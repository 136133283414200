import * as types from '../constants/actionTypes-constants';
import callApi from './api-actions';
import _get from 'lodash/get';

import { selectCompanyId, selectSubCompanyId, downloadImage } from '../utils/utils';

import { addFlashMessage } from './all-actions';
import { refreshPage } from '../routing/all-routing';
import { apiParams } from '../constants/api-params-constants';

import { FEEDBACK_STATUS_NEW, FEEDBACK_STATUS_HANDLED } from '../constants/backend-constants';
import { FLASH_MESSAGE_TYPE_ERROR } from '../constants/generic-constants';

export function getFeedbacksList(params) {
  return (dispatch, getState) => {
    const state = getState();

    let paramsObj;

    if (params) {
      const pageNumber = _get(params, 'page.number') || apiParams.default.page.number;
      const pageSize = _get(params, 'page.size') || apiParams.default.page.size;
      paramsObj = {
        page: {
          number: pageNumber,
          size: pageSize
        }
      };

      const sortProperty = params.sort ? params.sort.property : state.feedbacks.currentSortedIndex;
      const sortIsDescending = params.sort ? params.sort.isDescending : state.feedbacks.sortIsDescending;

      const sort = {
        property: sortProperty,
        isDescending: sortIsDescending
      };

      if (sortProperty) {
        paramsObj = Object.assign(paramsObj, { sort });
      }

      paramsObj = Object.assign(paramsObj, params);

      const companyId = selectCompanyId(state);
      const subCompanyId = selectSubCompanyId(state);

      paramsObj.companyIds = companyId ? [companyId] : [];
      paramsObj.subCompanyIds = subCompanyId ? [subCompanyId] : [];
    } else {
      paramsObj = null;
    }

    dispatch({
      type: types.FEEDBACKS_GET_LIST_REQUEST
    });

    return dispatch(callApi('feedbacks', 'getList', { params: paramsObj }, false)).then(function(data) {
      dispatch({
        type: types.FEEDBACKS_GET_LIST_SUCCESS,
        paginatedObj: data,
        urlParams: paramsObj
      });
      return data;
    });
  };
}

export function getFeedbackDetail(id) {
  return (dispatch, getState) => {
    dispatch({
      type: types.FEEDBACKS_GET_DETAIL_FEEDBACK_REQUEST
    });

    return dispatch(callApi('feedbacks', 'getFeedbackDetail', id)).then(function(data) {
      dispatch({
        type: types.FEEDBACKS_GET_DETAIL_FEEDBACK_SUCCESS,
        feedback: data
      });
      return data;
    });
  };
}

export function updateFeedbackStatus(feedbackDetail, status, reload = false) {
  return dispatch => {
    let bookingId = feedbackDetail.booking.id;

    return dispatch(callApi('feedbacks', 'updateFeedbackStatus', { bookingId, status }))
      .then(() => {
        status === FEEDBACK_STATUS_NEW || status === FEEDBACK_STATUS_HANDLED
          ? dispatch({
              type: types.FEEDBACKS_STATUS_CURRENTLY_UPDATED,
              statusCurrentlyUpdated: true
            })
          : null;
      })
      .then(() => {
        if (reload) refreshPage();
      })
      .catch(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'feedbacks_detail_edit_status_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

export function updateCurrentlyStatus(statusCurrentlyUpdated) {
  return {
    type: types.FEEDBACKS_STATUS_CURRENTLY_UPDATED,
    statusCurrentlyUpdated
  };
}

export function getFeedbackReportImage(fileId) {
  return dispatch => {
    dispatch({
      type: types.FEEDBACKS_GET_DETAIL_REPORT_IMAGE_REQUEST
    });

    return dispatch(callApi('file', 'getFile', fileId)).then(data => {
      dispatch({
        type: types.FEEDBACKS_GET_DETAIL_REPORT_IMAGE_SUCCESS,
        reportImage: data
      });
    });
  };
}

export function getDamageReportImage(fileId) {
  return dispatch => {
    dispatch({
      type: types.FEEDBACKS_GET_DAMAGE_REPORT_IMAGE_REQUEST
    });

    return dispatch(callApi('file', 'getFileUrl', fileId))
      .catch(() => {
        dispatch(callApi('file', 'getPublicFile', fileId)).then(function(fileData) {
          dispatch({
            type: types.FEEDBACKS_GET_DAMAGE_REPORT_IMAGE_SUCCESS,
            vehicleDamageImage: fileData
          });
          return downloadImage(fileData.url, fileData.id);
        });

        dispatch(
          addFlashMessage({
            contentKey: 'feedbacks_open_file_error',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      })
      .then(data => {
        dispatch({
          type: types.FEEDBACKS_GET_DAMAGE_REPORT_IMAGE_SUCCESS,
          damageImage: data
        });
        downloadImage(data.url, data.id);
      });
  };
}

export function setFeedbackAsDoneRequest(feedbackId) {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({
      type: types.FEEDBACKS_SET_FEEDBACK_AS_DONE_REQUEST
    });

    return dispatch(callApi('feedbacks', 'changeStatusFeedback', feedbackId));
  };
}

export function addFeedbackComment(feedbackDetail, comment) {
  return dispatch => {
    let bookingId = feedbackDetail.booking.id;

    return dispatch(callApi('feedbacks', 'addComment', { params: { bookingId, comment } }))
      .then(function() {
        refreshPage();
      })
      .catch(function() {
        dispatch(
          addFlashMessage({
            contentKey: 'feedbacks_add_comment_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

export function setFeedbackAsDoneSuccess(feedbackId) {
  return {
    type: types.FEEDBACKS_SET_FEEDBACK_AS_DONE_SUCCESS,
    feedbackId
  };
}

export function setFeedbacksCurrentSortedIndex(index) {
  return {
    type: types.FEEDBACKS_SET_CURRENT_SORTED_INDEX,
    index
  };
}

export function setFeedbacksSortIsDescending(isDescending) {
  return {
    type: types.FEEDBACKS_SORT_DIRECTION_SET,
    isDescending
  };
}

export function toggleFeedbacksFilters() {
  return {
    type: types.FEEDBACKS_FILTERS_FORM_OPENED_STATE_TOGGLE
  };
}

export function openAddCommentModal() {
  return {
    type: types.FEEDBACKS_ADD_COMMENT_MODAL_OPEN
  };
}

export function closeAddCommentModal() {
  return {
    type: types.FEEDBACKS_ADD_COMMENT_MODAL_CLOSE
  };
}
