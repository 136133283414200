export const HEADER_PROFILE_MENU_OPEN = 'HEADER_PROFILE_MENU_OPEN';
export const HEADER_PROFILE_MENU_CLOSE = 'HEADER_PROFILE_MENU_CLOSE';
export const HEADER_PROFILE_MENU_TOGGLE = 'HEADER_PROFILE_MENU_TOGGLE';
export const HEADER_MOBILE_MENU_TOGGLE = 'HEADER_MOBILE_MENU_TOGGLE';
export const HEADER_LANGUAGE_MENU_CLOSE = 'HEADER_LANGUAGE_MENU_CLOSE';
export const HEADER_LANGUAGE_MENU_TOGGLE = 'HEADER_LANGUAGE_MENU_TOGGLE';
export const HEADER_SUPER_COMPANY_TOGGLE = 'HEADER_SUPER_COMPANY_TOGGLE';
export const HEADER_COMPANY_TOGGLE = 'HEADER_COMPANY_TOGGLE';
export const HEADER_CHANGE_SEARCH_INPUT_TEXT = 'HEADER_CHANGE_SEARCH_INPUT_TEXT';
export const HEADER_SEARCH_MODE = 'HEADER_SEARCH_MODE';
export const HEADER_CHANGE_SUPERCOMPANY_INPUT_TEXT = 'HEADER_CHANGE_SUPERCOMPANY_INPUT_TEXT';
export const HEADER_CHANGE_COMPANY_INPUT_TEXT = 'HEADER_CHANGE_COMPANY_INPUT_TEXT';
export const STORE_PAGE_PARAMS = 'STORE_PAGE_PARAMS';

export const VEHICLE_PLANNING_TOGGLE_EDIT_BOOKING_MODAL = 'VEHICLE_PLANNING_TOGGLE_EDIT_BOOKING_MODAL';
export const VEHICLE_PLANNING_GET_DATA_REQUEST = 'VEHICLE_PLANNING_GET_DATA_REQUEST';
export const VEHICLE_PLANNING_GET_DATA_SUCCESS = 'VEHICLE_PLANNING_GET_DATA_SUCCESS';
export const VEHICLE_PLANNING_GET_DATA_ERROR = 'VEHICLE_PLANNING_GET_DATA_ERROR';
export const VEHICLE_PLANNING_CHANGE_SETTINGS = 'VEHICLE_PLANNING_CHANGE_SETTINGS';
export const VEHICLE_PLANNING_TOGGLE_CARD = 'VEHICLE_PLANNING_TOGGLE_CARD';
export const VEHICLE_PLANNING_GET_BOOKING_SUCCESS = 'VEHICLE_PLANNING_GET_BOOKING_SUCCESS';
export const VEHICLE_PLANNING_GET_BOOKING_ERROR = 'VEHICLE_PLANNING_GET_BOOKING_ERROR';
export const VEHICLE_PLANNING_TOGGLE_CHANGE_VEHICLE_MODAL = 'VEHICLE_PLANNING_TOGGLE_CHANGE_VEHICLE_MODAL';
export const VEHICLE_PLANNING_TOGGLE_CHANGE_MEMBER_MODAL = 'VEHICLE_PLANNING_TOGGLE_CHANGE_MEMBER_MODAL';
export const VEHICLE_PLANNING_TOGGLE_EDIT_CHANGE_MEMBER = 'VEHICLE_PLANNING_TOGGLE_EDIT_CHANGE_MEMBER';
export const VEHICLE_PLANNING_CHANGE_MEMBER_EDIT_BOOKING = 'VEHICLE_PLANNING_CHANGE_MEMBER_EDIT_BOOKING';
export const VEHICLE_PLANNING_GET_BOOKING_REQUEST = 'VEHICLE_PLANNING_GET_BOOKING_REQUEST';
export const VEHICLE_PLANNING_GET_BOOKING_PRICE_REQUEST = 'VEHICLE_PLANNING_GET_BOOKING_PRICE_REQUEST';
export const VEHICLE_PLANNING_GET_BOOKING_PRICE_SUCCESS = 'VEHICLE_PLANNING_GET_BOOKING_PRICE_SUCCESS';
export const VEHICLE_PLANNING_GET_BOOKING_PRICE_SUCCESS_EMPTY = 'VEHICLE_PLANNING_GET_BOOKING_PRICE_SUCCESS_EMPTY';
export const VEHICLE_PLANNING_GET_BOOKING_PRICE_ERROR = 'VEHICLE_PLANNING_GET_BOOKING_PRICE_ERROR';
export const VEHICLE_PLANNING_GET_BOOKING_PRICE_CLEAN = 'VEHICLE_PLANNING_GET_BOOKING_PRICE_CLEAN';
export const VEHICLE_PLANNING_CREATE_BOOKING_GET_PRICE = 'VEHICLE_PLANNING_CREATE_BOOKING_GET_PRICE';
export const VEHICLE_PLANNING_EDIT_BOOKING_GET_PRICE = 'VEHICLE_PLANNING_EDIT_BOOKING_GET_PRICE';
export const VEHICLE_PLANNING_SELECT_FIRST_DAY_FOR_CREATING_BBOKING = 'VEHICLE_PLANNING_SELECT_FIRST_DAY_FOR_CREATING_BBOKING';
export const VEHICLE_PLANNING_SELECT_SECOND_DAY_FOR_CREATING_BBOKING = 'VEHICLE_PLANNING_SELECT_SECOND_DAY_FOR_CREATING_BBOKING';
export const VEHICLE_PLANNING_CLEAR_CREATE_BOOKING_MODAL = 'VEHICLE_PLANNING_CLEAR_CREATE_BOOKING_MODAL';
export const VEHICLE_PLANNING_CLEAR_SELECTED_VEHICLE = 'VEHICLE_PLANNING_CLEAR_SELECTED_VEHICLE';
export const VEHICLE_PLANNING_TOGGLE_MEMBER_SELECT = 'VEHICLE_PLANNING_TOGGLE_MEMBER_SELECT';
export const VEHICLE_PLANNING_ERROR_SELECTED_USER = 'VEHICLE_PLANNING_ERROR_SELECTED_USER ';
export const VEHICLE_PLANNING_CLEAN_ERROR_SELECTED_USER = 'VEHICLE_PLANNING_CLEAN_RROR_SELECTED_USER ';
export const VEHICLE_PLANNING_SAVE_LOGIN_CREATE_BOOKING = 'VEHICLE_PLANNING_SAVE_LOGIN_CREATE_BOOKING';
export const VEHICLE_PLANNING_SAVE_MEMBER_TYPE_CREATE_BOOKING = 'VEHICLE_PLANNING_SAVE_MEMBER_TYPE_CREATE_BOOKING';
export const VEHICLE_PLANNING_SAVE_LOGIN_EDIT_BOOKING = 'VEHICLE_PLANNING_SAVE_LOGIN_EDIT_BOOKING';
export const VEHICLE_PLANNING_TOGGLE_CREATE_BOOKING_MODAL = 'VEHICLE_PLANNING_TOGGLE_CREATE_BOOKING_MODAL';
export const VEHICLE_PLANNING_CREATE_BOOKING_SUCCESS = 'VEHICLE_PLANNING_CREATE_BOOKING_SUCCESS';
export const VEHICLE_PLANNING_SELECT_FIRST_HOUR_FOR_CREATING_BBOKING = 'VEHICLE_PLANNING_SELECT_FIRST_HOUR_FOR_CREATING_BBOKING';
export const VEHICLE_PLANNING_SELECT_SECOND_HOUR_FOR_CREATING_BBOKING = 'VEHICLE_PLANNING_SELECT_SECOND_HOUR_FOR_CREATING_BBOKING';
export const VEHICLE_PLANNING_TOGGLE_CREATE_STATUS_MODE = 'VEHICLE_PLANNING_TOGGLE_CREATE_STATUS_MODE';
export const VEHICLE_PLANNING_SAVE_CREATE_STATUS_DATA = 'VEHICLE_PLANNING_SAVE_CREATE_STATUS_DATA';
export const VEHICLE_PLANNING_EMPTY_CREATE_STATUS_DATA = 'VEHICLE_PLANNING_EMPTY_CREATE_STATUS_DATA';
export const VEHICLE_PLANNING_TOGGLE_SHORTEN_EXTEND_MODAL = 'VEHICLE_PLANNING_TOGGLE_SHORTEN_EXTEND_MODAL';
export const VEHICLE_PLANNING_CLEAR_DATA = 'VEHICLE_PLANNING_CLEAR_DATA';
export const VEHICLE_PLANNING_SET_FOUND_USERS = 'VEHICLE_PLANNING_SET_FOUND_USERS';
export const VEHICLE_PLANNING_CLEAR_FOUND_USERS = 'VEHICLE_PLANNING_CLEAR_FOUND_USERS';
export const VEHICLE_PLANNING_START_NEW_PRESUB = 'VEHICLE_PLANNING_START_NEW_PRESUB';
export const VEHICLE_PLANNING_PROCEED_NEW_PRESUB = 'VEHICLE_PLANNING_PROCEED_NEW_PRESUB';
export const VEHICLE_PLANNING_CLEAR_EXIST_MEMBER_MESSAGE = 'VEHICLE_PLANNING_CLEAR_EXIST_MEMBER_MESSAGE';
export const VEHICLE_PLANNING_CONFIRM_PREBOOKING = 'VEHICLE_PLANNING_CONFIRM_PREBOOKING';
export const VEHICLE_PLANNING_CONFIRM_PREBOOKING_CANCEL = 'VEHICLE_PLANNING_CONFIRM_PREBOOKING_CANCEL';
export const VEHICLE_PLANNING_TOGGLE_FILTER = 'VEHICLE_PLANNING_TOGGLE_FILTER';
export const VEHICLE_PLANNING_TOGGLE_LEGEND = 'VEHICLE_PLANNING_TOGGLE_LEGEND';
export const VEHICLE_PLANNING_RESET_FILTERS = 'VEHICLE_PLANNING_RESET_FILTERS';
export const VEHICLE_PLANNING_ADD_FILTER = 'VEHICLE_PLANNING_ADD_FILTER';
export const VEHICLE_PLANNING_REMOVE_FILTER = 'VEHICLE_PLANNING_REMOVE_FILTER';
export const VEHICLE_PLANNING_EDIT_FILTERS = 'VEHICLE_PLANNING_EDIT_FILTERS';
export const VEHICLE_PLANNING_RESET_FILTERSv2 = 'VEHICLE_PLANNING_RESET_FILTERSv2';
export const VEHICLE_PLANNING_ADD_STATUS_CONFIRM_OPEN = 'VEHICLE_PLANNING_ADD_STATUS_CONFIRM_OPEN';
export const VEHICLE_PLANNING_ADD_STATUS_CONFIRM_CLOSE = 'VEHICLE_PLANNING_ADD_STATUS_CONFIRM_CLOSE';

export const ADVANCED_LIST_CHANGE_FILTER_VALUE = 'ADVANCED_LIST_CHANGE_FILTER_VALUE';
export const ADVANCED_LIST_CHANGE_FILTER_TEXT_INPUT = 'ADVANCED_LIST_CHANGE_FILTER_TEXT_INPUT';
export const ADVANCED_LIST_CHANGE_FILTER_SELECT = 'ADVANCED_LIST_CHANGE_FILTER_SELECT';

export const VOUCHER_REVOKE_REQUEST = 'VOUCHER_REVOKE_REQUEST';
export const VOUCHER_REVOKE_SUCCESS = 'VOUCHER_REVOKE_SUCCESS';
export const VOUCHER_REVOKE_FAIL = 'VOUCHER_REVOKE_FAIL';

export const VOUCHER_GET_LIST_REQUEST = 'VOUCHER_GET_LIST_REQUEST';
export const VOUCHER_GET_LIST_SUCCESS = 'VOUCHER_GET_LIST_SUCCESS';
export const VOUCHER_GET_LIST_ERROR = 'VOUCHER_GET_LIST_ERROR';
export const VOUCHER_GET_GROUPS_REQUEST = 'VOUCHER_GET_GROUPS_REQUEST';
export const VOUCHER_GET_GROUPS_SUCCESS = 'VOUCHER_GET_GROUPS_SUCCESS';
export const VOUCHER_GROUPS_UPDATE_FILTERS = 'VOUCHER_GROUPS_UPDATE_FILTERS';
export const VOUCHER_GET_GROUP_DETAIL_REQUEST = 'VOUCHER_GET_GROUP_DETAIL_REQUEST';
export const VOUCHER_GET_GROUP_DETAIL_SUCCESS = 'VOUCHER_GET_GROUP_DETAIL_SUCCESS';
export const VOUCHER_GROUP_CREATE_REQUEST = 'VOUCHER_GROUP_CREATE_REQUEST';
export const VOUCHER_GROUP_CREATE_SUCCESS = 'VOUCHER_GROUP_CREATE_SUCCESS';
export const VOUCHER_GET_GROUPS_ERROR = 'VOUCHER_GET_GROUPS_ERROR';
export const VOUCHER_GROUP_GET_COMPANY_REQUEST = 'VOUCHER_GROUP_GET_COMPANY_REQUEST';
export const VOUCHER_GROUP_GET_COMPANY_SUCCESS = 'VOUCHER_GROUP_GET_COMPANY_SUCCESS';
export const VOUCHER_TOGGLE_CREATE_CODE_MODAL = 'VOUCHER_TOGGLE_CREATE_CODE_MODAL';
export const VOUCHER_CREATE_CODE_REQUEST = 'VOUCHER_CREATE_CODE_REQUEST';
export const VOUCHER_CREATE_CODE_SUCCESS = 'VOUCHER_CREATE_CODE_SUCCESS';
export const VOUCHER_CLEAR_DETAIL = 'VOUCHER_CLEAR_DETAIL';
export const VOUCHER_GROUPS_CLEAR = 'VOUCHER_GROUPS_CLEAR';

export const VOUCHERS_USED_IN_BOOKINGS_REQUEST = 'VOUCHERS_USED_IN_BOOKINGS_REQUEST';
export const VOUCHERS_USED_IN_BOOKINGS_SUCCESS = 'VOUCHERS_USED_IN_BOOKINGS_SUCCESS';
export const VOUCHERS_USED_IN_BOOKINGS_ERROR = 'VOUCHERS_USED_IN_BOOKINGS_ERROR';
export const VOUCHERS_EXPORT_REQUEST = 'VOUCHERS_EXPORT_REQUEST';
export const VOUCHERS_EXPORT_SUCCESS = 'VOUCHERS_EXPORT_SUCCES';
export const VOUCHERS_EXPORT_FAIL = 'VOUCHERS_EXPORT_FAIL';
export const VOUCHERS_GET_PARKINGS_REQUEST = 'VOUCHERS_GET_PARKINGS_REQUEST';
export const VOUCHERS_SAVE_PARKINGS = 'VOUCHERS_SAVE_PARKINGS';
export const VOUCHERS_GET_PARKINGS_ERROR = 'VOUCHERS_GET_PARKINGS_ERROR';
export const VOUCHERS_GET_PARKINGS_SUCCESS = 'VOUCHERS_GET_PARKINGS_SUCCESS';
export const VOUCHERS_ADD_AVAILABLE_PARKINGS = 'VOUCHERS_ADD_AVAILABLE_PARKINGS';
export const VOUCHERS_ADD_TO_ALLOWED_PARKINGS = 'VOUCHERS_ADD_TO_ALLOWED_PARKINGS';
export const VOUCHERS_ADD_TO_AVAILABLE_PARKINGS = 'VOUCHERS_ADD_TO_AVAILABLE_PARKINGS';
export const VOUCHERS_SAVE_COMPANIES = 'VOUCHERS_SAVE_COMPANIES';
export const VOUCHERS_GET_COMPANIES_ERROR = 'VOUCHERS_GET_COMPANIES_ERROR';
export const VOUCHERS_GET_COMPANIES_REQUEST = 'VOUCHERS_GET_COMPANIES_REQUEST';
export const VOUCHERS_GET_COMPANIES_SUCCESS = 'VOUCHERS_GET_COMPANIES_SUCCESS';
export const VOUCHERS_ADD_AVAILABLE_COMPANIES = 'VOUCHERS_ADD_AVAILABLE_COMPANIES';
export const VOUCHERS_ADD_TO_ALLOWED_COMPANIES = 'VOUCHERS_ADD_TO_ALLOWED_COMPANIES';
export const VOUCHERS_ADD_TO_AVAILABLE_COMPANIES = 'VOUCHERS_ADD_TO_AVAILABLE_COMPANIES';

export const BANKOUT_GET_LIST_REQUEST = 'BANKOUT_GET_LIST_REQUEST';
export const BANKOUT_GET_LIST_ERROR = 'BANKOUT_GET_LIST_ERROR';
export const BANKOUT_GET_LIST_SUCCESS = 'BANKOUT_GET_LIST_SUCCESS';

export const BANKOUT_GET_DETAILS_REQUEST = 'BANKOUT_GET_DETAILS_REQUEST';
export const BANKOUT_GET_DETAILS_SUCCESS = 'BANKOUT_GET_DETAILS_SUCCESS';
export const BANKOUT_GET_DETAILS_CLEAR = 'BANKOUT_GET_DETAILS_CLEAR';
export const BANKOUT_GET_DETAILS_ERROR = 'BANKOUT_GET_DETAILS_ERROR';

export const BANKOUT_EXPORT_REQUEST = 'BANKOUT_EXPORT_REQUEST';
export const BANKOUT_EXPORT_FINISHED = 'BANKOUT_EXPORT_FINISHED';
export const BANKOUT_EXPORT_SUCCESS = 'BANKOUT_EXPORT_SUCCESS';
export const BANKOUT_EXPORT_FAILED = 'BANKOUT_EXPORT_FAILED';

export const PAYBACK_GET_LIST_REQUEST = 'PAYBACK_GET_LIST_REQUEST';
export const PAYBACK_GET_LIST_ERROR = 'PAYBACK_GET_LIST_ERROR';
export const PAYBACK_GET_LIST_SUCCESS = 'PAYBACK_GET_LIST_SUCCESS';
export const BANKOUT_RETRY_REQUEST = 'BANKOUT_RETRY_REQUEST';
export const BANKOUT_RETRY_SUCCESS = 'BANKOUT_RETRY_SUCCESS';
export const BANKOUT_CLEAR_LIST = 'BANKOUT_CLEAR_LIST';
export const PAYBACK_CLEAR_LIST = 'PAYBACK_CLEAR_LIST';

export const HEADER_SEARCH_REQUEST = 'HEADER_SEARCH_REQUEST';
export const SEARCH_MEMBER_NAME_SUCCESS = 'SEARCH_MEMBER_NAME_SUCCESS';
export const SEARCH_CLEAR_RESULTS = 'SEARCH_CLEAR_RESULTS';
export const SEARCH_BOOKINGS_SUCCESS = 'SEARCH_BOOKINGS_SUCCESS';
export const SEARCH_VEHICLES_SUCCESS = 'SEARCH_VEHICLES_SUCCESS';
export const SEARCH_COMPANIES_SUCCESS = 'SEARCH_COMPANIES_SUCCESS';
export const SEARCH_MEMBER_EMAIL_SUCCESS = 'SEARCH_MEMBER_EMAIL_SUCCESS';

export const API_SET_TOKEN_REQUEST = 'API_SET_TOKEN_REQUEST';
export const API_UNSET_TOKEN_REQUEST = 'API_UNSET_TOKEN_REQUEST';
export const API_CALL_START = 'API_CALL_START';
export const API_CALL_END = 'API_CALL_END';

export const I18N_BUNDLE_REQUEST = 'I18N_BUNDLE_REQUEST';
export const I18N_BUNDLE_SUCCESS = 'I18N_BUNDLE_SUCCESS';

export const FLASH_MESSAGE_ADD = 'FLASH_MESSAGE_ADD';
export const FLASH_MESSAGE_REMOVE = 'FLASH_MESSAGE_REMOVE';

export const PRICING_CATEGORY_WIDGET_OPENED_STATE_TOGGLE = 'PRICING_CATEGORY_WIDGET_OPENED_STATE_TOGGLE';
export const PRICING_GET_BUSINESS_TIME_SLOTS_REQUEST = 'PRICING_GET_BUSINESS_TIME_SLOTS_REQUEST';
export const PRICING_GET_BUSINESS_TIME_SLOTS_SUCCESS = 'PRICING_GET_BUSINESS_TIME_SLOTS_SUCCESS';
export const PRICING_GET_CATEGORY_TIME_SLOTS_SUCCESS = 'PRICING_GET_CATEGORY_TIME_SLOTS_SUCCESS';
export const PRICING_GET_CATEGORY_TRANSVERSAL_PRICES_SUCCESS = 'PRICING_GET_CATEGORY_TRANSVERSAL_PRICES_SUCCESS';
export const PRICING_SEND_TRANSVERSAL_PRICING_ERROR = 'PRICING_SEND_TRANSVERSAL_PRICING_ERROR';
export const PRICING_SET_CURRENT_INDEX = 'PRICING_SET_CURRENT_INDEX';
export const PRICING_RESET_OPENED_CATEGORY_WIDGET = 'PRICING_RESET_OPENED_CATEGORY_WIDGET';
export const PRICING_SAVE_ALL_TIMESLOTS = 'PRICING_SAVE_ALL_TIMESLOTS';
export const PRICING_SET_TIMESLOTS_ERRORS = 'PRICING_SET_TIMESLOTS_ERRORS';
export const PRICING_CLEAR_TIMESLOTS_ERRORS = 'PRICING_CLEAR_TIMESLOTS_ERRORS';
export const PRICING_PREPARE_TIMESLOTS_TO_SEND = 'PRICING_PREPARE_TIMESLOTS_TO_SEND';
export const PRICING_RESET_TIMESLOTS_TO_SEND = 'PRICING_RESET_TIMESLOTS_TO_SEND';
export const PRICING_RESET_CURRENT_COMPANY_BUSINESS_TIMESLOTS = 'PRICING_RESET_CURRENT_COMPANY_BUSINESS_TIMESLOTS';
export const PRICING_RESET_CATEGORIES_TRANSVERSAL_PRICES = 'PRICING_RESET_CATEGORIES_TRANSVERSAL_PRICES';
export const PRICING_SET_INITIAL_MEMBER_TYPE_ID = 'PRICING_SET_INITIAL_MEMBER_TYPE_ID';
export const PRICING_COPY_TIME_SLOTS_OF_MEMBER_TYPE_REQUEST = 'PRICING_COPY_TIME_SLOTS_OF_MEMBER_TYPE_REQUEST';
export const PRICING_COPY_TIME_SLOTS_OF_MEMBER_TYPE_SUCCESS = 'PRICING_COPY_TIME_SLOTS_OF_MEMBER_TYPE_SUCCESS';
export const PRICING_TOGGLE_DIALOG_COPY_TIME_SLOTS_OF_MEMBER_TYPE = 'PRICING_TOGGLE_DIALOG_COPY_TIME_SLOTS_OF_MEMBER_TYPE';
export const PRICING_GET_CATEGORY_CANCELLATION_FEES_REQUEST = 'PRICING_GET_CATEGORY_CANCELLATION_FEES_REQUEST';
export const PRICING_GET_CATEGORY_CANCELLATION_FEES_SUCCESS = 'PRICING_GET_CATEGORY_CANCELLATION_FEES_SUCCESS';
export const PRICING_GET_CATEGORY_CANCELLATION_FEES_ERROR = 'PRICING_GET_CATEGORY_CANCELLATION_FEES_ERROR';
export const PRICING_SAVE_CATEGORY_CANCELLATION_FEES_REQUEST = 'PRICING_SAVE_CATEGORY_CANCELLATION_FEES_REQUEST';
export const PRICING_EMPTY_CATEGORY_CANCELLATION_FEES = 'PRICING_EMPTY_CATEGORY_CANCELLATION_FEES';

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_STORED_LOGIN_EXPOSE = 'USER_STORED_LOGIN_EXPOSE';
export const USER_FORGOT_PASSWORD_MODE_TOGGLE = 'USER_FORGOT_PASSWORD_MODE_TOGGLE';
export const USER_RESET_PASSWORD_REQUEST = 'USER_RESET_PASSWORD_REQUEST';
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
export const USER_RESET_PASSWORD_ERROR = 'USER_RESET_PASSWORD_ERROR';
export const USER_TOKEN_REFRESH_REQUEST = 'USER_TOKEN_REFRESH_REQUEST';
export const USER_TOKEN_REFRESH_SUCCESS = 'USER_TOKEN_REFRESH_SUCCESS';
export const USER_RESET_PASSWORD_CHECK_TOKEN_REQUEST = 'USER_RESET_PASSWORD_CHECK_TOKEN_REQUEST';
export const USER_RESET_PASSWORD_CHECK_TOKEN_SUCCESS = 'USER_RESET_PASSWORD_CHECK_TOKEN_SUCCESS';
export const USER_RESET_PASSWORD_APPLY_REQUEST = 'USER_RESET_PASSWORD_APPLY_REQUEST';
export const USER_RESET_PASSWORD_STORE_TOKEN = 'USER_RESET_PASSWORD_STORE_TOKEN';
export const USER_EDIT_PASSWORD_REQUEST = 'USER_EDIT_PASSWORD_REQUEST';
export const USER_EDIT_PASSWORD_SUCCESS = 'USER_EDIT_PASSWORD_SUCCESS';
export const USER_REPORTING_SET_ERROR = 'USER_REPORTING_SET_ERROR';
export const USER_REPORTING_CLEAR_ERROR = 'USER_REPORTING_CLEAR_ERROR';

export const USER_REPORTING_SPINNER_START = 'USER_REPORTING_SPINNER_START';
export const USER_REPORTING_SPINNER_STOP = 'USER_REPORTING_SPINNER_STOP';

export const USER_UPDATE_UI_STATUS = 'USER_UPDATE_UI_STATUS';
export const USER_UPDATE_MOBILE_VIEW_STATUS = 'USER_UPDATE_MOBILE_VIEW_STATUS';
export const USER_UPDATE_VP_STATUS = 'USER_UPDATE_VP_STATUS';
export const USER_UPDATE_QUICK_SIGHT_TEST_EMAIL = 'USER_UPDATE_QUICK_SIGHT_TEST_EMAIL';

export const VEHICLES_CLEAR_LIST_DATA = 'VEHICLES_CLEAR_LIST_DATA';
export const VEHICLES_GET_FLEET_REQUEST = 'VEHICLES_GET_FLEET_REQUEST';
export const VEHICLES_GET_FLEET_SUCCESS = 'VEHICLES_GET_FLEET_SUCCESS';
export const VEHICLES_GET_FLEET_ERROR = 'VEHICLES_GET_FLEET_ERROR';
export const VEHICLES_GET_FORM_FIELD_INFOS_SUCCESS = 'VEHICLES_GET_FORM_FIELD_INFOS_SUCCESS';
export const VEHICLES_GET_DETAIL_VEHICLE_REQUEST = 'VEHICLES_GET_DETAIL_VEHICLE_REQUEST';
export const VEHICLES_GET_DETAIL_VEHICLE_SUCCESS = 'VEHICLES_GET_DETAIL_VEHICLE_SUCCESS';
export const VEHICLES_GET_CATEGORIES_LIST_REQUEST = 'VEHICLES_GET_CATEGORIES_LIST_REQUEST';
export const VEHICLES_GET_CATEGORIES_LIST_SUCCESS = 'VEHICLES_GET_CATEGORIES_LIST_SUCCESS';
export const VEHICLES_FILTERS_FORM_OPENED_STATE_TOGGLE = 'VEHICLES_FILTERS_FORM_OPENED_STATE_TOGGLE';
export const VEHICLES_OPEN_CHANGE_STATUS_MODAL = 'VEHICLES_OPEN_CHANGE_STATUS_MODAL';
export const VEHICLES_CLOSE_CHANGE_STATUS_MODAL = 'VEHICLES_CLOSE_CHANGE_STATUS_MODAL';
export const VEHICLES_CONFIRM_ADD_STATUS_MODAL_OPEN = 'VEHICLES_CONFIRM_ADD_STATUS_MODAL_OPEN';
export const VEHICLES_CONFIRM_ADD_STATUS_MODAL_CLOSE = 'VEHICLES_CONFIRM_ADD_STATUS_MODAL_CLOSE';
export const VEHICLES_GET_STATUSES_REQUEST = 'VEHICLES_GET_STATUSES_REQUEST';
export const VEHICLES_GET_STATUSES_SUCCESS = 'VEHICLES_GET_STATUSES_SUCCESS';
export const VEHICLES_SAVE_STATUS_REQUEST = 'VEHICLES_SAVE_STATUS_REQUEST';
export const VEHICLES_UPDATE_CLEANLINESS_STATUS_REQUEST = 'VEHICLES_UPDATE_CLEANLINESS_STATUS_REQUEST';
export const VEHICLES_UPDATE_CLEANLINESS_STATUS_SUCCESS = 'VEHICLES_UPDATE_CLEANLINESS_STATUS_SUCCESS';
export const VEHICLES_DELETE_STATUS_REQUEST = 'VEHICLES_DELETE_STATUS_REQUEST';
export const VEHICLES_DELETE_STATUS_SUCCESS = 'VEHICLES_DELETE_STATUS_SUCCESS';
export const VEHICLES_OPEN_DELETE_STATUS_MODAL = 'VEHICLES_OPEN_DELETE_STATUS_MODAL';
export const VEHICLES_CLOSE_DELETE_STATUS_MODAL = 'VEHICLES_CLOSE_DELETE_STATUS_MODAL';
export const VEHICLES_SET_CURRENT_SORTED_INDEX = 'VEHICLES_SET_CURRENT_SORTED_INDEX';
export const VEHICLES_SORT_DIRECTION_SET = 'VEHICLES_SORT_DIRECTION_SET';
export const VEHICLES_UNLOCK_REQUEST = 'VEHICLES_UNLOCK_REQUEST';
export const VEHICLES_UNLOCK_SUCCESS = 'VEHICLES_UNLOCK_SUCCESS';
export const VEHICLES_LOCK_REQUEST = 'VEHICLES_LOCK_REQUEST';
export const VEHICLES_LOCK_SUCCESS = 'VEHICLES_LOCK_SUCCESS';
export const VEHICLES_GET_DEVICE_STATUS_REQUEST = 'VEHICLES_GET_DEVICE_STATUS_REQUEST';
export const VEHICLES_GET_DEVICE_STATUS_SUCCESS = 'VEHICLES_GET_DEVICE_STATUS_SUCCESS';
export const VEHICLES_GET_DEVICE_ID_REQUEST = 'VEHICLES_GET_DEVICE_ID_REQUEST';
export const VEHICLES_GET_DEVICE_ID_SUCCESS = 'VEHICLES_GET_DEVICE_ID_SUCCESS';
export const VEHICLES_UDPATE_DEVICE_SERIAL_NUMBER_SUCCESS = 'VEHICLES_UDPATE_DEVICE_SERIAL_NUMBER_SUCCESS';
export const VEHICLES_EXPORT_REQUEST = 'VEHICLES_EXPORT_REQUEST';
export const VEHICLES_EXPORT_FINISHED = 'VEHICLES_EXPORT_FINISHED';
export const VEHICLES_DETAIL_SET_CURRENT_INDEX = 'VEHICLES_DETAIL_SET_CURRENT_INDEX';
export const VEHICLES_DETAIL_CREATE_LEASE_CONTRACT_REQUEST = 'VEHICLES_DETAIL_CREATE_LEASE_CONTRACT_REQUEST';
export const VEHICLES_DETAIL_CREATE_LEASE_CONTRACT_SUCCESS = 'VEHICLES_DETAIL_CREATE_LEASE_CONTRACT_SUCCESS';
export const VEHICLES_DETAIL_CREATE_INSURANCE_CONTRACT_REQUEST = 'VEHICLES_DETAIL_CREATE_INSURANCE_CONTRACT_REQUEST';
export const VEHICLES_DETAIL_CREATE_INSURANCE_CONTRACT_SUCCESS = 'VEHICLES_DETAIL_CREATE_INSURANCE_CONTRACT_SUCCESS';
export const VEHICLES_DETAIL_CREATE_FUEL_CARD_REQUEST = 'VEHICLES_DETAIL_CREATE_FUEL_CARD_REQUEST';
export const VEHICLES_DETAIL_CREATE_FUEL_CARD_SUCCESS = 'VEHICLES_DETAIL_CREATE_FUEL_CARD_SUCCESS';
export const VEHICLES_DETAIL_CREATE_AUTOLIB_CARD_REQUEST = 'VEHICLES_DETAIL_CREATE_AUTOLIB_CARD_REQUEST';
export const VEHICLES_DETAIL_CREATE_AUTOLIB_CARD_SUCCESS = 'VEHICLES_DETAIL_CREATE_AUTOLIB_CARD_SUCCESS';
export const VEHICLES_DETAIL_GET_LEASE_CONTRACTS_REQUEST = 'VEHICLES_DETAIL_GET_LEASE_CONTRACTS_REQUEST';
export const VEHICLES_DETAIL_GET_LEASE_CONTRACTS_SUCCESS = 'VEHICLES_DETAIL_GET_LEASE_CONTRACTS_SUCCESS';
export const VEHICLES_DETAIL_GET_INSURANCE_CONTRACTS_REQUEST = 'VEHICLES_DETAIL_GET_INSURANCE_CONTRACTS_REQUEST';
export const VEHICLES_DETAIL_GET_INSURANCE_CONTRACTS_SUCCESS = 'VEHICLES_DETAIL_GET_INSURANCE_CONTRACTS_SUCCESS';
export const VEHICLES_DETAIL_GET_FUEL_CARDS_REQUEST = 'VEHICLES_DETAIL_GET_FUEL_CARDS_REQUEST';
export const VEHICLES_DETAIL_GET_FUEL_CARDS_SUCCESS = 'VEHICLES_DETAIL_GET_FUEL_CARDS_SUCCESS';
export const VEHICLES_DETAIL_GET_AUTOLIB_CARDS_REQUEST = 'VEHICLES_DETAIL_GET_AUTOLIB_CARDS_REQUEST';
export const VEHICLES_DETAIL_GET_AUTOLIB_CARDS_SUCCESS = 'VEHICLES_DETAIL_GET_AUTOLIB_CARDS_SUCCESS';
export const VEHICLES_DETAIL_DELETE_LEASE_CONTRACT_REQUEST = 'VEHICLES_DETAIL_DELETE_LEASE_CONTRACT_REQUEST';
export const VEHICLES_DETAIL_DELETE_LEASE_CONTRACT_SUCCESS = 'VEHICLES_DETAIL_DELETE_LEASE_CONTRACT_SUCCESS';
export const VEHICLES_DETAIL_DELETE_INSURANCE_CONTRACT_REQUEST = 'VEHICLES_DETAIL_DELETE_INSURANCE_CONTRACT_REQUEST';
export const VEHICLES_DETAIL_DELETE_INSURANCE_CONTRACT_SUCCESS = 'VEHICLES_DETAIL_DELETE_INSURANCE_CONTRACT_SUCCESS';
export const VEHICLES_DETAIL_DELETE_FUEL_CARD_REQUEST = 'VEHICLES_DETAIL_DELETE_FUEL_CARD_REQUEST';
export const VEHICLES_DETAIL_DELETE_FUEL_CARD_SUCCESS = 'VEHICLES_DETAIL_DELETE_FUEL_CARD_SUCCESS';
export const VEHICLES_DETAIL_DELETE_AUTOLIB_CARD_REQUEST = 'VEHICLES_DETAIL_DELETE_AUTOLIB_CARD_REQUEST';
export const VEHICLES_DETAIL_DELETE_AUTOLIB_CARD_SUCCESS = 'VEHICLES_DETAIL_DELETE_AUTOLIB_CARD_SUCCESS';
export const VEHICLES_EDIT_REGISTRATION_FILE_INFOS_SUCCESS = 'VEHICLES_EDIT_REGISTRATION_FILE_INFOS_SUCCESS';
export const VEHICLES_CLEAR_REGISTRATION_FILE_INFOS_SUCCESS = 'VEHICLES_CLEAR_REGISTRATION_FILE_INFOS_SUCCESS';
export const VEHICLES_GET_INSURANCE_CONTRACT_IMAGE_SUCCESS = 'VEHICLES_GET_INSURANCE_CONTRACT_IMAGE_SUCCESS';
export const VEHICLES_GET_INSURANCE_CONTRACT_IMAGE_REQUEST = 'VEHICLES_GET_INSURANCE_CONTRACT_IMAGE_REQUEST';

export const VEHICLES_GET_REPORTS_REQUEST = 'VEHICLES_GET_REPORTS_REQUEST';
export const VEHICLES_GET_REPORTS_SUCCESS = 'VEHICLES_GET_REPORTS_SUCCESS';
export const VEHICLES_GET_REPORTS_ERROR = 'VEHICLES_GET_REPORTS_ERROR';
export const VEHICLES_CLEAR_REPORTS = 'VEHICLES_CLEAR_REPORTS';

export const VEHICLE_SET_DEVICE_REMOVAL_REMINDER = 'VEHICLE_SET_DEVICE_REMOVAL_REMINDER';
export const VEHICLE_FORM_CREATE_NEW_VEHICLE_REQUEST = 'VEHICLE_FORM_CREATE_NEW_VEHICLE_REQUEST';
export const VEHICLE_FORM_CREATE_NEW_VEHICLE_SUCCESS = 'VEHICLE_FORM_CREATE_NEW_VEHICLE_SUCCESS';
export const VEHICLE_FORM_CREATE_NEW_VEHICLE_INIT = 'VEHICLE_FORM_CREATE_NEW_VEHICLE_INIT';
export const VEHICLE_COPY_TO_CREATE_NEW = 'VEHICLE_COPY_TO_CREATE_NEW';
export const VEHICLE_FORM_IMAGE_PREVIEW_HIDE = 'VEHICLE_FORM_IMAGE_PREVIEW_HIDE';
export const VEHICLE_FORM_UPDATE_VEHICLE_REQUEST = 'VEHICLE_FORM_UPDATE_VEHICLE_REQUEST';
export const VEHICLE_FORM_UPDATE_VEHICLE_SUCCESS = 'VEHICLE_FORM_UPDATE_VEHICLE_SUCCESS';
export const VEHICLES_CLEAR_CURRENT = 'VEHICLES_CLEAR_CURRENT';
export const VEHICLE_FORM_UPDATE_CONTRACT_IMAGE_SUCCESS = 'VEHICLE_FORM_UPDATE_CONTRACT_IMAGE_SUCCESS';
export const VEHICLE_FORM_UPDATE_CONTRACT_IMAGE_REMOVE = 'VEHICLE_FORM_UPDATE_CONTRACT_IMAGE_REMOVE';

export const VEHICLE_DAMAGES_GET_DETAIL_DAMAGE_REQUEST = 'VEHICLE_DAMAGES_GET_DETAIL_DAMAGE_REQUEST';
export const VEHICLE_DAMAGES_GET_DETAIL_DAMAGE_SUCCESS = 'VEHICLE_DAMAGES_GET_DETAIL_DAMAGE_SUCCESS';
export const VEHICLE_DAMAGES_EDIT_STATUS_MODAL_OPEN = 'VEHICLE_DAMAGES_EDIT_STATUS_MODAL_OPEN';
export const VEHICLE_DAMAGES_EDIT_STATUS_MODAL_CLOSE = 'VEHICLE_DAMAGES_EDIT_STATUS_MODAL_CLOSE';

export const VEHICLE_DAMAGES_GET_LIST_REQUEST = 'VEHICLE_DAMAGES_GET_LIST_REQUEST';
export const VEHICLE_DAMAGES_GET_LIST_SUCCESS = 'VEHICLE_DAMAGES_GET_LIST_SUCCESS';
export const VEHICLE_DAMAGES_SET_CURRENT_SORTED_INDEX = 'VEHICLE_DAMAGES_SET_CURRENT_SORTED_INDEX';
export const VEHICLE_DAMAGES_SORT_DIRECTION_SET = 'VEHICLE_DAMAGES_SORT_DIRECTION_SET';
export const VEHICLE_DAMAGES_FILTERS_FORM_OPENED_STATE_TOGGLE = 'VEHICLE_DAMAGES_FILTERS_FORM_OPENED_STATE_TOGGLE';
export const VEHICLE_DAMAGES_ADD_DAMAGE_MODAL_OPEN = 'VEHICLE_DAMAGES_ADD_DAMAGE_MODAL_OPEN';
export const VEHICLE_DAMAGES_ADD_DAMAGE_MODAL_CLOSE = 'VEHICLE_DAMAGES_ADD_DAMAGE_MODAL_CLOSE';
export const VEHICLE_DAMAGE_FORM_UPDATE_DAMAGE_IMAGE_SUCCESS = 'VEHICLE_DAMAGE_FORM_UPDATE_DAMAGE_IMAGE_SUCCESS';
export const VEHICLE_DAMAGE_FORM_UPDATE_DAMAGE_IMAGE_REMOVE = 'VEHICLE_DAMAGE_FORM_UPDATE_DAMAGE_IMAGE_REMOVE';
export const VEHICLE_DAMAGES_GET_DETAIL_IMAGE_SUCCESS = 'VEHICLE_DAMAGES_GET_DETAIL_IMAGE_SUCCESS';
export const VEHICLE_DAMAGE_DETAIL_IMAGE_REMOVE = 'VEHICLE_DAMAGE_DETAIL_IMAGE_REMOVE';

export const VEHICLE_GET_TASKS_REQUEST = 'VEHICLE_GET_TASKS_REQUEST';
export const VEHICLE_GET_TASKS_SUCCESS = 'VEHICLE_GET_TASKS_SUCCESS';
export const VEHICLE_GET_TASKS_ERROR = 'VEHICLE_GET_TASKS_ERROR';
export const VEHICLE_CLEAR_TASKS_DATA = 'VEHICLE_CLEAR_TASKS_DATA';

export const VEHICLE_ADD_TASK_REQUEST = 'VEHICLE_ADD_TASK_REQUEST';
export const VEHICLE_ADD_TASK_SUCCESS = 'VEHICLE_ADD_TASK_SUCCESS';
export const VEHICLE_ADD_TASK_ERROR = 'VEHICLE_ADD_TASK_ERROR';

export const BACKUSERS_GET_FIELD_AGENTS_REQUEST = 'BACKUSERS_GET_FIELD_AGENTS_REQUEST';
export const BACKUSERS_GET_FIELD_AGENTS_SUCCESS = 'BACKUSERS_GET_FIELD_AGENTS_SUCCESS';
export const BACKUSERS_GET_FIELD_AGENTS_ERROR = 'BACKUSERS_GET_FIELD_AGENTS_ERROR';

export const USER_EDIT_PROFILE_REQUEST = 'USER_EDIT_PROFILE_REQUEST';
export const USER_EDIT_PROFILE_SUCCESS = 'USER_EDIT_PROFILE_SUCCESS';
export const USER_ENABLE_REQUEST = 'USER_ENABLE_REQUEST';

export const USER_ACCOUNT_ACTIVE_TAB_INDEX_UPDATE = 'USER_ACCOUNT_ACTIVE_TAB_INDEX_UPDATE';

export const SIDE_MENU_OPEN = 'SIDE_MENU_OPEN';
export const SIDE_MENU_CLOSE = 'SIDE_MENU_CLOSE';
export const SIDE_MENU_TOGGLE = 'SIDE_MENU_TOGGLE';
export const SIDE_SUB_MENU_TOGGLE = 'SIDE_SUB_MENU_TOGGLE';
export const SIDE_SUB_MENU_OPEN = 'SIDE_SUB_MENU_OPEN';
export const SIDE_SUB_MENU_CLOSE = 'SIDE_SUB_MENU_CLOSE';
export const SIDE_MENU_RESET = 'SIDE_MENU_RESET';

export const PAGE_UPDATE_CURRENT_GROUP = 'PAGE_UPDATE_CURRENT_GROUP';
export const PAGE_UPDATE_CURRENT_ROUTE = 'PAGE_UPDATE_CURRENT_ROUTE';
export const PAGE_UPDATE_ROOT_CLASSES = 'PAGE_UPDATE_ROOT_CLASSES';

export const BACK_USERS_GET_LIST_REQUEST = 'BACK_USERS_GET_LIST_REQUEST';
export const BACK_USERS_GET_LIST_SUCCESS = 'BACK_USERS_GET_LIST_SUCCESS';
export const BACK_USERS_GET_LIST_ERROR = 'BACK_USERS_GET_LIST_ERROR';
export const BACK_USERS_EMPTY_LIST = 'BACK_USERS_EMPTY_LIST';
export const BACK_USERS_CREATE_USER_REQUEST = 'BACK_USERS_CREATE_USER_REQUEST';
export const BACK_USERS_CREATE_USER_SUCCESS = 'BACK_USERS_CREATE_USER_SUCCESS';
export const BACK_USERS_GET_DETAIL_USER_REQUEST = 'BACK_USERS_GET_DETAIL_USER_REQUEST';
export const BACK_USERS_GET_DETAIL_USER_SUCCESS = 'BACK_USERS_GET_DETAIL_USER_SUCCESS';
export const BACK_USERS_UPDATE_BACK_USER_REQUEST = 'BACK_USERS_UPDATE_BACK_USER_REQUEST';
export const BACK_USERS_UPDATE_BACK_USER_SUCCESS = 'BACK_USERS_UPDATE_BACK_USER_SUCCESS';
export const BACK_USERS_OPEN_STATUS_BACK_USER_MODAL = 'BACK_USERS_OPEN_STATUS_BACK_USER_MODAL';
export const BACK_USERS_CLOSE_STATUS_BACK_USER_MODAL = 'BACK_USERS_CLOSE_STATUS_BACK_USER_MODAL';
export const BACK_USERS_STATUS_BACK_USER_TOGGLE = 'BACK_USERS_STATUS_BACK_USER_TOGGLE';
export const BACK_USERS_RESET_PASSWORD_BACK_USER_MODAL_TOGGLE = 'BACK_USERS_RESET_PASSWORD_BACK_USER_MODAL_TOGGLE';
export const BACK_USERS_RESET_PASSWORD_BACK_USER_REQUEST = 'BACK_USERS_RESET_PASSWORD_BACK_USER_REQUEST';
export const BACK_USERS_CHANGE_STATUS_BACK_USER_REQUEST = 'BACK_USERS_CHANGE_STATUS_BACK_USER_REQUEST';
export const BACK_USERS_CHANGE_STATUS_BACK_USER_SUCCESS = 'BACK_USERS_CHANGE_STATUS_BACK_USER_SUCCESS';
export const BACK_USERS_FILTERS_FORM_OPENED_STATE_TOGGLE = 'BACK_USERS_FILTERS_FORM_OPENED_STATE_TOGGLE';
export const BACK_USERS_SET_COMPANIES = 'BACK_USERS_SET_COMPANIES';
export const BACK_USERS_SET_SUBCOMPANIES = 'BACK_USERS_SET_SUBCOMPANIES';
export const BACK_USERS_REMOVE_COMPANIES = 'BACK_USERS_REMOVE_COMPANIES';
export const BACK_USERS_EMAIL_MODAL_OPEN = 'BACK_USERS_EMAIL_MODAL_OPEN';
export const BACK_USERS_EMAIL_MODAL_CLOSE = 'BACK_USERS_EMAIL_MODAL_CLOSE';
export const BACK_USERS_CHANGE_EMAIL_MEMBER_SUCCESS = 'BACK_USERS_CHANGE_EMAIL_MEMBER_SUCCESS';

export const MEMBERS_CUSTOM_FIELDS_FILE_REQUEST = 'MEMBERS_CUSTOM_FIELDS_FILE_REQUEST';
export const MEMBERS_CUSTOM_FIELDS_FILE_SUCCESS = 'MEMBERS_CUSTOM_FIELDS_FILE_SUCCESS';
export const MEMBERS_CUSTOM_FIELDS_FILE_ERROR = 'MEMBERS_CUSTOM_FIELDS_FILE_ERROR';
export const MEMBERS_CUSTOM_FIELDS_CLEAR = 'MEMBERS_CUSTOM_FIELDS_CLEAR';
export const MEMBERS_CUSTOM_FIELDS_SUCCESS = 'MEMBERS_CUSTOM_FIELDS_SUCCESS';
export const MEMBERS_GET_LIST_REQUEST = 'MEMBERS_GET_LIST_REQUEST';
export const MEMBERS_GET_LIST_SUCCESS = 'MEMBERS_GET_LIST_SUCCESS';
export const MEMBERS_GET_LIST_ERROR = 'MEMBERS_GET_LIST_ERROR';
export const MEMBERS_GET_DETAIL_MEMBER_REQUEST = 'MEMBERS_GET_DETAIL_MEMBER_REQUEST';
export const MEMBERS_GET_DETAIL_MEMBER_SUCCESS = 'MEMBERS_GET_DETAIL_MEMBER_SUCCESS';
export const MEMBERS_DETAILS_DRIVING_LICENSE_REQUEST = 'MEMBERS_DETAILS_DRIVING_LICENSE_REQUEST';
export const MEMBERS_DETAILS_DRIVING_LICENSE_SUCCESS = 'MEMBERS_DETAILS_DRIVING_LICENSE_SUCCESS';
export const MEMBERS_DETAILS_DRIVING_LICENSE_ERROR = 'MEMBERS_DETAILS_DRIVING_LICENSE_ERROR';
export const MEMBERS_DETAILS_IDENTITY_DOCUMENT_REQUEST = 'MEMBERS_DETAILS_IDENTITY_DOCUMENT_REQUEST';
export const MEMBERS_DETAILS_IDENTITY_DOCUMENT_SUCCESS = 'MEMBERS_DETAILS_IDENTITY_DOCUMENT_SUCCESS';
export const MEMBERS_DETAILS_IDENTITY_DOCUMENT_ERROR = 'MEMBERS_DETAILS_IDENTITY_DOCUMENT_ERROR';
export const MEMBERS_DETAILS_EMPLOYER_CERTIFICATE_REQUEST = 'MEMBERS_DETAILS_EMPLOYER_CERTIFICATE_REQUEST';
export const MEMBERS_DETAILS_EMPLOYER_CERTIFICATE_SUCCESS = 'MEMBERS_DETAILS_EMPLOYER_CERTIFICATE_SUCCESS';
export const MEMBERS_DETAILS_EMPLOYER_CERTIFICATE_ERROR = 'MEMBERS_DETAILS_EMPLOYER_CERTIFICATE_ERROR';
export const MEMBERS_DETAILS_CONTRACT_FILE_REQUEST = 'MEMBERS_DETAILS_CONTRACT_FILE_REQUEST';
export const MEMBERS_DETAILS_CONTRACT_FILE_SUCCESS = 'MEMBERS_DETAILS_CONTRACT_FILE_SUCCESS';
export const MEMBERS_GET_PROFILE_HISTORY_MEMBER_REQUEST = 'MEMBERS_GET_PROFILE_HISTORY_MEMBER_REQUEST';
export const MEMBERS_GET_PROFILE_HISTORY_MEMBER_SUCCESS = 'MEMBERS_GET_PROFILE_HISTORY_MEMBER_SUCCESS';
export const MEMBERS_GET_PROFILE_HISTORY_MEMBER_ERROR = 'MEMBERS_GET_PROFILE_HISTORY_MEMBER_ERROR';
export const MEMBERS_GET_PROFILE_HISTORY_MEMBER_CLEAR = 'MEMBERS_GET_PROFILE_HISTORY_MEMBER_CLEAR';

export const MEMBERS_IMPERSONATE_MEMBER_REQUEST = 'MEMBERS_IMPERSONATE_MEMBER_REQUEST';
export const MEMBERS_IMPERSONATE_MEMBER_SUCCESS = 'MEMBERS_IMPERSONATE_MEMBER_SUCCESS';
export const MEMBERS_DETAILS_COMMENTS_REQUEST = 'MEMBERS_DETAILS_COMMENTS_REQUEST';
export const MEMBERS_DETAILS_COMMENTS_SUCCESS = 'MEMBERS_DETAILS_COMMENTS_SUCCESS';
export const MEMBERS_SET_CURRENT_SORTED_INDEX = 'MEMBERS_SET_CURRENT_SORTED_INDEX';
export const MEMBERS_SET_SORT_DIRECTION = 'MEMBERS_SET_SORT_DIRECTION';
export const MEMBERS_OPEN_RESET_PASSWORD_MEMBER_MODAL = 'MEMBERS_OPEN_RESET_PASSWORD_MEMBER_MODAL';

export const MEMBERS_CLOSE_RESET_PASSWORD_MEMBER_MODAL = 'MEMBERS_CLOSE_RESET_PASSWORD_MEMBER_MODAL';
export const MEMBERS_RESET_PASSWORD_MEMBER_REQUEST = 'MEMBERS_RESET_PASSWORD_MEMBER_REQUEST';
export const MEMBERS_STATUS_MEMBER_TOGGLE = 'MEMBERS_STATUS_MEMBER_TOGGLE';
export const MEMBERS_CHANGE_STATUS_MEMBER_REQUEST = 'MEMBERS_CHANGE_STATUS_MEMBER_REQUEST';

export const MEMBERS_CHANGE_TECHNICIAN_MEMBER_REQUEST = 'MEMBERS_CHANGE_TECHNICIAN_MEMBER_REQUEST';
export const MEMBERS_CHANGE_TECHNICIAN_MEMBER_SUCCESS = 'MEMBERS_CHANGE_TECHNICIAN_MEMBER_SUCCESS';
export const MEMBERS_EXPORT_REQUEST = 'MEMBERS_EXPORT_REQUEST';
export const MEMBERS_EXPORT_FINISHED = 'MEMBERS_EXPORT_FINISHED';
export const MEMBERS_GET_COMPANIES_USED_LIST_REQUEST = 'MEMBERS_GET_COMPANIES_USED_LIST_REQUEST';
export const MEMBERS_GET_COMPANIES_USED_LIST_SUCCESS = 'MEMBERS_GET_COMPANIES_USED_LIST_SUCCESS';
export const MEMBERS_SAVE_COMPANIES_USED_LIST_SUCCESS = 'MEMBERS_SAVE_COMPANIES_USED_LIST_SUCCESS';
export const MEMBERS_EDIT_COMPANIES_USED_LIST_SUCCESS = 'MEMBERS_EDIT_COMPANIES_USED_LIST_SUCCESS';
export const MEMBERS_SAVE_COMPANIES_USED_LIST_REQUEST = 'MEMBERS_SAVE_COMPANIES_USED_LIST_REQUEST';
export const MEMBERS_IMPORTED_SUCCESS = 'MEMBERS_IMPORTED_SUCCESS';
export const MEMBERS_IMPORTED_EMPTY_RESUME = 'MEMBERS_IMPORTED_EMPTY_RESUME';

export const MEMBER_COMMENT_MODAL_OPEN = 'MEMBER_COMMENT_MODAL_OPEN';
export const MEMBER_COMMENT_MODAL_CLOSE = 'MEMBER_COMMENT_MODAL_CLOSE';
export const MEMBER_EMAIL_MODAL_OPEN = 'MEMBER_EMAIL_MODAL_OPEN';
export const MEMBER_EMAIL_MODAL_CLOSE = 'MEMBER_EMAIL_MODAL_CLOSE';
export const MEMBER_OPEN_MIGRATE_MODAL = 'MEMBER_OPEN_MIGRATE_MODAL';
export const MEMBER_CLOSE_MIGRATE_MODAL = 'MEMBER_CLOSE_MIGRATE_MODAL';
export const MEMBER_TOGGLE_HISTORY_PROFLE_MODAL = 'MEMBER_TOGGLE_HISTORY_PROFLE_MODAL';
export const MEMBERS_CHANGE_EMAIL_MEMBER_REQUEST = 'MEMBERS_CHANGE_EMAIL_MEMBER_REQUEST';
export const MEMBERS_CHANGE_EMAIL_MEMBER_SUCCESS = 'MEMBERS_CHANGE_EMAIL_MEMBER_SUCCESS';

export const MEMBER_UPDATE_CHORUS_DATA_SUCCESS = 'MEMBER_UPDATE_CHORUS_DATA_SUCCESS';

export const MEMBER_ASSIGN_TYPE_MODAL_OPEN = 'MEMBER_ASSIGN_TYPE_MODAL_OPEN';
export const MEMBER_ASSIGN_TYPE_MODAL_CLOSE = 'MEMBER_ASSIGN_TYPE_MODAL_CLOSE';
export const MEMBER_ANONYMIZE_DIALOG_OPEN = 'MEMBER_ANONYMIZE_DIALOG_OPEN';
export const MEMBER_ANONYMIZE_DIALOG_CLOSE = 'MEMBER_ANONYMIZE_DIALOG_CLOSE';
export const MEMBER_ANONYMIZE_REQUEST = 'MEMBER_ANONYMIZE_REQUEST';
export const MEMBER_ANONYMIZE_SUCCESS = 'MEMBER_ANONYMIZE_SUCCESS';
export const MEMBER_DEANONYMIZE_REQUEST = 'MEMBER_DEANONYMIZE_REQUEST';
export const MEMBER_DEANONYMIZE_SUCCESS = 'MEMBER_DEANONYMIZE_SUCCESS';
export const MEMBER_PRESUBSCRIPTION_REQUEST = 'MEMBER_PRESUBSCRIPTION_REQUEST';
export const MEMBER_PRESUBSCRIPTION_SUCCESS = 'MEMBER_PRESUBSCRIPTION_SUCCESS';
export const MEMBER_DRIVING_LICENCE_MODAL_OPEN = 'MEMBER_DRIVING_LICENCE_MODAL_OPEN';
export const MEMBER_DRIVING_LICENCE_MODAL_CLOSE = 'MEMBER_DRIVING_LICENCE_MODAL_CLOSE';

export const MEMBER_TYPE_CHANGE_ASSIGNATION = 'MEMBER_TYPE_CHANGE_ASSIGNATION';
export const MEMBER_CHANGE_MEMBER_TYPE_REQUEST = 'MEMBER_CHANGE_MEMBER_TYPE_REQUEST';
export const MEMBER_CHANGE_MEMBER_TYPE_SUCCESS = 'MEMBER_CHANGE_MEMBER_TYPE_SUCCESS';
export const MEMBER_UPDATE_MEMBER_DRIVING_LICENCE_REQUEST = 'MEMBER_UPDATE_MEMBER_DRIVING_LICENCE_REQUEST';
export const MEMBER_UPDATE_MEMBER_DRIVING_LICENCE_SUCCESS = 'MEMBER_UPDATE_MEMBER_DRIVING_LICENCE_SUCCESS';
export const MEMBER_UPDATE_MEMBER_DRIVING_LICENCE_ERROR = 'MEMBER_UPDATE_MEMBER_DRIVING_LICENCE_ERROR';

export const MEMBER_TYPES_GET_LIST_REQUEST = 'MEMBER_TYPES_GET_LIST_REQUEST';
export const MEMBER_TYPES_GET_LIST_SUCCESS = 'MEMBER_TYPES_GET_LIST_SUCCESS';
export const MEMBER_TYPES_GET_LIST_ERROR = 'MEMBER_TYPES_GET_LIST_ERROR';
export const MEMBER_TYPES_CLEAR_LIST_DATA = 'MEMBER_TYPES_CLEAR_LIST_DATA';
export const MEMBER_TYPES_EDIT_MODE = 'MEMBER_TYPES_EDIT_MODE';
export const MEMBER_TYPE_EDIT_OPEN_MODAL = 'MEMBER_TYPE_EDIT_OPEN_MODAL';
export const MEMBER_TYPE_EDIT_CLOSE_MODAL = 'MEMBER_TYPE_EDIT_CLOSE_MODAL';
export const MEMBER_TYPE_EDIT_SUCCEEDED_MODAL = 'MEMBER_TYPE_EDIT_SUCCEEDED_MODAL';
export const MEMBER_TYPE_CREATE_REQUEST = 'MEMBER_TYPE_CREATE_REQUEST';
export const MEMBER_TYPE_CREATE_SUCCESS = 'MEMBER_TYPE_CREATE_SUCCESS';
export const MEMBER_TYPE_CREATE_OPEN_MODAL = 'MEMBER_TYPE_CREATE_OPEN_MODAL';
export const MEMBER_TYPE_CLOSE_MODAL = 'MEMBER_TYPE_CLOSE_MODAL';
export const MEMBER_TYPE_DELETE_OPEN_MODAL = 'MEMBER_TYPE_DELETE_OPEN_MODAL';
export const MEMBER_TYPE_DELETE_REQUEST = 'MEMBER_TYPE_DELETE_REQUEST';
export const MEMBER_TYPE_DELETE_SUCCESS = 'MEMBER_TYPE_DELETE_SUCCESS';

export const BOOKINGS_GET_LIST_ERROR = 'BOOKINGS_GET_LIST_ERROR';
export const BOOKINGS_GET_LIST_REQUEST = 'BOOKINGS_GET_LIST_REQUEST';
export const BOOKINGS_GET_LIST_SUCCESS = 'BOOKINGS_GET_LIST_SUCCESS';
export const BOOKINGS_CANCEL_BOOKING_MODAL_OPEN = 'BOOKINGS_CANCEL_BOOKING_MODAL_OPEN';
export const BOOKINGS_UPDATE_FILTERS = 'BOOKINGS_UPDATE_FILTERS';
export const BOOKINGS_CANCEL_BOOKING_MODAL_CLOSE = 'BOOKINGS_CANCEL_BOOKING_MODAL_CLOSE';
export const BOOKINGS_LIST_SET_ITEM_STATUS = 'BOOKINGS_LIST_SET_ITEM_STATUS';
export const BOOKINGS_DETAIL_UPDATE_VEHICLE = 'BOOKINGS_DETAIL_UPDATE_VEHICLE';
export const BOOKINGS_LIST_UPDATE_VEHICLE = 'BOOKINGS_LIST_UPDATE_VEHICLE';
export const BOOKINGS_CANCEL_BOOKING_REQUEST = 'BOOKINGS_CANCEL_BOOKING_REQUEST';
export const BOOKINGS_CREATE_INVOICE_BOOKING_MODAL_TOGGLE = 'BOOKINGS_CREATE_INVOICE_BOOKING_MODAL_TOGGLE';
export const BOOKINGS_SET_CURRENT_SORTED_INDEX = 'BOOKINGS_SET_CURRENT_SORTED_INDEX';
export const BOOKINGS_SORT_DIRECTION_SET = 'BOOKINGS_SORT_DIRECTION_SET';
export const BOOKINGS_FACETS_SET = 'BOOKINGS_FACETS_SET';
export const BOOKINGS_FILTERS_FORM_OPENED_STATE_TOGGLE = 'BOOKINGS_FILTERS_FORM_OPENED_STATE_TOGGLE';
export const BOOKINGS_GET_DETAILS_REQUEST = 'BOOKINGS_GET_DETAILS_REQUEST';
export const BOOKINGS_GET_DETAILS_SUCCESS = 'BOOKINGS_GET_DETAILS_SUCCESS';
export const BOOKINGS_SET_CURRENT_DELAYED_STATUS = 'BOOKINGS_SET_CURRENT_DELAYED_STATUS';
export const BOOKINGS_EXTEND_SHORTEN_BOOKING_MODAL_OPEN = 'BOOKINGS_EXTEND_SHORTEN_BOOKING_MODAL_OPEN';
export const BOOKINGS_EXTEND_SHORTEN_BOOKING_MODAL_CLOSE = 'BOOKINGS_EXTEND_SHORTEN_BOOKING_MODAL_CLOSE';
export const BOOKINGS_EXTEND_SHORTEN_BOOKING_SUCCESS = 'BOOKINGS_EXTEND_SHORTEN_BOOKING_SUCCESS';
export const BOOKINGS_FINISH_BOOKING_MODAL_OPEN = 'BOOKINGS_FINISH_BOOKING_MODAL_OPEN';
export const BOOKINGS_FINISH_BOOKING_MODAL_CLOSE = 'BOOKINGS_FINISH_BOOKING_MODAL_CLOSE';
export const BOOKINGS_FINISH_BOOKING_REQUEST = 'BOOKINGS_FINISH_BOOKING_REQUEST';
export const BOOKINGS_FINISH_BOOKING_SUCCESS = 'BOOKINGS_FINISH_BOOKING_SUCCESS';
export const BOOKINGS_EXPORT_REQUEST = 'BOOKINGS_EXPORT_REQUEST';
export const BOOKINGS_EXPORTV3_REQUEST = 'BOOKINGS_EXPORTV3_REQUEST';
export const BOOKINGS_EXPORT_FINISHED = 'BOOKINGS_EXPORT_FINISHED';
export const BOOKINGS_EXPORTV3_FINISHED = 'BOOKINGS_EXPORTV3_FINISHED';
export const BOOKINGS_GET_VEHICLE_COMPANY_REQUEST = 'BOOKINGS_GET_VEHICLE_COMPANY_REQUEST';
export const BOOKINGS_GET_VEHICLE_COMPANY_SUCCESS = 'BOOKINGS_GET_VEHICLE_COMPANY_SUCCESS';
export const BOOKINGS_GET_VEHICLE_COMPANY_ERROR = 'BOOKINGS_GET_VEHICLE_COMPANY_ERROR';
export const BOOKINGS_GET_TRANSACTIONS_FOR_BOOKING_REQUEST = 'BOOKINGS_GET_TRANSACTIONS_FOR_BOOKING_REQUEST';
export const BOOKINGS_GET_TRANSACTIONS_FOR_BOOKING_SUCCESS = 'BOOKINGS_GET_TRANSACTIONS_FOR_BOOKING_SUCCESS';
export const BOOKINGS_GET_TRANSACTIONS_FOR_BOOKING_ERROR = 'BOOKINGS_GET_TRANSACTIONS_FOR_BOOKING_ERROR';
export const BOOKINGS_GET_INVOICES_FOR_BOOKING_REQUEST = 'BOOKINGS_GET_INVOICES_FOR_BOOKING_REQUEST';
export const BOOKINGS_GET_INVOICES_FOR_BOOKING_SUCCESS = 'BOOKINGS_GET_INVOICES_FOR_BOOKING_SUCCESS';
export const BOOKINGS_GET_INVOICES_FOR_BOOKING_ERROR = 'BOOKINGS_GET_INVOICES_FOR_BOOKING_ERROR';
export const BOOKINGS_RETRY_BOOKING_REQUEST = 'BOOKINGS_RETRY_BOOKING_REQUEST';
export const BOOKINGS_RETRY_FAILED_BOOKINGS_REQUEST = 'BOOKINGS_RETRY_FAILED_BOOKINGS_REQUEST';
export const BOOKINGS_MEMBER_UPDATE_SUCCESS = 'BOOKINGS_MEMBER_UPDATE_SUCCESS';

export const BOOKING_CLEAR_LIST_DATA = 'BOOKING_CLEAR_LIST_DATA';
export const BOOKING_CLEAR_ALL_DATA = 'BOOKING_CLEAR_ALL_DATA';
export const BOOKING_UPDATE_PRICE_CHECK_SUCCESS = 'BOOKING_UPDATE_PRICE_CHECK_SUCCESS';
export const BOOKING_UPDATE_PRICE_RESPONSE_REMOVE = 'BOOKING_UPDATE_PRICE_RESPONSE_REMOVE';
export const BOOKING_AUTOMATIC_PAYMENT_CHANGE_STATUS_SUCCESS = 'BOOKING_AUTOMATIC_PAYMENT_CHANGE_STATUS_SUCCESS';
export const BOOKING_SETTLE_SUCCESS = 'BOOKING_SETTLE_SUCCESS';
export const BOOKING_RETRY_ITALIAN_INVOICE = 'BOOKING_RETRY_ITALIAN_INVOICE';
export const BOOKING_CREATE_SET_SELECTED_MEMBER = 'BOOKING_CREATE_SET_SELECTED_MEMBER';
export const BOOKING_CREATE_SET_SELECTED_VEHICLE = 'BOOKING_CREATE_SET_SELECTED_VEHICLE';
export const BOOKING_CREATE_CLOSE_CONFIRMATION_MODAL = 'BOOKING_CREATE_CLOSE_CONFIRMATION_MODAL';
export const BOOKING_CREATE_SET_CONFIRMATION_DETAILS = 'BOOKING_CREATE_SET_CONFIRMATION_DETAILS';
export const BOOKING_CREATE_CONFIRM_CREATION_REQUEST = 'BOOKING_CREATE_CONFIRM_CREATION_REQUEST';
export const BOOKING_CREATE_CONFIRM_CREATION_REQUEST_SUCCESS = 'BOOKING_CREATE_CONFIRM_CREATION_REQUEST_SUCCESS';
export const BOOKING_CREATE_CONFIRM_CREATION_REQUEST_ERROR = 'BOOKING_CREATE_CONFIRM_CREATION_REQUEST_ERROR';
export const BOOKING_CREATE_CONFIRM_EDITION_REQUEST = 'BOOKING_CREATE_CONFIRM_EDITION_REQUEST';
export const BOOKING_CREATE_CONFIRM_EDITION_REQUEST_SUCCESS = 'BOOKING_CREATE_CONFIRM_EDITION_REQUEST_SUCCESS';
export const BOOKING_CREATE_CLEAR_CREATION_SESSION = 'BOOKING_CREATE_CLEAR_CREATION_SESSION';
export const BOOKING_FIND_MEMBER_SET_CURRENT_SORTED_INDEX = 'BOOKING_FIND_MEMBERS_SET_CURRENT_SORTED_INDEX';

export const BOOKING_FIND_MEMBER_SET_SORT_DIRECTION = 'BOOKING_FIND_MEMBERS_SET_SORT_DIRECTION';
export const BOOKING_FIND_VEHICLE_GET_LIST_REQUEST = 'BOOKING_FIND_VEHICLE_GET_LIST_REQUEST';
export const BOOKING_FIND_VEHICLE_GET_LIST_REQUEST_SUCCESS = 'BOOKING_FIND_VEHICLE_GET_LIST_REQUEST_SUCCESS';
export const BOOKING_FIND_VEHICLE_TOGGLE_MEMBER_INFOS = 'BOOKING_FIND_VEHICLE_TOGGLE_MEMBER_INFOS';
export const BOOKING_CREATE_SET_EDIT_MODE = 'BOOKING_CREATE_SET_EDIT_MODE';
export const BOOKING_START_MODAL_OPEN = 'BOOKING_START_MODAL_OPEN';
export const BOOKING_START_MODAL_CLOSE = 'BOOKING_START_MODAL_CLOSE';
export const BOOKING_START_REQUEST = 'BOOKING_START_REQUEST';
export const BOOKING_START_SUCCESS = 'BOOKING_START_SUCCESS';
export const BOOKING_START_ERROR = 'BOOKING_START_ERROR';
export const BOOKING_UPDATE_PRICE_MODAL_TOGGLE = 'BOOKING_UPDATE_PRICE_MODAL_TOGGLE';

export const BOOKING_GET_ERRORS_PROPLANNER_REQUEST = 'BOOKING_GET_ERRORS_PROPLANNER_REQUEST';
export const BOOKING_GET_ERRORS_PROPLANNER_SUCCESS = 'BOOKING_GET_ERRORS_PROPLANNER_SUCCESS';
export const BOOKING_GET_ERRORS_PROPLANNER_CLEAR = 'BOOKING_GET_ERRORS_PROPLANNER_CLEAR';
export const BOOKING_FIX_ERRORS_PROPLANNER_MODAL_OPEN = 'BOOKING_FIX_ERRORS_PROPLANNER_MODAL_OPEN';
export const BOOKING_FIX_ERRORS_PROPLANNER_MODAL_CLOSE = 'BOOKING_FIX_ERRORS_PROPLANNER_MODAL_CLOSE';

export const BOOKING_UPDATE_CHANGE_VEHICLE = 'BOOKING_UPDATE_CHANGE_VEHICLE';
export const BOOKING_EVENTS_HISTORY_REQUEST = 'BOOKING_EVENTS_HISTORY_REQUEST';
export const BOOKING_EVENTS_HISTORY_SUCCESS = 'BOOKING_EVENTS_HISTORY_SUCCESS';
export const BOOKING_EVENTS_HISTORY_ERROR = 'BOOKING_EVENTS_HISTORY_ERROR';
export const BOOKING_DRIVING_LICENSE_DETAILS_REQUEST = 'BOOKING_DRIVING_LICENSE_DETAILS_REQUEST';
export const BOOKING_DRIVING_LICENSE_DETAILS_ERROR = 'BOOKING_DRIVING_LICENSE_DETAILS_ERROR';
export const BOOKING_DRIVING_LICENSE_DETAILS_SUCCESS = 'BOOKING_DRIVING_LICENSE_DETAILS_SUCCESS';
export const BOOKING_DRIVING_LICENSE_FILES_EMPTY = 'BOOKING_DRIVING_LICENSE_FILES_EMPTY';

export const COMPANIES_PREVIEW_INVOICE_REQUEST = 'COMPANIES_PREVIEW_INVOICE_REQUEST';
export const COMPANIES_PREVIEW_INVOICE_SUCCESS = 'COMPANIES_PREVIEW_INVOICE_SUCCESS';
export const COMPANIES_PREVIEW_INVOICE_ERROR = 'COMPANIES_PREVIEW_INVOICE_ERROR';
export const COMPANIES_PREVIEW_INVOICE_CLEAR = 'COMPANIES_PREVIEW_INVOICE_CLEAR';

export const COMPANIES_GET_LIST_REQUEST = 'COMPANIES_GET_LIST_REQUEST';
export const COMPANIES_GET_HEADER_LIST_REQUEST = 'COMPANIES_GET_HEADER_LIST_REQUEST';
export const COMPANIES_GET_HEADER_LIST_SUCCESS = 'COMPANIES_GET_HEADER_LIST_SUCCESS';
export const COMPANIES_CLEAR_HEADER_LIST_DATA = 'COMPANIES_CLEAR_HEADER_LIST_DATA';

export const COMPANIES_GET_LIST_WITH_SEARCH_SUCCESS = 'COMPANIES_GET_LIST_WITH_SEARCH_SUCCESS';
export const COMPANIES_GET_LIST_WITH_SEARCH_ERROR = 'COMPANIES_GET_LIST_WITH_SEARCH_ERROR';
export const COMPANIES_SORT_DIRECTION_SET = 'COMPANIES_SORT_DIRECTION_SET';
export const COMPANIES_CREATE_COMPANY_REQUEST = 'COMPANIES_CREATE_COMPANY_REQUEST';
export const COMPANIES_CREATE_COMPANY_MODAL_SUCCESS = 'COMPANIES_CREATE_COMPANY_MODAL_SUCCESS';
export const COMPANIES_SET_CURRENT_COMPANY = 'COMPANIES_SET_CURRENT_COMPANY';
export const COMPANIES_SET_CURRENT_COMPANY_DETAILS = 'COMPANIES_SET_CURRENT_COMPANY_DETAILS';
export const COMPANIES_SET_CURRENT_INDEX = 'COMPANIES_SET_CURRENT_INDEX';
export const COMPANIES_GET_DETAIL_COMPANY_REQUEST = 'COMPANIES_GET_DETAIL_COMPANY_REQUEST';
export const COMPANIES_GET_DETAIL_COMPANY_SUCCESS = 'COMPANIES_GET_DETAIL_COMPANY_SUCCESS';
export const COMPANIES_GET_CONNECTED_ACCOUNT_REQUEST = 'COMPANIES_GET_CONNECTED_ACCOUNT_REQUEST';
export const COMPANIES_GET_CONNECTED_ACCOUNT_SUCCESS = 'COMPANIES_GET_CONNECTED_ACCOUNT_SUCCESS';
export const COMPANIES_GET_CONNECTED_ACCOUNT_ERROR = 'COMPANIES_GET_CONNECTED_ACCOUNT_ERROR';
export const COMPANIES_SET_CONNECTED_ACCOUNT_REQUEST = 'COMPANIES_SET_CONNECTED_ACCOUNT_REQUEST';
export const COMPANIES_SET_CONNECTED_ACCOUNT_SUCCESS = 'COMPANIES_SET_CONNECTED_ACCOUNT_SUCCESS';
export const COMPANIES_SET_CONNECTED_ACCOUNT_ERROR = 'COMPANIES_SET_CONNECTED_ACCOUNT_ERROR';
export const COMPANIES_REMOVE_CONNECTED_ACCOUNT_REQUEST = 'COMPANIES_REMOVE_CONNECTED_ACCOUNT_REQUEST';
export const COMPANIES_REMOVE_CONNECTED_ACCOUNT_SUCCESS = 'COMPANIES_REMOVE_CONNECTED_ACCOUNT_SUCCESS';
export const COMPANIES_REMOVE_CONNECTED_ACCOUNT_FAILED = 'COMPANIES_REMOVE_CONNECTED_ACCOUNT_FAILED';
export const COMPANIES_GET_DETAIL_COMPANY_CLEAR = 'COMPANIES_GET_DETAIL_COMPANY_CLEAR';
export const COMPANIES_GET_CURRENT_INVOICE_PARAMS_REQUEST = 'COMPANIES_GET_CURRENT_INVOICE_PARAMS_REQUEST';
export const COMPANIES_GET_CURRENT_INVOICE_PARAMS_SUCCESS = 'COMPANIES_GET_CURRENT_INVOICE_PARAMS_SUCCESS';
export const COMPANIES_GET_CURRENT_INVOICE_PARAMS_NO_CONTENT = 'COMPANIES_GET_CURRENT_INVOICE_PARAMS_NO_CONTENT';
export const COMPANIES_SET_CURRENT_INVOICE_PARAMS_FAILED = 'COMPANIES_SET_CURRENT_INVOICE_PARAMS_FAILED';
export const COMPANIES_SET_CURRENT_INVOICE_PARAMS_REQUEST = 'COMPANIES_SET_CURRENT_INVOICE_PARAMS_REQUEST';
export const COMPANIES_SET_CURRENT_INVOICE_PARAMS_SUCCESS = 'COMPANIES_SET_CURRENT_INVOICE_PARAMS_SUCCESS';
export const COMPANIES_GET_INVOICE_VAT_RATE_PARAM_REQUEST = 'COMPANIES_GET_INVOICE_VAT_RATE_PARAM_REQUEST';
export const COMPANIES_GET_INVOICE_VAT_RATE_PARAM_SUCCESS = 'COMPANIES_GET_INVOICE_VAT_RATE_PARAM_SUCCESS';
export const COMPANIES_GET_INVOICE_VAT_RATE_PARAM_NO_CONTENT = 'COMPANIES_GET_INVOICE_VAT_RATE_PARAM_NO_CONTENT';
export const COMPANIES_FILE_IMPORTED_REQUEST = 'COMPANIES_FILE_IMPORTED_REQUEST';
export const COMPANIES_FILE_IMPORTED_SUCCESS = 'COMPANIES_FILE_IMPORTED_SUCCESS';
export const COMPANIES_FILE_IMPORTED_ERROR = 'COMPANIES_FILE_IMPORTED_ERROR';
export const COMPANIES_FILES_IMPORTED_EMPTY = 'COMPANIES_FILES_IMPORTED_EMPTY';
export const COMPANIES_FILE_IMPORTED_CLEAN = 'COMPANIES_FILE_IMPORTED_CLEAN';
export const COMPANIES_FILE_IMPORTED_CLEAR_ERROR = 'COMPANIES_FILE_IMPORTED_CLEAR_ERROR';
export const COMPANIES_FILE_IMPORTED_REMOVE = 'COMPANIES_FILE_IMPORTED_REMOVE';
export const COMPANIES_PAYMENT_DETAILS_REQUEST = 'COMPANIES_PAYMENT_DETAILS_REQUEST';
export const COMPANIES_PAYMENT_DETAILS_SUCCESS = 'COMPANIES_PAYMENT_DETAILS_SUCCESS';

export const FILE_UPLOAD_RESET_STATE = 'FILE_UPLOAD_RESET_STATE';

export const COMPANY_GET_PAYBACK_CONFIG_SUCCESS = 'COMPANY_GET_PAYBACK_CONFIG_SUCCESS';
export const COMPANY_GET_PAYBACK_CONFIG_ERROR = 'COMPANY_GET_PAYBACK_CONFIG_ERROR';
export const COMPANY_GET_INVERS_FLEET_SUCCESS = 'COMPANY_GET_INVERS_FLEET_SUCCESS';
export const COMPANY_GET_INVERS_FLEET_ERROR = 'COMPANY_GET_INVERS_FLEET_ERROR';
export const COMPANY_CLEAR_PAYBACK_SETTINGS_DATA = 'COMPANY_CLEAR_PAYBACK_SETTINGS_DATA';
export const COMPANY_CLEAR_TEMPORARY_BANK_DETAILS = 'COMPANY_CLEAR_TEMPORARY_BANK_DETAILS';
export const COMPANY_PAYMENT_PROVIDER_STRIPE_MIGRATION = 'COMPANY_PAYMENT_PROVIDER_STRIPE_MIGRATION';

export const SAVE_REDIRECT_LOCATION = 'SAVE_REDIRECT_LOCATION';
export const CLEAR_REDIRECT_LOCATION = 'CLEAR_REDIRECT_LOCATION';

export const COMPANY_SAVE_PAYBACK_REDIRECT_DATA = 'COMPANY_SAVE_PAYBACK_REDIRECT_DATA';
export const START_PAYBACK_REDIRECT_SPIN = 'START_PAYBACK_REDIRECT_SPIN';
export const STOP_PAYBACK_REDIRECT_SPIN = 'STOP_PAYBACK_REDIRECT_SPIN';
export const AFTER_PAYBACK_REDIRECT_TRUE = 'AFTER_PAYBACK_REDIRECT_TRUE';
export const AFTER_PAYBACK_REDIRECT_FALSE = 'AFTER_PAYBACK_REDIRECT_FALSE';
export const AFTER_PAYBACK_REDIRECT_ACTION = 'AFTER_PAYBACK_REDIRECT_ACTION';

export const SUB_COMPANY_SAVE_PAYBACK_REDIRECT_DATA = 'SUB_COMPANY_SAVE_PAYBACK_REDIRECT_DATA';
export const SUB_COMPANY_CLEAR_PAYBACK_SETTINGS_DATA = 'SUB_COMPANY_CLEAR_PAYBACK_SETTINGS_DATA';
export const SUB_COMPANY_CLEAR_TEMPORARY_BANK_DETAILS = 'SUB_COMPANY_CLEAR_TEMPORARY_BANK_DETAILS';
export const SUB_COMPANY_GET_PAYBACK_CONFIG_SUCCESS = 'SUB_COMPANY_GET_PAYBACK_CONFIG_SUCCESS';
export const SUB_COMPANY_GET_PAYBACK_CONFIG_ERROR = 'SUB_COMPANY_GET_PAYBACK_CONFIG_ERROR';

export const COMPANIES_SAVE_CONTRACT_REQUEST = 'COMPANIES_SAVE_CONTRACT_REQUEST';
export const CONTRACTS_SAVE_CONTRACT_SUCCESS = 'CONTRACTS_SAVE_CONTRACT_SUCCESS';
export const COMPANIES_GET_CURRENT_CONTRACT_REQUEST = 'COMPANIES_GET_CURRENT_CONTRACT_REQUEST';
export const COMPANIES_GET_CURRENT_CONTRACT_SUCCESS = 'COMPANIES_GET_CURRENT_CONTRACT_SUCCESS';
export const COMPANIES_CLEAR_CURRENT_CONTRACT = 'COMPANIES_CLEAR_CURRENT_CONTRACT';
export const COMPANIES_UPDATE_COMPANY_REQUEST = 'COMPANIES_UPDATE_COMPANY_REQUEST';
export const COMPANIES_UPDATE_COMPANY_SUCCESS = 'COMPANIES_UPDATE_COMPANY_SUCCESS';

export const COMPANIES_GET_SINGLE_COMPANY_USER_CONTRACT_SUCCESS = 'COMPANIES_GET_SINGLE_COMPANY_USER_CONTRACT_SUCCESS';
export const COMPANIES_CLEAR_SINGLE_COMPANY_USER_CONTRACT = 'COMPANIES_CLEAR_SINGLE_COMPANY_USER_CONTRACT';

export const COMPANIES_AUTHORIZE_APPLICATION_LIST_SUCCESS = 'COMPANIES_AUTHORIZE_APPLICATION_LIST_SUCCESS';
export const COMPANIES_UNAUTHORIZE_APPLICATION_LIST_SUCCESS = 'COMPANIES_UNAUTHORIZE_APPLICATION_LIST_SUCCESS';
export const COMPANIES_DRIVING_AUTHORISATION_PDF_PREVIEW_TOGGLE = 'COMPANIES_DRIVING_AUTHORISATION_PDF_PREVIEW_TOGGLE';

export const COMPANIES_GET_EMAIL_SETUP_REQUEST = 'COMPANIES_GET_EMAIL_SETUP_REQUEST';
export const COMPANIES_GET_EMAIL_SETUP_SUCCESS = 'COMPANIES_GET_EMAIL_SETUP_SUCCESS';
export const COMPANIES_GET_EMAIL_SETUP_ERROR = 'COMPANIES_GET_EMAIL_SETUP_ERROR';

export const COMPANIES_PULL_EMAIL_SETUP_ERROR = 'COMPANIES_PULL_EMAIL_SETUP_ERROR';
export const COMPANIES_PULL_EMAIL_SETUP_REQUEST = 'COMPANIES_PULL_EMAIL_SETUP_REQUEST';

export const COMPANIES_UPDATE_EMAIL_SETUP_REQUEST = 'COMPANIES_UPDATE_EMAIL_SETUP_REQUEST';
export const COMPANIES_UPDATE_EMAIL_SETUP_SUCCESS = 'COMPANIES_UPDATE_EMAIL_SETUP_SUCCESS';
export const COMPANIES_UPDATE_EMAIL_SETUP_ERROR = 'COMPANIES_UPDATE_EMAIL_SETUP_ERROR';

export const COMPANIES_DELETE_EMAIL_SETUP_REQUEST = 'COMPANIES_DELETE_EMAIL_SETUP_REQUEST';
export const COMPANIES_DELETE_EMAIL_SETUP_SUCCESS = 'COMPANIES_DELETE_EMAIL_SETUP_SUCCESS';
export const COMPANIES_DELETE_EMAIL_SETUP_ERROR = 'COMPANIES_DELETE_EMAIL_SETUP_ERROR';

export const COMPANIES_CURRENCIES_LIST_REQUEST = 'COMPANIES_CURRENCIES_LIST_REQUEST';
export const COMPANIES_CURRENCIES_LIST_SUCCESS = 'COMPANIES_CURRENCIES_LIST_SUCCESS';

export const CUSTOM_FIELDS_BOOKING_PICK = 'CUSTOM_FIELDS_BOOKING_PICK';
export const CUSTOM_FIELDS_SUBSCRIPTION_PICK = 'CUSTOM_FIELDS_SUBSCRIPTION_PICK';
export const CUSTOM_FIELDS_GET_SUCCESS = 'CUSTOM_FIELDS_GET_SUCCESS';
export const CUSTOM_FIELDS_SUBSCRIPTION_GET_SUCCESS = 'CUSTOM_FIELDS_SUBSCRIPTION_GET_SUCCESS';
export const CUSTOM_FIELDS_BOOKINGS_GET_SUCCESS = 'CUSTOM_FIELDS_BOOKINGS_GET_SUCCESS';
export const CUSTOM_FIELDS_BOOKING_UNPICK = 'CUSTOM_FIELDS_BOOKING_UNPICK';
export const CUSTOM_FIELDS_SUBSCRIPTION_UNPICK = 'CUSTOM_FIELDS_SUBSCRIPTION_UNPICK';
export const CUSTOM_FIELDS_UPDATE_SUCCESS = 'CUSTOM_FIELDS_UPDATE_SUCCESS';
export const CUSTOM_FIELDS_SAVE_SUCCESS = 'CUSTOM_FIELDS_SAVE_SUCCESS';

export const SITES_GET_LIST_REQUEST = 'SITES_GET_LIST_REQUEST';
export const SITES_GET_LIST_SUCCESS = 'SITES_GET_LIST_SUCCESS';
export const SITES_GET_LIST_CLEAR = 'SITES_GET_LIST_CLEAR';

export const GET_SITES_NO_STATE_REQUEST = 'GET_SITES_NO_STATE_REQUEST';
export const GET_SITES_NO_STATE_SUCCESS = 'GET_SITES_NO_STATE_SUCCESS';
export const GET_SITES_NO_STATE_ERROR = 'GET_SITES_NO_STATE_ERROR';

export const SITES_CREATE_SITE_REQUEST = 'SITES_CREATE_SITE_REQUEST';
export const SITES_CREATE_SITE_SUCCESS = 'SITES_CREATE_SITE_SUCCESS';
export const SITES_GET_DETAIL_SITE_REQUEST = 'SITES_GET_DETAIL_SITE_REQUEST';
export const SITES_GET_DETAIL_SITE_SUCCESS = 'SITES_GET_DETAIL_SITE_SUCCESS';
export const SITES_UPDATE_SITE_REQUEST = 'SITES_UPDATE_SITE_REQUEST';
export const SITES_UPDATE_SITE_SUCCESS = 'SITES_UPDATE_SITE_SUCCESS';
export const SITE_COPY_TO_NEW = 'SITE_COPY_TO_NEW';
export const SITE_CLEAN_DUP = 'SITE_CLEAN_DUP';

export const PARKINGS_GET_LIST_REQUEST = 'PARKINGS_GET_LIST_REQUEST';
export const PARKINGS_GET_LIST_SUCCESS = 'PARKINGS_GET_LIST_SUCCESS';
export const PARKINGS_GET_LIST_ERROR = 'PARKINGS_GET_LIST_ERROR';

export const GET_PARKINGS_NO_STATE_REQUEST = 'GET_PARKINGS_NO_STATE_REQUEST';
export const GET_PARKINGS_NO_STATE_SUCCESS = 'GET_PARKINGS_NO_STATE_SUCCESS';
export const GET_PARKINGS_NO_STATE_ERROR = 'GET_PARKINGS_NO_STATE_ERROR';

export const PARKINGS_CLEAR_LIST = 'PARKINGS_CLEAR_LIST';
export const PARKINGS_CREATE_PARKING_REQUEST = 'PARKINGS_CREATE_PARKING_REQUEST';
export const PARKINGS_CREATE_PARKING_SUCCESS = 'PARKINGS_CREATE_PARKING_SUCCESS';
export const PARKINGS_GET_DETAIL_PARKING_REQUEST = 'PARKINGS_GET_DETAIL_PARKING_REQUEST';
export const PARKINGS_GET_DETAIL_PARKING_SUCCESS = 'PARKINGS_GET_DETAIL_PARKING_SUCCESS';
export const PARKINGS_GET_BANK_HOLIDAYS_PARKING_REQUEST = 'PARKINGS_GET_BANK_HOLIDAYS_PARKING_REQUEST';
export const PARKINGS_GET_BANK_HOLIDAYS_PARKING_SUCCESS = 'PARKINGS_GET_BANK_HOLIDAYS_PARKING_SUCCESS';
export const PARKINGS_UPDATE_PARKING_REQUEST = 'PARKINGS_UPDATE_PARKING_REQUEST';
export const PARKINGS_UPDATE_PARKING_SUCCESS = 'PARKINGS_UPDATE_PARKING_SUCCESS';
export const PARKINGS_CONFIRMATION_MODAL_OPEN = 'PARKINGS_CONFIRMATION_MODAL_OPEN';
export const PARKINGS_CONFIRMATION_MODAL_CLOSE = 'PARKINGS_CONFIRMATION_MODAL_CLOSE';
export const PARKINGS_SET_IS_OPEN = 'PARKINGS_SET_IS_OPEN';

export const COLORS_CREATE_SUCCESS = 'COLORS_CREATE_SUCCESS';
export const COLORS_GET_COLORS_SUCCESS = 'COLORS_GET_COLORS_SUCCESS';
export const COLORS_GET_SINGLE_COLOR_SUCCESS = 'COLORS_GET_SINGLE_COLOR_SUCCESS';
export const COLORS_UPDATE_COLOR_REQUEST = 'COLORS_UPDATE_COLOR_REQUEST';
export const COLORS_UPDATE_COLOR_SUCCESS = 'COLORS_UPDATE_COLOR_SUCCESS';

export const CATEGORIES_GET_CATEGORIES_SUCCESS = 'CATEGORIES_GET_CATEGORIES_SUCCESS';
export const CATEGORIES_GET_SINGLE_CATEGORY_REQUEST = 'CATEGORIES_GET_SINGLE_CATEGORY_REQUEST';
export const CATEGORIES_GET_SINGLE_CATEGORY_SUCCESS = 'CATEGORIES_GET_SINGLE_CATEGORY_SUCCESS';
export const CATEGORIES_CREATE_CATEGORY_SUCCESS = 'CATEGORIES_CREATE_CATEGORY_SUCCESS';
export const CATEGORIES_UPDATE_CATEGORY_REQUEST = 'CATEGORIES_UPDATE_CATEGORY_REQUEST';
export const CATEGORIES_UPDATE_CATEGORY_SUCCESS = 'CATEGORIES_UPDATE_CATEGORY_SUCCESS';

export const BRANDS_GET_LIST_ERROR = 'BRANDS_GET_LIST_ERROR';
export const BRANDS_GET_LIST_REQUEST = 'BRANDS_GET_LIST_REQUEST';
export const BRANDS_GET_LIST_SUCCESS = 'BRANDS_GET_LIST_SUCCESS';
export const BRANDS_CREATE_BRAND_SUCCESS = 'BRANDS_CREATE_BRAND_SUCCESS';
export const BRANDS_GET_SINGLE_BRAND_SUCCESS = 'BRANDS_GET_SINGLE_BRAND_SUCCESS';
export const BRANDS_UPDATE_BRAND_SUCCESS = 'BRANDS_UPDATE_BRAND_SUCCESS';

export const ORGANIZATION_CREATE_BRAND_SUCCESS = 'ORGANIZATION_CREATE_BRAND_SUCCESS';
export const ORGANIZATION_SET_BRAND_EDIT_DATA = 'ORGANIZATION_SET_BRAND_EDIT_DATA';

export const MODELS_GET_LIST_SUCCESS = 'MODELS_GET_LIST_SUCCESS';
export const MODELS_GET_LIST_REQUEST = 'MODELS_GET_LIST_REQUEST';
export const MODELS_GET_LIST_ERROR = 'MODELS_GET_LIST_ERROR';

export const MODELS_GET_LIST_EMPTY = 'MODELS_GET_LIST_EMPTY';
export const MODELS_CREATE_MODEL_SUCCESS = 'MODELS_CREATE_MODEL_SUCCESS';
export const MODELS_GET_SINGLE_MODEL_SUCCESS = 'MODELS_GET_SINGLE_MODEL_SUCCESS';
export const MODELS_UPDATE_MODEL_SUCCESS = 'MODELS_UPDATE_MODEL_SUCCESS';

export const VERSIONS_EMPTY_LIST = 'VERSIONS_EMPTY_LIST';
export const VERSIONS_GET_LIST_SUCCESS = 'VERSIONS_GET_LIST_SUCCESS';
export const VERSIONS_CREATE_VERSION_SUCCESS = 'VERSIONS_CREATE_VERSION_SUCCESS';
export const VERSIONS_GET_SINGLE_VERSION_SUCCESS = 'VERSIONS_GET_SINGLE_VERSION_SUCCESS';
export const VERSIONS_UPDATE_VERSION_SUCCESS = 'VERSIONS_UPDATE_VERSION_SUCCESS';

export const DASHBOARD_GET_UNMANAGED_DAMAGES_SUCCESS = 'DASHBOARD_GET_UNMANAGED_DAMAGES_SUCCESS';
export const DASHBOARD_GET_CLEANLINESS_PROBLEMS_SUCCESS = 'DASHBOARD_GET_CLEANLINESS_PROBLEMS_SUCCESS';
export const DASHBOARD_GET_LAST_BOOKINGS_SUCCESS = 'DASHBOARD_GET_LAST_BOOKINGS_SUCCESS';
export const DASHBOARD_GET_DELAYED_BOOKINGS_SUCCESS = 'DASHBOARD_GET_DELAYED_BOOKINGS_SUCCESS';
export const DASHBOARD_GET_NON_VALIDATED_USER_SUCCESS = 'DASHBOARD_GET_NON_VALIDATED_USER_SUCCESS';
export const DASHBOARD_GET_FAILED_BOOKINGS_SUCCESS = 'DASHBOARD_GET_FAILED_BOOKINGS_SUCCESS';
export const DASHBOARD_GET_EXPEDITE_MEMBERS_SUCCESS = 'DASHBOARD_GET_EXPEDITE_MEMBERS_SUCCESS';
export const DASHBOARD_GET_FAILED_RRS_BOOKINGS_SUCCESS = 'DASHBOARD_GET_FAILED_RRS_BOOKINGS_SUCCESS';
export const DASHBOARD_GET_LOW_ACCESSORY_BATTERIE_VEHICLES_SUCCESS = 'DASHBOARD_GET_LOW_ACCESSORY_BATTERIE_VEHICLES_SUCCESS';
export const DASHBOARD_GET_NON_OPERATING_VEHICLES_SUCCESS = 'DASHBOARD_GET_NON_OPERATING_VEHICLES_SUCCESS';
export const DASHBOARD_GET_LOW_FUEL_CHARGE_LEVEL_SUCCESS = 'DASHBOARD_GET_LOW_FUEL_CHARGE_LEVEL_SUCCESS';
export const DASHBOARD_RESET_ITEMS_COUNT = 'DASHBOARD_RESET_ITEMS_COUNT';

export const FEEDBACKS_GET_LIST_REQUEST = 'FEEDBACKS_GET_LIST_REQUEST';
export const FEEDBACKS_GET_LIST_SUCCESS = 'FEEDBACKS_GET_LIST_SUCCESS';
export const FEEDBACKS_GET_DETAIL_FEEDBACK_REQUEST = 'FEEDBACKS_GET_DETAIL_FEEDBACK_REQUEST';
export const FEEDBACKS_GET_DETAIL_FEEDBACK_SUCCESS = 'FEEDBACKS_GET_DETAIL_FEEDBACK_SUCCESS';
export const FEEDBACKS_GET_DETAIL_REPORT_IMAGE_REQUEST = 'FEEDBACKS_GET_DETAIL_REPORT_IMAGE_REQUEST';
export const FEEDBACKS_GET_DETAIL_REPORT_IMAGE_SUCCESS = 'FEEDBACKS_GET_DETAIL_REPORT_IMAGE_SUCCESS';
export const FEEDBACKS_GET_DAMAGE_REPORT_IMAGE_REQUEST = 'FEEDBACKS_GET_DAMAGE_REPORT_IMAGE_REQUEST';
export const FEEDBACKS_GET_DAMAGE_REPORT_IMAGE_SUCCESS = 'FEEDBACKS_GET_DAMAGE_REPORT_IMAGE_SUCCESS';
export const FEEDBACKS_SET_FEEDBACK_AS_DONE_REQUEST = 'FEEDBACKS_SET_FEEDBACK_AS_DONE_REQUEST';
export const FEEDBACKS_SET_FEEDBACK_AS_DONE_SUCCESS = 'FEEDBACKS_SET_FEEDBACK_AS_DONE_SUCCESS';
export const FEEDBACKS_SET_CURRENT_SORTED_INDEX = 'FEEDBACKS_SET_CURRENT_SORTED_INDEX';
export const FEEDBACKS_SORT_DIRECTION_SET = 'FEEDBACKS_SORT_DIRECTION_SET';
export const FEEDBACKS_FILTERS_FORM_OPENED_STATE_TOGGLE = 'FEEDBACKS_FILTERS_FORM_OPENED_STATE_TOGGLE';
export const FEEDBACKS_ADD_COMMENT_MODAL_OPEN = 'FEEDBACKS_ADD_COMMENT_MODAL_OPEN';
export const FEEDBACKS_ADD_COMMENT_MODAL_CLOSE = 'FEEDBACKS_ADD_COMMENT_MODAL_CLOSE';
export const FEEDBACKS_STATUS_CURRENTLY_UPDATED = 'FEEDBACKS_STATUS_CURRENTLY_UPDATED';

export const APPLICATIONS_GET_ALL_REQUEST = 'APPLICATIONS_GET_ALL_REQUEST';
export const APPLICATIONS_GET_ALL_SUCCESS = 'APPLICATIONS_GET_ALL_SUCCESS';

export const APP_BRANDS_GET_LIST_SUCCESS = 'APP_BRANDS_GET_LIST_SUCCESS';
export const APP_BRANDS_UPDATE_ITEM_SUCCESS = 'APP_BRANDS_UPDATE_ITEM_SUCCESS';
export const APP_BRANDS_CLEAR_LIST_SUCCESS = 'APP_BRANDS_CLEAR_LIST_SUCCESS';
export const APP_BRANDS_UPDATE_CONFIG = 'APP_BRANDS_UPDATE_CONFIG';
export const APP_BRANDS_SET_CONFIG_FETCHED_STATE = 'APP_BRANDS_SET_CONFIG_FETCHED_STATE';
export const APP_BRANDS_ROUTE_LOADED = 'APP_BRANDS_ROUTE_LOADED';
export const APP_BRANDS_SHOW_ERROR = 'APP_BRANDS_SHOW_ERROR';
export const APP_BRANDS_UPDATE_BANNER_STYLE = 'APP_BRANDS_UPDATE_BANNER_STYLE';
export const APP_BRANDS_UPDATE_BANNER_MESSAGE = 'APP_BRANDS_UPDATE_BANNER_MESSAGE';
export const APP_BRANDS_UPDATE_BANNER_DISPLAY = 'APP_BRANDS_UPDATE_BANNER_DISPLAY';

export const CONFIGURATION_GET_LIST_REQUEST = 'CONFIGURATION_GET_LIST_REQUEST';
export const CONFIGURATION_GET_LIST_SUCCESS = 'CONFIGURATION_GET_LIST_SUCCESS';
export const CONFIGURATION_GET_ALL_LIST_REQUEST = 'CONFIGURATION_GET_ALL_LIST_REQUEST';
export const CONFIGURATION_GET_ALL_LIST_SUCCESS = 'CONFIGURATION_GET_ALL_LIST_SUCCESS';
export const CONFIGURATION_CREATE_CONFIGURATION_REQUEST = 'CONFIGURATION_CREATE_CONFIGURATION_REQUEST';
export const CONFIGURATION_CREATE_CONFIGURATION_SUCCESS = 'CONFIGURATION_CREATE_CONFIGURATION_SUCCESS';
export const CONFIGURATION_GET_DETAIL_CONFIGURATION_REQUEST = 'CONFIGURATION_GET_DETAIL_CONFIGURATION_REQUEST';
export const CONFIGURATION_GET_DETAIL_CONFIGURATION_SUCCESS = 'CONFIGURATION_GET_DETAIL_CONFIGURATION_SUCCESS';
export const CONFIGURATION_UPDATE_CONFIGURATION_REQUEST = 'CONFIGURATION_UPDATE_CONFIGURATION_REQUEST';
export const CONFIGURATION_UPDATE_CONFIGURATION_SUCCESS = 'CONFIGURATION_UPDATE_CONFIGURATION_SUCCESS';

export const HOTLINES_GET_HOTLINES_REQUEST = 'HOTLINES_GET_HOTLINES_REQUEST';
export const HOTLINES_GET_HOTLINES_SUCCESS = 'HOTLINES_GET_HOTLINES_SUCCESS';
export const HOTLINES_GET_DETAIL_HOTLINE_REQUEST = 'HOTLINES_GET_DETAIL_HOTLINE_REQUEST';
export const HOTLINES_GET_DETAIL_HOTLINE_SUCCESS = 'HOTLINES_GET_DETAIL_HOTLINE_SUCCESS';
export const HOTLINES_CREATE_HOTLINE_REQUEST = 'HOTLINES_CREATE_HOTLINE_REQUEST';
export const HOTLINES_CREATE_HOTLINE_SUCCESS = 'HOTLINES_CREATE_HOTLINE_SUCCESS';
export const HOTLINES_UPDATE_HOTLINE_REQUEST = 'HOTLINES_UPDATE_HOTLINE_REQUEST';
export const HOTLINES_UPDATE_HOTLINE_SUCCESS = 'HOTLINES_UPDATE_HOTLINE_SUCCESS';

export const SUB_COMPANIES_GET_LIST_REQUEST = 'SUB_COMPANIES_GET_LIST_REQUEST';
export const SUB_COMPANIES_GET_LIST_SUCCESS = 'SUB_COMPANIES_GET_LIST_SUCCESS';
export const SUB_COMPANIES_GET_LIST_CLEAR = 'SUB_COMPANIES_GET_LIST_CLEAR';
export const SUB_COMPANIES_HEADER_GET_LIST_REQUEST = 'SUB_COMPANIES_HEADER_GET_LIST_REQUEST';
export const SUB_COMPANIES_HEADER_GET_LIST_SUCCESS = 'SUB_COMPANIES_HEADER_GET_LIST_SUCCESS';
export const SUB_COMPANIES_CREATE_SUB_COMPANY_REQUEST = 'SUB_COMPANIES_CREATE_SUB_COMPANY_REQUEST';
export const SUB_COMPANIES_CREATE_COMPANY_MODAL_SUCCESS = 'SUB_COMPANIES_CREATE_COMPANY_MODAL_SUCCESS';
export const SUB_COMPANIES_DETAIL_SUB_COMPANY_REQUEST = 'SUB_COMPANIES_DETAIL_SUB_COMPANY_REQUEST';
export const SUB_COMPANIES_DETAIL_SUB_COMPANY_SUCCESS = 'SUB_COMPANIES_DETAIL_SUB_COMPANY_SUCCESS';
export const SUB_COMPANIES_UPDATE_SUB_COMPANY_REQUEST = 'SUB_COMPANIES_UPDATE_SUB_COMPANY_REQUEST';
export const SUB_COMPANIES_UPDATE_SUB_COMPANY_SUCCESS = 'SUB_COMPANIES_UPDATE_SUB_COMPANY_SUCCESS';
export const SUB_COMPANIES_SET_CURRENT_SUB_COMPANY = 'SUB_COMPANIES_SET_CURRENT_SUB_COMPANY';
export const SUB_COMPANIES_SET_SUB_COMPANY_OPTIONS = 'SUB_COMPANIES_SET_SUB_COMPANY_OPTIONS';
export const SUB_COMPANIES_DETAIL_STORE_PAYMENT_REQUEST = 'SUB_COMPANIES_DETAIL_STORE_PAYMENT_REQUEST';
export const SUB_COMPANIES_DETAIL_STORE_PAYMENT_SUCCESS = 'SUB_COMPANIES_DETAIL_STORE_PAYMENT_SUCCESS';
export const SUB_COMPANIES_PAYMENT_DETAILS_REQUEST = 'SUB_COMPANIES_PAYMENT_DETAILS_REQUEST';
export const SUB_COMPANIES_PAYMENT_DETAILS_SUCCESS = 'SUB_COMPANIES_PAYMENT_DETAILS_SUCCESS';

export const SUB_COMPANIES_UPDATE_INVOICE_VAT_RATE_REQUEST = 'SUB_COMPANIES_UPDATE_INVOICE_VAT_RATE_REQUEST';
export const SUB_COMPANIES_UPDATE_INVOICE_VAT_RATE_SUCCESS = 'SUB_COMPANIES_UPDATE_INVOICE_VAT_RATE_SUCCESS';
export const SUB_COMPANIES_UPDATE_INVOICE_VAT_RATE_ERROR = 'SUB_COMPANIES_UPDATE_INVOICE_VAT_RATE_ERROR';

export const SUB_COMPANIES_CREATE_INVOICE_VAT_RATE_REQUEST = 'SUB_COMPANIES_CREATE_INVOICE_VAT_RATE_REQUEST';
export const SUB_COMPANIES_CREATE_INVOICE_VAT_RATE_SUCCESS = 'SUB_COMPANIES_CREATE_INVOICE_VAT_RATE_SUCCESS';
export const SUB_COMPANIES_CREATE_INVOICE_VAT_RATE_ERROR = 'SUB_COMPANIES_CREATE_INVOICE_VAT_RATE_ERROR';

export const SUB_COMPANIES_GET_INVOICE_VAT_RATE_REQUEST = 'SUB_COMPANIES_GET_INVOICE_VAT_RATE_REQUEST';
export const SUB_COMPANIES_GET_INVOICE_VAT_RATE_SUCCESS = 'SUB_COMPANIES_GET_INVOICE_VAT_RATE_SUCCESS';
export const SUB_COMPANIES_GET_INVOICE_VAT_RATE_ERROR = 'SUB_COMPANIES_GET_INVOICE_VAT_RATE_ERROR';

export const SUB_COMPANIES_UPDATE_INVOICE_PARAMS_REQUEST = 'SUB_COMPANIES_UPDATE_INVOICE_PARAMS_REQUEST';
export const SUB_COMPANIES_UPDATE_INVOICE_PARAMS_SUCCESS = 'SUB_COMPANIES_UPDATE_INVOICE_PARAMS_SUCCESS';
export const SUB_COMPANIES_UPDATE_INVOICE_PARAMS_ERROR = 'SUB_COMPANIES_UPDATE_INVOICE_PARAMS_ERROR';

export const SUB_COMPANIES_CREATE_INVOICE_PARAMS_REQUEST = 'SUB_COMPANIES_CREATE_INVOICE_PARAMS_REQUEST';
export const SUB_COMPANIES_CREATE_INVOICE_PARAMS_SUCCESS = 'SUB_COMPANIES_CREATE_INVOICE_PARAMS_SUCCESS';
export const SUB_COMPANIES_CREATE_INVOICE_PARAMS_ERROR = 'SUB_COMPANIES_CREATE_INVOICE_PARAMS_ERROR';

export const SUB_COMPANIES_GET_INVOICE_PARAMS_REQUEST = 'SUB_COMPANIES_GET_INVOICE_PARAMS_REQUEST';
export const SUB_COMPANIES_GET_INVOICE_PARAMS_SUCCESS = 'SUB_COMPANIES_GET_INVOICE_PARAMS_SUCCESS';
export const SUB_COMPANIES_GET_INVOICE_PARAMS_ERROR = 'SUB_COMPANIES_GET_INVOICE_PARAMS_ERROR';

export const SUB_COMPANIES_PREVIEW_INVOICE_REQUEST = 'SUB_COMPANIES_PREVIEW_INVOICE_REQUEST';
export const SUB_COMPANIES_PREVIEW_INVOICE_SUCCESS = 'SUB_COMPANIES_PREVIEW_INVOICE_SUCCESS';
export const SUB_COMPANIES_PREVIEW_INVOICE_ERROR = 'SUB_COMPANIES_PREVIEW_INVOICE_ERROR';
export const SUB_COMPANIES_PREVIEW_INVOICE_CLEAR = 'SUB_COMPANIES_PREVIEW_INVOICE_CLEAR';

export const SUB_COMPANY_GET_EMAIL_SETUP_REQUEST = 'SUB_COMPANY_GET_EMAIL_SETUP_REQUEST';
export const SUB_COMPANY_GET_EMAIL_SETUP_SUCCESS = 'SUB_COMPANY_GET_EMAIL_SETUP_SUCCESS';
export const SUB_COMPANY_GET_EMAIL_SETUP_ERROR = 'SUB_COMPANY_GET_EMAIL_SETUP_ERROR';
export const SUB_COMPANY_UPDATE_EMAIL_SETUP_REQUEST = 'SUB_COMPANY_UPDATE_EMAIL_SETUP_REQUEST';
export const SUB_COMPANY_UPDATE_EMAIL_SETUP_SUCCESS = 'SUB_COMPANY_UPDATE_EMAIL_SETUP_SUCCESS';
export const SUB_COMPANY_UPDATE_EMAIL_SETUP_ERROR = 'SUB_COMPANY_UPDATE_EMAIL_SETUP_ERROR';
export const SUB_COMPANY_DELETE_EMAIL_SETUP_SUCCESS = 'SUB_COMPANY_DELETE_EMAIL_SETUP_SUCCESS';
export const SUB_COMPANY_DELETE_EMAIL_SETUP_ERROR = 'SUB_COMPANY_DELETE_EMAIL_SETUP_ERROR';
export const SUB_COMPANY_DELETE_EMAIL_SETUP_REQUEST = 'SUB_COMPANY_DELETE_EMAIL_SETUP_REQUEST';
export const SUB_COMPANY_PULL_EMAIL_SETUP_ERROR = 'SUB_COMPANY_PULL_EMAIL_SETUP_ERROR';
export const SUB_COMPANY_PULL_EMAIL_SETUP_REQUEST = 'SUB_COMPANY_PULL_EMAIL_SETUP_REQUEST';
export const SUB_COMPANY_GET_CONNECTED_ACCOUNT_REQUEST = 'SUB_COMPANY_GET_CONNECTED_ACCOUNT_REQUEST';
export const SUB_COMPANY_GET_CONNECTED_ACCOUNT_SUCCESS = 'SUB_COMPANY_GET_CONNECTED_ACCOUNT_SUCCESS';
export const SUB_COMPANY_GET_CONNECTED_ACCOUNT_ERROR = 'SUB_COMPANY_GET_CONNECTED_ACCOUNT_ERROR';
export const SUB_COMPANY_SET_CONNECTED_ACCOUNT_REQUEST = 'SUB_COMPANY_SET_CONNECTED_ACCOUNT_REQUEST';
export const SUB_COMPANY_SET_CONNECTED_ACCOUNT_SUCCESS = 'SUB_COMPANY_SET_CONNECTED_ACCOUNT_SUCCESS';
export const SUB_COMPANY_SET_CONNECTED_ACCOUNT_ERROR = 'SUB_COMPANY_SET_CONNECTED_ACCOUNT_ERROR';
export const SUB_COMPANY_REMOVE_CONNECTED_ACCOUNT_REQUEST = 'SUB_COMPANY_REMOVE_CONNECTED_ACCOUNT_REQUEST';
export const SUB_COMPANY_REMOVE_CONNECTED_ACCOUNT_SUCCESS = 'SUB_COMPANY_REMOVE_CONNECTED_ACCOUNT_SUCCESS';
export const SUB_COMPANY_REMOVE_CONNECTED_ACCOUNT_ERROR = 'SUB_COMPANY_REMOVE_CONNECTED_ACCOUNT_ERROR';

export const SUB_COMPANIES_SET_TAB_INDEX = 'SUB_COMPANIES_SET_TAB_INDEX';

export const SMARTCARDS_GET_LIST_REQUEST = 'SMARTCARDS_GET_LIST_REQUEST';
export const SMARTCARDS_GET_LIST_SUCCESS = 'SMARTCARDS_GET_LIST_SUCCESS';
export const SMARTCARDS_FILTERS_FORM_OPENED_STATE_TOGGLE = 'SMARTCARDS_FILTERS_FORM_OPENED_STATE_TOGGLE';
export const SMARTCARDS_EXPORT_REQUEST = 'SMARTCARDS_EXPORT_REQUEST';
export const SMARTCARDS_SET_CURRENT_SORTED_INDEX = 'SMARTCARDS_SET_CURRENT_SORTED_INDEX';
export const SMARTCARDS_SET_SORT_DIRECTION = 'SMARTCARDS_SET_SORT_DIRECTION';
export const SMARTCARDS_EMPTY_LIST = 'SMARTCARDS_EMPTY_LIST';
export const SMARTCARDS_EMPTY_DETAIL = 'SMARTCARDS_EMPTY_DETAIL';
export const SMARTCARD_USER_ASSIGN_SUCCESS = 'SMARTCARD_USER_ASSIGN_SUCCESS';
export const SMARTCARD_USER_ASSIGN_ERROR = 'SMARTCARD_USER_ASSIGN_ERROR';

export const smartcards = {
  GET_LIST_SUCCESS: SMARTCARDS_GET_LIST_SUCCESS,
  FILTERS_FORM_OPENED_STATE_TOGGLE: SMARTCARDS_FILTERS_FORM_OPENED_STATE_TOGGLE,
  SET_CURRENT_SORTED_INDEX: SMARTCARDS_SET_CURRENT_SORTED_INDEX,
  SET_SORT_DIRECTION: SMARTCARDS_SET_SORT_DIRECTION
};

export const SMARTCARDS_EVENTS_GET_LIST_REQUEST = 'SMARTCARDS_EVENTS_GET_LIST_REQUEST';
export const SMARTCARDS_EVENTS_GET_LIST_SUCCESS = 'SMARTCARDS_EVENTS_GET_LIST_SUCCESS';
export const SMARTCARDS_EVENTS_FILTERS_FORM_OPENED_STATE_TOGGLE = 'SMARTCARDS_EVENTS_FILTERS_FORM_OPENED_STATE_TOGGLE';
export const SMARTCARDS_EVENTS_SET_CURRENT_SORTED_INDEX = 'SMARTCARDS_EVENTS_SET_CURRENT_SORTED_INDEX';
export const SMARTCARDS_EVENTS_SET_SORT_DIRECTION = 'SMARTCARDS_EVENTS_SET_SORT_DIRECTION';
export const SMARTCARDS_EVENT_USER_ASSIGN_ERROR = 'SMARTCARDS_EVENT_USER_ASSIGN_ERROR';

export const smartcardsEvents = {
  GET_LIST_REQUEST: SMARTCARDS_EVENTS_GET_LIST_REQUEST,
  GET_LIST_SUCCESS: SMARTCARDS_EVENTS_GET_LIST_SUCCESS,
  FILTERS_FORM_OPENED_STATE_TOGGLE: SMARTCARDS_EVENTS_FILTERS_FORM_OPENED_STATE_TOGGLE,
  SET_CURRENT_SORTED_INDEX: SMARTCARDS_EVENTS_SET_CURRENT_SORTED_INDEX,
  SET_SORT_DIRECTION: SMARTCARDS_EVENTS_SET_SORT_DIRECTION
};

export const SMARTCARDS_GET_DETAIL_REQUEST = 'SMARTCARDS_GET_DETAIL_REQUEST';
export const SMARTCARDS_GET_DETAIL_SUCCESS = 'SMARTCARDS_GET_DETAIL_SUCCESS';
export const SMARTCARDS_REMOVE_USER_REQUEST = 'SMARTCARDS_REMOVE_USER_REQUEST';
export const SMARTCARDS_REMOVE_USER_SUCCESS = 'SMARTCARDS_REMOVE_USER_SUCCESS';
export const SMARTCARDS_CHANGE_STATUS_SUCCESS = 'SMARTCARDS_CHANGE_STATUS_SUCCESS';

export const INVOICES_GET_LIST_REQUEST = 'INVOICES_GET_LIST_REQUEST';
export const INVOICES_GET_LIST_SUCCESS = 'INVOICES_GET_LIST_SUCCESS';
export const INVOICES_GET_LIST_ERROR = 'INVOICES_GET_LIST_ERROR';
export const INVOICES_SAVE_INVOICE_DETAILS = 'INVOICES_SAVE_INVOICE_DETAILS';

export const INVOICES_FILTERS_FORM_OPENED_STATE_TOGGLE = 'INVOICES_FILTERS_FORM_OPENED_STATE_TOGGLE';
export const INVOICE_FIND_BOOKING_SET_CURRENT_SORTED_INDEX = 'INVOICE_FIND_BOOKING_SET_CURRENT_SORTED_INDEX';
export const INVOICE_FIND_BOOKING_SET_SORT_DIRECTION = 'INVOICE_FIND_BOOKING_SET_SORT_DIRECTION';
export const INVOICE_CREATE_CLEAR_CREATION_SESSION = 'INVOICE_CREATE_CLEAR_CREATION_SESSION';

export const INVOICE_ADD_SET_SELECTED_BOOKING = 'INVOICE_ADD_SET_SELECTED_BOOKING';
export const CREATE_INVOICE_TOGGLE_MEMBER_INFOS = 'CREATE_INVOICE_TOGGLE_MEMBER_INFOS';
export const INVOICE_ADD_ITEM = 'INVOICE_ADD_ITEM';
export const INVOICE_DELETE_ITEM = 'INVOICE_DELETE_ITEM';
export const INVOICE_TOGGLE_DEPOSIT_USAGE = 'INVOICE_TOGGLE_DEPOSIT_USAGE';
export const INVOICE_GET_VAT_RATE_FAILED = 'INVOICE_GET_VAT_RATE_FAILED';
export const INVOICE_PAYMENT_REQUEST = 'INVOICE_PAYMENT_REQUEST';
export const INVOICE_PAYMENT_SUCCESS = 'INVOICE_PAYMENT_SUCCESS';
export const INVOICE_PAYMENT_FAIL = 'INVOICE_PAYMENT_FAIL';
export const INVOICES_EXPORT_REQUEST = 'INVOICES_EXPORT_REQUEST';
export const INVOICES_EXPORT_FINISHED = 'INVOICES_EXPORT_FINISHED';
export const INVOICE_PDF_VIEW_MODAL = 'INVOICE_PDF_VIEW_OPEN_MODAL';
export const INVOICE_GET_ITALIAN_STATUS_SUCCESS = 'INVOICE_GET_ITALIAN_STATUS_SUCCESS';
export const INVOICE_CLEAR_ITALIAN_STATUS = 'INVOICE_CLEAR_ITALIAN_STATUS';
export const INVOICE_CLEAR_DMS_ERRORS = 'INVOICE_CLEAR_DMS_ERRORS';
export const INVOICE_GET_DMS_STATUS_SUCCESS = 'INVOICE_GET_DMS_STATUS_SUCCESS';
export const INVOICES_UPDATE_FILTERS = 'INVOICES_UPDATE_FILTERS';

export const INVOICES_SET_STATUS_V2_CREATE = 'INVOICES_SET_STATUS_V2_CREATE';

export const VEHICLE_STATUSES_SET_DATES = 'VEHICLE_STATUSES_SET_DATES';
export const VEHICLE_STATUSES_SET_START_DATE = 'VEHICLE_STATUSES_SET_START_DATE';
export const VEHICLE_STATUSES_SET_END_DATE = 'VEHICLE_STATUSES_SET_END_DATE';
export const VEHICLE_STATUSES_TOGGLE_REPORT_MODAL = 'VEHICLE_STATUSES_TOGGLE_REPORT_MODAL';

export const VEHICLE_STATUSES_GET_PIE_DATA_REQUEST = 'VEHICLE_STATUSES_GET_PIE_DATA_REQUEST';
export const VEHICLE_STATUSES_GET_PIE_DATA_SUCCESS = 'VEHICLE_STATUSES_GET_PIE_DATA_SUCCESS';
export const VEHICLE_STATUSES_GET_PIE_DATA_ERROR = 'VEHICLE_STATUSES_GET_PIE_DATA_ERROR';

export const VEHICLE_STATUSES_GET_EXPORT_REQUEST = 'VEHICLE_STATUSES_GET_EXPORT_REQUEST';
export const VEHICLE_STATUSES_GET_EXPORT_FINISHED = 'VEHICLE_STATUSES_GET_EXPORT_FINISHED';
export const VEHICLE_STATUSES_GET_EXPORT_ERROR = 'VEHICLE_STATUSES_GET_EXPORT_ERROR';

export const VEHICLE_STATUSES_GET_FLEET_REQUEST = 'VEHICLE_STATUSES_GET_FLEET_REQUEST';
export const VEHICLE_STATUSES_GET_FLEET_SUCCESS = 'VEHICLE_STATUSES_GET_FLEET_SUCCESS';
export const VEHICLE_STATUSES_GET_FLEET_ERROR = 'VEHICLE_STATUSES_GET_FLEET_ERROR';
export const VEHICLE_STATUSES_CLEAR_LIST_DATA = 'VEHICLE_STATUSES_CLEAR_LIST_DATA';

export const EXPORTS_GET_CONFIGURATIONS_SUCCESS = 'EXPORTS_GET_CONFIGURATIONS_SUCCESS';
export const EXPORTS_ADD_CONFIGURATION_SUCCESS = 'EXPORTS_ADD_CONFIGURATION_SUCCESS';
export const EXPORTS_DELETE_CONFIGURATIONS_SUCCESS = 'EXPORTS_DELETE_CONFIGURATIONS_SUCCESS';
export const EXPORTS_SET_CURRENT_CONFIGURATION_ID = 'EXPORTS_SET_CURRENT_CONFIGURATION_ID';
export const EXPORTS_ADD_LIST_FILTER = 'EXPORTS_ADD_LIST_FILTER';
export const EXPORTS_EDIT_LIST_FILTER = 'EXPORTS_EDIT_LIST_FILTER';
export const EXPORTS_RESET_LIST_FILTER = 'EXPORTS_RESET_LIST_FILTER';
