"use strict";

exports.__esModule = true;
exports.processRequestQueue = exports.queueRequest = void 0;

var _cloneSvg = _interopRequireDefault(require("./clone-svg"));

var _svgCache = _interopRequireDefault(require("./svg-cache"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var requestQueue = [];

var queueRequest = function queueRequest(url, callback) {
  requestQueue[url] = requestQueue[url] || [];
  requestQueue[url].push(callback);
};

exports.queueRequest = queueRequest;

var processRequestQueue = function processRequestQueue(url) {
  for (var i = 0, len = requestQueue[url].length; i < len; i++) {
    // Make these calls async so we avoid blocking the page/renderer
    ;

    (function (index) {
      setTimeout(function () {
        requestQueue[url][index]((0, _cloneSvg.default)(_svgCache.default[url]));
      }, 0);
    })(i);
  }
};

exports.processRequestQueue = processRequestQueue;