import version from '../../../generated-version.json';
import i18nRevManifest from '../../../generated-rev-manifest-i18n.json';
import { SITE_SMARTCARD_SPONTANEOUS_USAGE_BUSINESS } from './backend-constants';
import { getAppObj } from '../utils/utils';
import routes from './routes-constants';
import theme from './theme-constants';
import urlJoin from 'url-join';

import {
  DAY_OF_WEEK_MONDAY,
  DAY_OF_WEEK_TUESDAY,
  DAY_OF_WEEK_WEDNESDAY,
  DAY_OF_WEEK_THURSDAY,
  DAY_OF_WEEK_FRIDAY,
  DAY_OF_WEEK_SATURDAY,
  DAY_OF_WEEK_SUNDAY
} from './backend-constants';

const overridableDefaultConfig = {
  loginRoute: routes.login.path,
  defaultLocale: 'en'
};

const compressProps = {
  convertTypes: 'image/png',
  convertSize: 300000,
  quality: 0.9
};

const maxDimensions = size => ({ maxWidth: size, maxHeight: size });

const getAwsProps = config => ({
  baseUrl: 'https://rcimob-assets.s3-eu-west-1.amazonaws.com',
  brandMap: config.devMode ? 'brandMapDev.json' : 'brandMap.json',
  banner: config.devMode ? 'bannerDev.json' : 'banner.json',
  configFolder: 'back-office',
  brandsFolder: 'brands'
});

const setSiteIcon = ({ baseUrl, configFolder }) => {
  return urlJoin(baseUrl, configFolder, 'resources/favicon.ico');
};

function getConfig() {
  const nonOverridableDefaultConfig = {
    apiDefaultUrl: 'https://gwadmin-prod-glide.tech.rcimobility.com/api',
    maxDocumentSizeInMb: 2,
    fileUploadCompression: {
      default: {
        ...maxDimensions(2000),
        ...compressProps
      },
      vehicleImages: {
        ...maxDimensions(1000),
        ...compressProps
      }
    },
    enableCompanySelectionOnPages: [
      'dashboard',
      'brands',
      'vehicles-colors',
      'sub-companies',
      'configurations',
      'hotlines',
      'sites',
      'fleet',
      'fleet-usages',
      'feedbacks',
      'vehicle-damages',
      'members',
      'back-users',
      'smartcards',
      'smartcardsEvents',
      'bookings',
      'invoices',
      'sub-companies',
      'contract',
      'bankouts',
      'pricing'
    ],
    // hide driver license images if status is different from "to_review"
    // this is the list of (super) company IDs whith this feature enabled
    hideDriverImageForIds: {
      'ffbb84ab-45a1-415b-b668-b805e9afb3b4': true, // ENGIE (prod)
      '6929f8a2-e1ed-4cad-9682-9fb16d861f95': true, // RENAULT TRUCKS (prod)
      '11b57833-cf3e-47c6-90bb-a01c8fe39353': true, // 2 RCI Mobility (dev)
      '49c91410-e52c-447a-a4b7-9f705be75693': true, // asc (dev)
      '294a16db-c546-4762-848e-c7d3a101c6ff': true, // ascaasasc (dev)
      'e9d2fff3-ae61-4ebe-a13d-0d23cade33cb': true, // company6 (valid)
      '7ad1caa1-818f-4d31-a844-0446f42494b8': true, // 2 RCI Mobility (valid) + RCI Mobility (staging)
      'b02ab24e-2ff5-4b8a-899d-9a6543216e58': true // aaaaa (staging)
    },
    showEditMemberDriverLicenceInfoForIds: {
      'fb10f1b9-abb4-483a-a308-8afae9749d57': true, // ms france (prod)
      '58107ebe-dc4c-403f-a54e-25271fd43da9': true, // ms france (valid)
      'fa16bdda-438d-4c12-8fd0-a306014edb35': true, // ms france (dev)
      'f7d40bfe-8e89-4468-aad5-af2617375a66': true, // ms italy (valid)
      '48690976-d28b-445d-bffe-fb8d45b850ee': true, // ms spain (valid)
      '34e4c790-2f80-43f4-8d42-01107ee0e7ef': true, // ms beligum (valid)
      '7cf8d131-534b-4085-94b0-2ef77b92cdc3': true, // ms belgium (prod)
      'bf5fee16-55d1-433f-9207-609a19f8e666': true, // ms italy (prod)
      'e3ad0d68-75ae-4931-896c-cf11da5c17fe': true, // ms spain (prod)
      '6ad68464-9881-4578-ad61-f7e83f964762': true, // ms zity italy (prod)
      '9be81939-df6a-4e84-8673-a619cd088967': true // ms zity spain (prod)
    },
    // hide vehicle position on status request for ENEDIS
    hideGPSCoordForIds: {
      '5f42527a-f05c-4a0b-a58f-3c913cb3a468': true, // ENEDIS (prod)
      '294a16db-c546-4762-848e-c7d3a101c6ff': true, // ascaasasc (dev)
      'e9d2fff3-ae61-4ebe-a13d-0d23cade33cb': true, // company6 (valid)
      'b02ab24e-2ff5-4b8a-899d-9a6543216e58': true // aaaaa (staging)
    },
    phoneCountryPrefixes: [
      '+1',
      '+7',
      '+20',
      '+27',
      '+30',
      '+31',
      '+32',
      '+33',
      '+34',
      '+36',
      '+39',
      '+40',
      '+41',
      '+43',
      '+44',
      '+45',
      '+46',
      '+47',
      '+48',
      '+49',
      '+51',
      '+52',
      '+53',
      '+54',
      '+55',
      '+56',
      '+57',
      '+58',
      '+590',
      '+60',
      '+61',
      '+62',
      '+63',
      '+64',
      '+65',
      '+66',
      '+81',
      '+82',
      '+84',
      '+86',
      '+90',
      '+91',
      '+92',
      '+93',
      '+94',
      '+95',
      '+98',
      '+212',
      '+213',
      '+216',
      '+218',
      '+220',
      '+221',
      '+222',
      '+223',
      '+224',
      '+225',
      '+226',
      '+227',
      '+228',
      '+229',
      '+230',
      '+231',
      '+232',
      '+233',
      '+234',
      '+235',
      '+236',
      '+237',
      '+238',
      '+239',
      '+240',
      '+241',
      '+242',
      '+243',
      '+244',
      '+245',
      '+246',
      '+247',
      '+248',
      '+249',
      '+250',
      '+251',
      '+252',
      '+253',
      '+254',
      '+255',
      '+256',
      '+257',
      '+258',
      '+260',
      '+261',
      '+262',
      '+263',
      '+264',
      '+265',
      '+266',
      '+267',
      '+268',
      '+269',
      '+284',
      '+290',
      '+291',
      '+297',
      '+298',
      '+299',
      '+340',
      '+345',
      '+350',
      '+351',
      '+352',
      '+353',
      '+354',
      '+355',
      '+356',
      '+357',
      '+358',
      '+359',
      '+370',
      '+371',
      '+372',
      '+373',
      '+374',
      '+375',
      '+376',
      '+378',
      '+380',
      '+381',
      '+385',
      '+386',
      '+387',
      '+389',
      '+420',
      '+421',
      '+423',
      '+473',
      '+500',
      '+501',
      '+502',
      '+503',
      '+504',
      '+505',
      '+506',
      '+507',
      '+508',
      '+509',
      '+591',
      '+592',
      '+593',
      '+594',
      '+595',
      '+596',
      '+597',
      '+598',
      '+599',
      '+670',
      '+671',
      '+672',
      '+673',
      '+674',
      '+675',
      '+676',
      '+677',
      '+678',
      '+679',
      '+680',
      '+681',
      '+682',
      '+683',
      '+684',
      '+685',
      '+686',
      '+687',
      '+688',
      '+689',
      '+690',
      '+691',
      '+692',
      '+767',
      '+787',
      '+809',
      '+850',
      '+852',
      '+853',
      '+855',
      '+856',
      '+868',
      '+869',
      '+876',
      '+880',
      '+886',
      '+960',
      '+961',
      '+962',
      '+963',
      '+964',
      '+965',
      '+966',
      '+967',
      '+968',
      '+971',
      '+972',
      '+973',
      '+974',
      '+975',
      '+976',
      '+977',
      '+993',
      '+994',
      '+995',
      '+996'
    ].sort(),
    availableHours: [
      '00',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23'
    ],
    availableMinutes: ['00', '15', '30', '45'],
    allAvailableMinutes: [
      '00',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
      '27',
      '28',
      '29',
      '30',
      '31',
      '32',
      '33',
      '34',
      '35',
      '36',
      '37',
      '38',
      '39',
      '40',
      '41',
      '42',
      '43',
      '44',
      '45',
      '46',
      '47',
      '48',
      '49',
      '50',
      '51',
      '52',
      '53',
      '54',
      '55',
      '56',
      '57',
      '58',
      '59'
    ],
    daysOfWeek: [
      {
        labelKey: 'dayOfWeek_' + DAY_OF_WEEK_MONDAY,
        value: DAY_OF_WEEK_MONDAY
      },
      {
        labelKey: 'dayOfWeek_' + DAY_OF_WEEK_TUESDAY,
        value: DAY_OF_WEEK_TUESDAY
      },
      {
        labelKey: 'dayOfWeek_' + DAY_OF_WEEK_WEDNESDAY,
        value: DAY_OF_WEEK_WEDNESDAY
      },
      {
        labelKey: 'dayOfWeek_' + DAY_OF_WEEK_THURSDAY,
        value: DAY_OF_WEEK_THURSDAY
      },
      {
        labelKey: 'dayOfWeek_' + DAY_OF_WEEK_FRIDAY,
        value: DAY_OF_WEEK_FRIDAY
      },
      {
        labelKey: 'dayOfWeek_' + DAY_OF_WEEK_SATURDAY,
        value: DAY_OF_WEEK_SATURDAY
      },
      {
        labelKey: 'dayOfWeek_' + DAY_OF_WEEK_SUNDAY,
        value: DAY_OF_WEEK_SUNDAY
      }
    ],
    vehicleOwnerChoicesValues: {
      default: 'default',
      currentCompany: 'currentCompany'
    },
    numberResults: [15, 35, 50, 100],
    slotsTypes: {
      specialClosingDates: {
        startDate: null,
        endDate: null
      },
      specialOpeningHours: {
        startDate: null,
        endDate: null,
        items: []
      },
      timeSlot: {
        startHours: null,
        startMinutes: null,
        endHours: null,
        endMinutes: null
      },
      weeklyOpeningHours: {
        days: [false, false, false, false, false, false, false], //default values for days of the week slot
        items: []
      }
    },
    gMapsApiKey: 'AIzaSyCVyfs-uSw_HOF7kaTMOOGqP5IwaXJF9UI', // Has to be the same key as the one used on index.html
    //gMapsApiKey: 'AIzaSyAcP34WdKWGEVg3bOJr5v1k8bL5-8GG9Ss', // Develop Key
    gMapsTimeZoneApiUrl: 'https://maps.googleapis.com/maps/api/timezone/json',
    // define default parameters when creating a site
    defaultSiteBookingParameters: {
      timeUnitOfBooking: 15,
      minDurationOfBooking: 30,
      maxDurationOfBooking: 10080,
      reservationBuffer: 60,
      maxStartPlannedBookingInDays: 30,
      minStartPlannedBookingInMin: 0,
      maxStartImmediateBookingInMin: 0,
      periodBeforeCantNoMoreUpdateStartDate: 0,
      periodBeforeCantNoMoreUpdateEndDate: 0,
      preBookingStartDelay: 15,
      preBookingCancelDelay: 1440,
      preBookingConfirmDelayMin: 0,
      periodBeforeCantNoMoreCancel: 0,
      carPriorReservation: 15,
      automaticNoShow: 15, // Deprecated
      automaticNoShowOneWay: 120,
      automaticNoShowRoundTrip: 120,
      automaticExtension: 'false',
      automaticShortening: 'true',
      spontaneousBookingUsage: SITE_SMARTCARD_SPONTANEOUS_USAGE_BUSINESS,
      sendReportToDekra: 'false',
      chargeExpiredBooking: 'false',
      smartcardEnabled: 'false',
      smartcardFishingEnabled: 'false',
      spontaneousBookingEnabled: 'false',
      startBookingReportMandatory: 'false',
      endBookingReportMandatory: 'false',
      reportPhotos: 'false',
      photoStartBooking: 'false',
      photoEndBooking: 'false'
    },
    enableMenuItems: {
      organization: true,
      dashboard: true,
      vehiclesColors: true,
      brands: true,
      vehiclesCategories: true,
      contract: true,
      companies: true,
      subCompanies: true,
      support: true,
      configurations: false,
      hotlines: false,
      sites: true,
      memberTypes: true,
      pricing: true,
      fleet: true,
      feedbacks: true,
      vehicleDamages: true,
      members: true,
      backUsers: true,
      smartcards: true,
      smartcardsEvents: true,
      bookings: true,
      invoices: true,
      vouchers: true,
      vehiclePlanning: true,
      vehicleStatuses: true,
      scheduledExport: true,
      quickSight: true,
      bankouts: true
    },
    currencySymbol: {
      BRL: 'R$',
      CHF: 'CHF',
      DKK: 'Kr.',
      euro: '€',
      EUR: '€',
      GBP: '£',
      SEK: 'Kr',
      USD: '$'
    }
  };

  const browserLocale = (window.navigator.language || window.navigator.language).split('-')[0];

  let config = Object.assign(
    {},
    overridableDefaultConfig,
    getAppObj().settings,
    { browserLocale },
    { version },
    { i18nRevManifest },
    { theme },
    nonOverridableDefaultConfig
  );

  // set 'aws' after overrides
  config.aws = getAwsProps(config);
  config.siteIcon = setSiteIcon(config.aws);

  // web urls by application type
  config.webUrls = {
    GLIDE_WEB: config.glideWebBaseUrl,
    RRENT_WEB: config.rrentWebBaseUrl,
    RENAULT_WEB: config.renaultWebBaseUrl,
    NISSAN_WEB: config.nissanWebBaseUrl,
    SHARE_MOBILITY_WEB: config.shareMobilityWebBaseUrl,
    ENGIE_WEB: config.engieWebBaseUrl,
    EGO_WEB: config.egoWebBaseUrl,
    SNCF_WEB: config.sncfWebBaseUrl,
    AGILAUTO_WEB: config.agilautoWebBaseUrl
  };

  config.geoIpUrl = 'https://ip2c.org/self';

  config.mapboxEndpoint = 'https://api.mapbox.com/geocoding/v5';
  config.mapboxApiKey = 'pk.eyJ1Ijoib2xlZy1rdXoiLCJhIjoiY2wycmR2ejd2MDFwNDNqbWd0YzRuNGZiaiJ9.Pg-UmverhwsJyzQNTizNGg';
  config.mapboxUrl = 'https://www.mapbox.com/search-service';

  // expose config on window for debug purposes
  window._glideConfig = config;

  return config;
}

export default getConfig();
