import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import autoBind from 'react-autobind';

import Dialog from 'material-ui/Dialog';
import FormRow from '../../../components/FormRow/FormRow';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';

import { closeDriverLicenceDetailsModal, requestMemberDrivingLicenceUpdate } from '../../../actions/all-actions';
import BoxedInput from '../../../components/BoxedInput/BoxedInput';
import FieldErrorMsg from '../../../components/FieldErrorMsg/FieldErrorMsg';
import { createValidator, notEmpty } from '../../../validation/sync-validation';
import { scrollToFirstError } from '../../../utils/utils';

class DrivingLicenceDetailsDialog extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleSubmit() {
    const { dispatch, detailMember } = this.props;
    const params = {
      documentId: detailMember.drivingLicence.id
    };
    dispatch(requestMemberDrivingLicenceUpdate(params));
  }

  handleClose() {
    const { dispatch } = this.props;
    dispatch(closeDriverLicenceDetailsModal());
  }

  render() {
    const {
      fields: { licenceNumber, expirationDate, cityDeliverance, deliveranceDate },
      handleSubmit,
      intl
    } = this.props;

    let actions = [
      <FlatButton
        key="createMemberTypeForm_abortBtn"
        label={intl.messages['common_cancel']}
        formRowItemKey="createMemberTypeForm_abortBtn"
        primary
        onClick={this.handleClose}
      />,
      <RaisedButton
        primary
        key="createMemberTypeForm_submitBtn"
        label={intl.messages['common_approve']}
        formRowItemKey="createMemberTypeForm_submitBtn"
        onClick={handleSubmit(this.handleSubmit)}
      />
    ];

    return (
      <Dialog
        open
        title={intl.messages.edit_member_detail_driving_licence_title}
        actions={actions}
        actionsContainerClassName="dialog-actions"
      >
        <form action="#" className="assignMemberTypeForm" onSubmit={handleSubmit(this.handleSubmit)}>
          <FormRow customClass="changeTypeForm_row">
            <BoxedInput
              formRowItemKey="changeEmailForm_login"
              id="licenceNumber"
              labelKey="members_detail_driver_licence_number"
              // customClass="resetPasswordForm_boxedInput"
              field={licenceNumber}
              mandatory
            >
              <FieldErrorMsg field={licenceNumber} customClass="fieldErrorMsg--companyForm" />
            </BoxedInput>
            <BoxedInput
              formRowItemKey="editStatusForm_date"
              id="deliveranceDaye"
              skinType="date"
              type="date"
              customClass={'boxedInputWrapper--label vehiclePlanning_date_block'}
              labelKey="members_detail_driver_deliverance_date"
              field={deliveranceDate}
              mandatory
            >
              <FieldErrorMsg field={deliveranceDate} customClass="fieldErrorMsg--companyForm" />
            </BoxedInput>
          </FormRow>
          <FormRow customClass="changeTypeForm_row">
            <BoxedInput
              formRowItemKey="changeEmailForm_login"
              id="cityDeliverance"
              customClass="resetPasswordForm_boxedInput"
              labelKey="members_detail_driver_city_delivrance"
              field={cityDeliverance}
              mandatory
            >
              <FieldErrorMsg field={cityDeliverance} customClass="fieldErrorMsg--companyForm" />
            </BoxedInput>
            <BoxedInput
              formRowItemKey="editStatusForm_date"
              id="deliveranceDate"
              skinType="date"
              type="date"
              customClass={'boxedInputWrapper--label vehiclePlanning_date_block'}
              labelKey="members_detail_driver_expiration_date"
              field={expirationDate}
            />
          </FormRow>
        </form>
      </Dialog>
    );
  }
}

DrivingLicenceDetailsDialog.displayName = 'DrivingLicenceDetailsDialog';

DrivingLicenceDetailsDialog = reduxForm(
  {
    form: 'drivingLicenceEditApproveForm',
    onSubmitFail: scrollToFirstError
  },
  state => {
    return {
      initialValues: {
        licenceNumber: state.members.detailMember.drivingLicence.licenceNumber,
        expirationDate: state.members.detailMember.drivingLicence.expirationDate,
        cityDeliverance: state.members.detailMember.drivingLicence.cityDeliverance,
        deliveranceDate: state.members.detailMember.drivingLicence.deliveranceDate
      },
      fields: ['licenceNumber', 'expirationDate', 'cityDeliverance', 'deliveranceDate'],
      validate: createValidator({
        licenceNumber: [notEmpty()],
        deliveranceDate: [notEmpty()],
        cityDeliverance: [notEmpty()]
      })
    };
  }
)(DrivingLicenceDetailsDialog);

export default connect(state => {
  const {
    user: { userInfo },
    members: { detailMember }
  } = state;

  return {
    userInfo,
    detailMember
  };
})(injectIntl(DrivingLicenceDetailsDialog));
