// noinspection ES6CheckImport
import React, { Component, PropTypes } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import AccountCircleIcon from 'material-ui/svg-icons/action/account-circle';
import Modal from 'react-modal';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _isArray from 'lodash/isArray';
import _partial from 'lodash/partial';
import _isEmpty from 'lodash/isEmpty';
import autoBind from 'react-autobind';
import classnames from 'classnames';
import {
  BOOKING_STATUS_PRE_BOOKED,
  BOOKING_STATUS_SCHEDULED,
  MEMBER_TYPE_CATEGORY_PROFESSIONNAL,
  STATUS_TO_COMPLETE
} from '../../constants/backend-constants';

import MailOutlineIcon from 'material-ui/svg-icons/communication/mail-outline';
import BlockIcon from 'material-ui/svg-icons/content/block';
import LockIcon from 'material-ui/svg-icons/action/lock';
import CommentIcon from 'material-ui/svg-icons/communication/comment';
import PeopleIcon from 'material-ui/svg-icons/social/people';
import BuildIcon from 'material-ui/svg-icons/action/build';
import StarBorderIcon from 'material-ui/svg-icons/toggle/star-border';
import AnonymizeUser from 'material-ui/svg-icons/action/power-settings-new';
import DeanonymizeUser from 'material-ui/svg-icons/action/settings-backup-restore';
import DoneIcon from 'material-ui/svg-icons/action/done';
import BusinessIcon from 'material-ui/svg-icons/communication/business';
import UpdateChorusIcon from 'material-ui/svg-icons/file/cloud-queue';
import Restore from 'material-ui/svg-icons/action/restore';

import IconList from '../../components/IconList/IconList';

import MemberBasicInfo from '../../components/MemberBasicInfo/MemberBasicInfo';
import MemberMoreInfo from '../../components/MemberMoreInfo/MemberMoreInfo';
import MemberDriverInfo from '../../components/MemberDriverInfo/MemberDriverInfo';
import EmployerCertificateInfo from '../../components/EmployerCertificateInfo/EmployerCertificateInfo';
import MemberIdCardInfo from '../../components/MemberIdCardInfo/MemberIdCardInfo';
import MemberCompanyInfo from '../../components/MemberCompanyInfo/MemberCompanyInfo';

import MemberProfileInfo from '../../components/MemberProfileInfo/MemberProfileInfo';
import MemberTechnicianInfo from '../../components/MemberTechnicianInfo/MemberTechnicianInfo';
import MemberCommentsInfo from '../../components/MemberCommentsInfo/MemberCommentsInfo';
import AssignMemberTypeDialog from '../MemberTypes/components/AssignMemberTypeDialog';
import AnonymizeMemberDialog from './components/AnonymizeMemberDialog';

import ResetPasswordModalForm from '../../components/ResetPasswordModalForm/ResetPasswordModalForm';
import MemberMigrate from '../../components/MemberMigrate/MemberMigrate';
import MemberCommentForm from '../../components/MemberCommentForm/MemberCommentForm';
import ChangeEmailForm from '../../components/ChangeEmailForm/ChangeEmailForm';
import BackLink from '../../components/BackLink/BackLink';

import {
  addCompanyToTechnician,
  closeCommentModal,
  closeMemberEmailModal,
  closeResetPasswordMemberModal,
  confirmCommentMember,
  freeCompanyFromMemberUsed,
  changeMemberEmailRequest,
  requestResetPasswordMember,
  saveCompanyUsedRequest,
  requestChangeSuspensionMember,
  toggleStatusMember,
  openCommentModal,
  openResetPasswordMemberModal,
  getImpersonateMember,
  requestChangeTechnicianMember,
  openAssignMemberTypeModal,
  openAnonymizeMemberDialog,
  confirmApproveMember,
  requestEnableUser,
  getCustomFieldsFile,
  openMemberEmailModal,
  openMigrateMemberModal,
  updateChorusData,
  openDriverLicenceDetailsModal
} from '../../actions/all-actions';
import {
  localeSelector,
  memberCompanyContractMemberValuesSelector,
  memberCustomFieldsSelector,
  userRoleSelector
} from '../../selectors/all-selectors';
import { AddCommentModalStyles, DetailViewStyles, cssColors, modalStyles } from '../../constants/style-constants';
import {
  MEMBER_SUSPEND_MEMBER,
  MEMBER_ADD_COMMENT,
  MEMBER_INFORMATION,
  BACKUSER_ROLE_ADMIN,
  BACKUSER_ROLE_SUPER_ADMIN,
  BACKUSER_ROLE_FLEET_MANAGER,
  BACKUSER_ROLE_CALL_CENTER_OPERATOR,
  CUSTOM_FIELD_TYPE_FILE,
  MEMBER_DRIVING_LICENCE,
  MEMBER_ID_DOCUMENT,
  MEMBER_EMPLOYER_CERTIFICATE,
  BACKUSER_ROLE_ROOT
} from '../../constants/backend-constants';
import routes, { memberDetailEditRules } from '../../constants/routes-constants';
import { apiParams } from '../../constants/api-params-constants';
import { scrollToFirstError, safe } from '../../utils/utils';
import Tooltip from '../../components/Tooltip/Tooltip';
import { IconButton, RaisedButton } from 'material-ui';
import { checkRole } from '../../constants/backuser-role-rules';
import MemberAddressInfo from '../../components/MemberAddressInfo/MemberAddressInfo';
import Close from 'material-ui/svg-icons/navigation/close';
import { routeActions } from 'react-router-redux';
import memoizeOne from 'memoize-one';
import DrivingLicenceDetailsDialog from './components/DrivingLicenceDetailsDialog';

const filt = (args = []) => args.filter(it => it.status === BOOKING_STATUS_PRE_BOOKED || it.status === BOOKING_STATUS_SCHEDULED);
const upcomingBookings = memoizeOne(filt);

class MemberView extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.setReadOnly(props);
    this.updateMemberIconListItems(props);
  }

  setReadOnly(props) {
    const { role } = props;
    this.readOnly = !(
      role === BACKUSER_ROLE_SUPER_ADMIN ||
      role === BACKUSER_ROLE_ADMIN ||
      role === BACKUSER_ROLE_ROOT ||
      role === BACKUSER_ROLE_CALL_CENTER_OPERATOR ||
      checkRole(memberDetailEditRules, role)
    );
  }

  componentDidMount() {
    this.updateCustomFieldFiles(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.detailMember !== nextProps.detailMember) {
      this.updateMemberIconListItems(nextProps);
    }
  }

  componentWillUpdate(nextProps) {
    if (this.props.customFields !== nextProps.customFields) this.updateCustomFieldFiles(nextProps);
  }

  updateCustomFieldFiles(props) {
    const { dispatch, customFields } = props;

    _isArray(customFields) &&
      customFields.map(field => {
        if (_get(field, 'companyCustomField.fieldType') === CUSTOM_FIELD_TYPE_FILE && field.value) {
          dispatch(getCustomFieldsFile(field.value));
        }
      });
  }

  handleAbortCommentMember() {
    const { dispatch } = this.props;

    dispatch(closeCommentModal());
  }

  handleConfirmChangeEamilMember() {
    const { dispatch, detailMember } = this.props;

    dispatch(changeMemberEmailRequest(_get(detailMember, 'id')));
  }

  renderUsedCompaniesList() {
    const { companyUsedByMember } = this.props;
    let companiesList = [];

    if (_isArray(companyUsedByMember)) {
      companiesList = companyUsedByMember.map(item => {
        return {
          label: item.name,
          value: item.id
        };
      });
    }
    return companiesList;
  }

  renderFreeCompaniesList() {
    const { listOfCompanies, companyUsedByMember } = this.props;
    let usedCompaniesIds = [];
    let companiesList = [];

    if (_isArray(companyUsedByMember)) {
      companyUsedByMember.forEach(company => {
        usedCompaniesIds.push(company.id);
      });
    }
    if (_isArray(listOfCompanies)) {
      companiesList = listOfCompanies
        .filter(company => !_includes(usedCompaniesIds, company.id))
        .map(item => {
          return {
            label: item.name,
            value: item.id
          };
        });
    }
    return companiesList;
  }

  updateMemberIconListItems(props) {
    const { memberBookings } = props;

    this.memberIconListItems = [];
    let suspendProps = {};
    let techProps = {};
    let anonymousProps = {};

    if (this.readOnly) return;

    if (_get(props, 'detailMember.suspended')) {
      suspendProps.labelKey = 'members_detail_activate_confirm_button';
      suspendProps.id = 'unsuspend_button';
    } else {
      suspendProps.labelKey = 'members_detail_suspend_confirm_button';
      suspendProps.id = 'suspend_button';
    }

    if (_get(props, 'detailMember.technician')) {
      techProps.labelKey = 'members_detail_remove_from_technician';
      techProps.id = 'remove_tech_button';
    } else {
      techProps.labelKey = 'members_detail_tag_as_technician';
      techProps.id = 'tag_tech_button';
    }

    if (!_get(props, 'detailMember.anonymizationEffectiveDate')) {
      anonymousProps.labelKey = 'members_detail_anonymize_member';
      anonymousProps.id = 'anonymize_button';
      anonymousProps.icon = <AnonymizeUser />;
    } else {
      anonymousProps.labelKey = 'members_detail_deanonymize_member';
      anonymousProps.id = 'deanonymize_button';
      anonymousProps.icon = <DeanonymizeUser />;
    }

    if (upcomingBookings(memberBookings.results).length <= 0) {
      this.memberIconListItems.push({
        handleClick: this.handleAnonymizedMember,
        disallowed: [BACKUSER_ROLE_FLEET_MANAGER],
        ...anonymousProps
      });
    }

    if (!_get(props, 'detailMember.anonymizationEffectiveDate')) {
      if (!_get(props, 'detailMember.enabled')) {
        this.memberIconListItems.push({
          id: 'enable_button',
          labelKey: 'members_detail_enable_button',
          icon: <DoneIcon color={cssColors.listItem} />,
          handleClick: this.handleEnable,
          disallowed: [BACKUSER_ROLE_FLEET_MANAGER]
        });
      }
      if (safe(() => props.detailMember.enterprise.enterpriseUniqueId)) {
        this.memberIconListItems.push({
          id: 'update_chorus_button',
          labelKey: 'button.update.member.enterprise.info',
          icon: <UpdateChorusIcon color={cssColors.listItem} />,
          handleClick: this.handleChorusUpdate
        });
      }
      this.memberIconListItems.push({
        id: 'reset_password_button',
        labelKey: 'resetPassword_new_title',
        icon: <LockIcon color={cssColors.listItem} />,
        handleClick: this.handleResetPassword,
        disallowed: [BACKUSER_ROLE_FLEET_MANAGER]
      });
      this.memberIconListItems.push({
        id: 'migrate_button',
        labelKey: 'migrate_member_title',
        icon: <BusinessIcon color={cssColors.listItem} />,
        handleClick: this.handleOpenMigrateModal,
        allowed: [BACKUSER_ROLE_SUPER_ADMIN, BACKUSER_ROLE_ROOT]
      });
      this.memberIconListItems.push({
        id: 'change_email_button',
        labelKey: 'resetEmail_button_title',
        icon: <MailOutlineIcon color={cssColors.listItem} />,
        handleClick: this.handleOpenEmailModal,
        disallowed: [BACKUSER_ROLE_FLEET_MANAGER]
      });
      this.memberIconListItems.push({
        id: 'open_member_profile_history_button',
        labelKey: 'memberDetail_proflle_history',
        icon: <Restore color={cssColors.listItem} />,
        handleClick: this.goToHistory,
        disallowed: [BACKUSER_ROLE_FLEET_MANAGER]
      });
      this.memberIconListItems.push({
        id: 'impersonate_button',
        labelKey: 'members_detail_impersonate_button',
        icon: <PeopleIcon color={cssColors.listItem} />,
        handleClick: this.handleImpersonate,
        disallowed: [BACKUSER_ROLE_FLEET_MANAGER]
      });
      this.memberIconListItems.push({
        icon: <BuildIcon color={cssColors.listItem} />,
        handleClick: this.handleChangeTechnician,
        disallowed: [BACKUSER_ROLE_FLEET_MANAGER],
        ...techProps
      });
      if (props.memberRules.type) {
        if (_get(props, 'detailMember.memberType.category') !== MEMBER_TYPE_CATEGORY_PROFESSIONNAL) {
          this.memberIconListItems.push({
            icon: <StarBorderIcon color={cssColors.listItem} />,
            handleClick: this.handleAssignMemberType,
            labelKey: 'members_detail_assign_member_type',
            disallowed: [BACKUSER_ROLE_FLEET_MANAGER]
          });
        }
      }
      this.memberIconListItems.push({
        id: 'comment_button',
        labelKey: 'members_detail_add_comment_button',
        icon: <CommentIcon color={cssColors.listItem} />,
        handleClick: _partial(this.handleOpenCommentModal, MEMBER_ADD_COMMENT)
      });
      this.memberIconListItems.push({
        icon: <BlockIcon color={cssColors.listItem} />,
        handleClick: this.handleSuspend,
        ...suspendProps
      });
    }
  }

  handleAssignUseRoute() {
    const { dispatch, listOfCompanies } = this.props;
    let companiesIds = _get(this, 'props.fields.freeCompanies.value');
    let companies = [];

    if (_isArray(listOfCompanies) && _isArray(companiesIds)) {
      listOfCompanies.forEach(company => {
        if (companiesIds.indexOf(_get(company, 'id')) !== -1) {
          companies.push(company);
        }
      });

      dispatch(addCompanyToTechnician(companies));
    }
  }

  handleAssignFreeRoute() {
    const { dispatch, listOfCompanies } = this.props;
    let companiesIds = this.props.fields.usedCompanies.value;
    let companies = [];

    if (_isArray(listOfCompanies) && _isArray(companiesIds)) {
      listOfCompanies.forEach(company => {
        if (companiesIds.indexOf(_get(company, 'id')) !== -1) {
          companies.push(company);
        }
      });

      if (companies.length > 0) {
        dispatch(freeCompanyFromMemberUsed(companies));
      }
    }
  }

  handleOpenEmailModal() {
    const { dispatch } = this.props;

    dispatch(openMemberEmailModal());
  }

  handleConfirmCommentMember(formData) {
    const { dispatch, commentModalMemberType, detailMember } = this.props;

    dispatch(closeCommentModal());
    dispatch(confirmCommentMember(detailMember, commentModalMemberType, _get(formData, 'comment', '')));
  }

  handleAssignSaveRoute() {
    const { dispatch, detailMember } = this.props;

    dispatch(saveCompanyUsedRequest(_get(detailMember, 'id')));
  }

  handleAbortResetPasswordMember() {
    const { dispatch } = this.props;

    dispatch(closeResetPasswordMemberModal());
  }

  goToHistory() {
    const { detailMember } = this.props;
    this.props.dispatch(routeActions.push(`${routes.memberProfileHistory.path.replace(':memberId', detailMember.id)}`));
  }

  handleEnable() {
    const { dispatch, detailMember } = this.props;
    const { company, login } = detailMember || {};
    const brandId = safe(() => company.brand.id);

    dispatch(requestEnableUser({ login, brandId }));
  }

  handleApproveInfo(type) {
    const { dispatch, detailMember } = this.props;

    dispatch(confirmApproveMember(detailMember, type));
  }

  handleResetPassword() {
    this.props.dispatch(openResetPasswordMemberModal());
  }

  handleConfirmResetPasswordMember() {
    const { dispatch, detailMember } = this.props;

    _get(detailMember, 'id') && dispatch(requestResetPasswordMember(detailMember.id));
  }

  handleOpenMigrateModal() {
    this.props.dispatch(openMigrateMemberModal());
  }

  handleAbortEmailMember() {
    const { dispatch } = this.props;

    dispatch(closeMemberEmailModal());
  }

  handleChangeTechnician() {
    const { dispatch, detailMember } = this.props;

    dispatch(requestChangeTechnicianMember(detailMember));
  }

  handleAssignMemberType() {
    const { dispatch, detailMember } = this.props;
    dispatch(openAssignMemberTypeModal(detailMember));
  }

  handleDrivingLicenceDetails() {
    const { dispatch, detailMember } = this.props;
    dispatch(openDriverLicenceDetailsModal(detailMember));
  }

  handleAnonymizedMember() {
    const { dispatch, detailMember } = this.props;
    dispatch(openAnonymizeMemberDialog(detailMember));
  }

  handleImpersonate() {
    const { dispatch, detailMember } = this.props;

    dispatch(getImpersonateMember(detailMember.id, detailMember.company.id));
  }

  handleConfirmChangeStatusMember() {
    const { dispatch, detailMember } = this.props;

    dispatch(toggleStatusMember());
    dispatch(requestChangeSuspensionMember(detailMember));
  }

  handleOpenCommentModal(type) {
    this.props.dispatch(openCommentModal(type));
  }

  handleChorusUpdate() {
    this.props.dispatch(updateChorusData(safe(() => this.props.detailMember.enterprise.enterpriseUniqueId)));
  }

  handleSuspend() {
    if (_get(this.props, 'detailMember.suspended')) this.handleConfirmChangeStatusMember();
    else this.handleOpenCommentModal(MEMBER_SUSPEND_MEMBER);
  }

  closeButton() {
    const { toggle } = this.props;
    if (toggle) {
      return (
        <IconButton onClick={toggle}>
          <Close color={'rgba(0, 0, 0, .54)'} hoverColor={'rgba(0, 0, 0, .84)'} />
        </IconButton>
      );
    }
  }

  render() {
    const {
      fields: { freeCompanies, usedCompanies },
      detailMember,
      memberRules,
      customFields,
      customFieldsFiles,
      locale,
      drivingLicenseFiles,
      employerCertificateFiles,
      identityDocumentFiles,
      memberResetPasswordFormIsOpen,
      memberMigrateFormIsOpen,
      openedCommentModalMember,
      openAssignMemberType,
      openDriverLicenceDetails,
      openAnonymizeMember,
      listOfMemberTypes,
      commentModalMemberType,
      openedEmailModalMember,
      comments,
      urlParams,
      customFilesErrors
    } = this.props;

    const showActions = detailMember.profileReviewStatus !== STATUS_TO_COMPLETE;
    const unknown = <FormattedMessage id="common_unknown" />;
    const backToListURL = '/#' + routes.members.path.replace(':search', encodeURIComponent(JSON.stringify(urlParams || apiParams.default)));

    return (
      <div className="mainContainer_content">
        <div className="pageContainer">
          {!this.props.toggle && <BackLink link={backToListURL} labelKey="back_link_members_list" />}
          <div className={classnames('memberView detailView', { tile: this.props.toggle })}>
            <div className="detailView_header">
              <div className="detailView_header_left">
                <Tooltip content={<FormattedMessage id="user_type_member" />} placement="bottom">
                  <AccountCircleIcon style={DetailViewStyles.icons.header} />
                </Tooltip>
                <h3 className="detailView_header_text">
                  <Tooltip content={<FormattedMessage id="members_detail_first_name" />} placement="bottom">
                    {_get(detailMember, 'firstName', unknown)}
                  </Tooltip>
                  <span> </span>
                  <Tooltip content={<FormattedMessage id="members_detail_last_name" />} placement="bottom">
                    {_get(detailMember, 'lastName', unknown)}
                  </Tooltip>
                  {safe(() => detailMember.status) && (
                    <span className={`detailView_header_status member_status--${detailMember.status.toLowerCase()}`}>
                      <Tooltip content={<FormattedMessage id="members_detail_validationStatus" />} placement="bottom">
                        <FormattedMessage id={`members_status_${detailMember.status.toLowerCase()}`} />
                      </Tooltip>
                    </span>
                  )}
                </h3>
              </div>
              <div className="detailView_header_right">
                {!this.readOnly && _get(detailMember, 'anonymizationEffectiveDate') && (
                  <RaisedButton
                    onClick={this.handleAnonymizedMember}
                    icon={<DeanonymizeUser />}
                    label={<FormattedMessage id="members_detail_deanonymize_member" />}
                  />
                )}
                {!_get(detailMember, 'anonymizationEffectiveDate') && (
                  <IconList items={this.memberIconListItems} listName="memberView_actionList" />
                )}
                {this.closeButton()}
              </div>
            </div>
            <div className="detailView_body">
              {!_isEmpty(detailMember) && (
                <MemberBasicInfo
                  detailMember={detailMember}
                  showName={false}
                  enabledActions={showActions}
                  handleApproveInfo={_partial(this.handleApproveInfo, MEMBER_INFORMATION)}
                  handleRejectInfo={_partial(this.handleOpenCommentModal, MEMBER_INFORMATION)}
                />
              )}
              {!_isEmpty(detailMember) && (
                <MemberMoreInfo
                  memberRules={memberRules}
                  detailMember={detailMember}
                  customFields={customFields}
                  customFieldsFiles={customFieldsFiles}
                  customFilesErrors={customFilesErrors}
                  locale={locale}
                />
              )}
              {_get(detailMember, 'technician') && (
                <MemberTechnicianInfo
                  readOnly={this.readOnly}
                  freeCompanies={freeCompanies}
                  usedCompanies={usedCompanies}
                  anonymizationEffectiveDate={safe(() => detailMember.anonymizationEffectiveDate)}
                  renderUsedCompaniesList={this.renderUsedCompaniesList}
                  renderFreeCompaniesList={this.renderFreeCompaniesList}
                  onHandleAssignUseRoute={this.handleAssignUseRoute}
                  onHandleAssignSaveRoute={this.handleAssignSaveRoute}
                  onHandleAssignFreeRoute={this.handleAssignFreeRoute}
                />
              )}
              {detailMember.drivingLicence && (
                <MemberDriverInfo
                  detailMember={detailMember}
                  enabledActions={_get(detailMember, 'drivingLicence.status') !== STATUS_TO_COMPLETE}
                  drivingLicenseFiles={drivingLicenseFiles}
                  handleApproveInfo={_partial(this.handleApproveInfo, MEMBER_DRIVING_LICENCE)}
                  handleRejectInfo={_partial(this.handleOpenCommentModal, MEMBER_DRIVING_LICENCE)}
                  handleEditInfo={_partial(this.handleDrivingLicenceDetails, MEMBER_DRIVING_LICENCE)}
                />
              )}
              {_get(detailMember, 'identityDocument') && (
                <MemberIdCardInfo
                  enabledActions={_get(detailMember, 'identityDocument.status') !== STATUS_TO_COMPLETE}
                  detailMember={detailMember}
                  identityDocumentFiles={identityDocumentFiles}
                  handleApproveInfo={_partial(this.handleApproveInfo, MEMBER_ID_DOCUMENT)}
                  handleRejectInfo={_partial(this.handleOpenCommentModal, MEMBER_ID_DOCUMENT)}
                />
              )}
              {_get(detailMember, 'employerCertificate') && (
                <EmployerCertificateInfo
                  detailMember={detailMember}
                  enabledActions={_get(detailMember, 'employerCertificate.status') !== STATUS_TO_COMPLETE}
                  employerCertificateFiles={employerCertificateFiles}
                  handleApproveInfo={_partial(this.handleApproveInfo, MEMBER_EMPLOYER_CERTIFICATE)}
                  handleRejectInfo={_partial(this.handleOpenCommentModal, MEMBER_EMPLOYER_CERTIFICATE)}
                />
              )}

              {safe(() => detailMember.enterprise.enterpriseUniqueId) && <MemberCompanyInfo enterprise={detailMember.enterprise} />}

              <MemberProfileInfo detailMember={detailMember} showStatus={false} />

              {_get(comments, 'length', 0) > 0 && <MemberCommentsInfo comments={comments} />}
              {_get(detailMember, 'address') && <MemberAddressInfo address={detailMember.address} />}
            </div>
            <div>
              {memberResetPasswordFormIsOpen && (
                <Modal isOpen={memberResetPasswordFormIsOpen} style={modalStyles} className="resetPasswordModal">
                  <p className="resetPasswordModal_title">
                    <FormattedMessage id="resetPassword_new_title" />
                  </p>
                  <ResetPasswordModalForm
                    formName="memberView_resetForm"
                    onCallback={this.handleConfirmResetPasswordMember}
                    onAbort={this.handleAbortResetPasswordMember}
                  />
                </Modal>
              )}
              {memberMigrateFormIsOpen && (
                <Modal isOpen={memberMigrateFormIsOpen} style={modalStyles} className="resetPasswordModal">
                  <p className="resetPasswordModal_title">
                    <FormattedMessage id="migrate_member_title" />
                  </p>
                  <MemberMigrate formName="memberView_migrateForm" />
                </Modal>
              )}
              {openedCommentModalMember && (
                <Modal isOpen={openedCommentModalMember} style={AddCommentModalStyles}>
                  <MemberCommentForm
                    formName="memberView_commentForm"
                    onCallback={this.handleConfirmCommentMember}
                    onAbort={this.handleAbortCommentMember}
                    type={commentModalMemberType}
                  />
                </Modal>
              )}
              {openedEmailModalMember && (
                <Modal isOpen={openedEmailModalMember} style={AddCommentModalStyles}>
                  <ChangeEmailForm onCallback={this.handleConfirmChangeEamilMember} onAbort={this.handleAbortEmailMember} />
                </Modal>
              )}
              {openAssignMemberType && <AssignMemberTypeDialog list={listOfMemberTypes} />}
              {openDriverLicenceDetails && <DrivingLicenceDetailsDialog />}
              {openAnonymizeMember && <AnonymizeMemberDialog />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MemberView.propTypes = {
  fields: PropTypes.object,
  commentModalMemberType: PropTypes.any,
  comments: PropTypes.array,
  companyUsedByMember: PropTypes.array,
  contractFileUrl: PropTypes.any,
  customFields: PropTypes.array,
  detailMember: PropTypes.object,
  drivingLicenseFiles: PropTypes.array,
  employerCertificateFiles: PropTypes.array,
  identityDocumentFiles: PropTypes.array,
  listOfCompanies: PropTypes.array,
  memberBookings: PropTypes.array,
  locale: PropTypes.string,
  memberResetPasswordFormIsOpen: PropTypes.bool,
  memberMigrateFormIsOpen: PropTypes.bool,
  openAssignMemberType: PropTypes.bool,
  openAnonymizeMember: PropTypes.bool,
  openDriverLcenceDetails: PropTypes.bool,
  openedCommentModalMember: PropTypes.any,
  openedEmailModalMember: PropTypes.any,
  urlParams: PropTypes.object,
  dispatch: PropTypes.func,
  customFieldsFiles: PropTypes.object
};

MemberView.displayName = 'MemberView';

MemberView = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'MemberView',
  fields: ['freeCompanies', 'usedCompanies']
})(MemberView);

export default connect(state => {
  const {
    members: {
      detailMember,
      drivingLicenseFiles,
      identityDocumentFiles,
      employerCertificateFiles,
      contractFileUrl,
      memberMigrateFormIsOpen,
      memberResetPasswordFormIsOpen,
      openedCommentModalMember,
      openAssignMemberType,
      openDriverLicenceDetails,
      openAnonymizeMember,
      commentModalMemberType,
      openedEmailModalMember,
      urlParams,
      comments,
      companyUsedByMember,
      customFieldsFiles,
      customFilesErrors
    },
    companies: { headerList: list },
    bookings: { paginatedResults: results }
  } = state;

  const listOfMemberTypes = state.memberTypes.list;

  return {
    detailMember,
    customFilesErrors,
    drivingLicenseFiles,
    identityDocumentFiles,
    employerCertificateFiles,
    contractFileUrl,
    memberMigrateFormIsOpen,
    memberResetPasswordFormIsOpen,
    openedEmailModalMember,
    openedCommentModalMember,
    commentModalMemberType,
    openAnonymizeMember,
    openAssignMemberType,
    openDriverLicenceDetails,
    urlParams,
    comments,
    companyUsedByMember,
    listOfCompanies: list,
    listOfMemberTypes,
    customFields: memberCustomFieldsSelector(state),
    memberRules: memberCompanyContractMemberValuesSelector(state),
    customFieldsFiles,
    locale: localeSelector(state),
    role: userRoleSelector(state),
    memberBookings: results
  };
})(MemberView);
