'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _pure = require('recompose/pure');

var _pure2 = _interopRequireDefault(_pure);

var _SvgIcon = require('../../SvgIcon');

var _SvgIcon2 = _interopRequireDefault(_SvgIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ImagePhotoSizeSelectActual = function ImagePhotoSizeSelectActual(props) {
  return _react2.default.createElement(
    _SvgIcon2.default,
    props,
    _react2.default.createElement('path', { d: 'M21 3H3C2 3 1 4 1 5v14c0 1.1.9 2 2 2h18c1 0 2-1 2-2V5c0-1-1-2-2-2zM5 17l3.5-4.5 2.5 3.01L14.5 11l4.5 6H5z' })
  );
};
ImagePhotoSizeSelectActual = (0, _pure2.default)(ImagePhotoSizeSelectActual);
ImagePhotoSizeSelectActual.displayName = 'ImagePhotoSizeSelectActual';
ImagePhotoSizeSelectActual.muiName = 'SvgIcon';

exports.default = ImagePhotoSizeSelectActual;