'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _pure = require('recompose/pure');

var _pure2 = _interopRequireDefault(_pure);

var _SvgIcon = require('../../SvgIcon');

var _SvgIcon2 = _interopRequireDefault(_SvgIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CommunicationStayCurrentLandscape = function CommunicationStayCurrentLandscape(props) {
  return _react2.default.createElement(
    _SvgIcon2.default,
    props,
    _react2.default.createElement('path', { d: 'M1.01 7L1 17c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2H3c-1.1 0-1.99.9-1.99 2zM19 7v10H5V7h14z' })
  );
};
CommunicationStayCurrentLandscape = (0, _pure2.default)(CommunicationStayCurrentLandscape);
CommunicationStayCurrentLandscape.displayName = 'CommunicationStayCurrentLandscape';
CommunicationStayCurrentLandscape.muiName = 'SvgIcon';

exports.default = CommunicationStayCurrentLandscape;