"use strict";

exports.__esModule = true;
exports.default = void 0;

var cloneSvg = function cloneSvg(sourceSvg) {
  return sourceSvg.cloneNode(true);
};

var _default = cloneSvg;
exports.default = _default;