'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _pure = require('recompose/pure');

var _pure2 = _interopRequireDefault(_pure);

var _SvgIcon = require('../../SvgIcon');

var _SvgIcon2 = _interopRequireDefault(_SvgIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var EditorFormatIndentDecrease = function EditorFormatIndentDecrease(props) {
  return _react2.default.createElement(
    _SvgIcon2.default,
    props,
    _react2.default.createElement('path', { d: 'M11 17h10v-2H11v2zm-8-5l4 4V8l-4 4zm0 9h18v-2H3v2zM3 3v2h18V3H3zm8 6h10V7H11v2zm0 4h10v-2H11v2z' })
  );
};
EditorFormatIndentDecrease = (0, _pure2.default)(EditorFormatIndentDecrease);
EditorFormatIndentDecrease.displayName = 'EditorFormatIndentDecrease';
EditorFormatIndentDecrease.muiName = 'SvgIcon';

exports.default = EditorFormatIndentDecrease;