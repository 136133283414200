'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _pure = require('recompose/pure');

var _pure2 = _interopRequireDefault(_pure);

var _SvgIcon = require('../../SvgIcon');

var _SvgIcon2 = _interopRequireDefault(_SvgIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MapsLocalConvenienceStore = function MapsLocalConvenienceStore(props) {
  return _react2.default.createElement(
    _SvgIcon2.default,
    props,
    _react2.default.createElement('path', { d: 'M19 7V4H5v3H2v13h8v-4h4v4h8V7h-3zm-8 3H9v1h2v1H8V9h2V8H8V7h3v3zm5 2h-1v-2h-2V7h1v2h1V7h1v5z' })
  );
};
MapsLocalConvenienceStore = (0, _pure2.default)(MapsLocalConvenienceStore);
MapsLocalConvenienceStore.displayName = 'MapsLocalConvenienceStore';
MapsLocalConvenienceStore.muiName = 'SvgIcon';

exports.default = MapsLocalConvenienceStore;