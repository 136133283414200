'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _pure = require('recompose/pure');

var _pure2 = _interopRequireDefault(_pure);

var _SvgIcon = require('../../SvgIcon');

var _SvgIcon2 = _interopRequireDefault(_SvgIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var NotificationAirlineSeatLegroomExtra = function NotificationAirlineSeatLegroomExtra(props) {
  return _react2.default.createElement(
    _SvgIcon2.default,
    props,
    _react2.default.createElement('path', { d: 'M4 12V3H2v9c0 2.76 2.24 5 5 5h6v-2H7c-1.66 0-3-1.34-3-3zm18.83 5.24c-.38-.72-1.29-.97-2.03-.63l-1.09.5-3.41-6.98c-.34-.68-1.03-1.12-1.79-1.12L11 9V3H5v8c0 1.66 1.34 3 3 3h7l3.41 7 3.72-1.7c.77-.36 1.1-1.3.7-2.06z' })
  );
};
NotificationAirlineSeatLegroomExtra = (0, _pure2.default)(NotificationAirlineSeatLegroomExtra);
NotificationAirlineSeatLegroomExtra.displayName = 'NotificationAirlineSeatLegroomExtra';
NotificationAirlineSeatLegroomExtra.muiName = 'SvgIcon';

exports.default = NotificationAirlineSeatLegroomExtra;