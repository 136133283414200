import * as types from '../constants/actionTypes-constants';
import callApi from './api-actions';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _forEach from 'lodash/forEach';
import _differenceWith from 'lodash/differenceWith';

import {
  selectCompanyId,
  append,
  trySet,
  trimValues,
  addErrorMessage,
  setApiCompanyIds,
  urlRemoveDoubleSlash,
  selectSubCompanyId,
  openUrlBackgroundTab
} from '../utils/utils';
import { refreshPage } from '../routing/all-routing';
import { addFlashMessage, pollingMembersExport } from '../actions/all-actions';
import { SORT_DESC, SORT_ASC, FLASH_MESSAGE_TYPE_ERROR, FLASH_MESSAGE_TYPE_SUCCESS } from '../constants/generic-constants';
import {
  MEMBER_ADD_COMMENT,
  MEMBER_SUSPEND_MEMBER,
  MEMBER_INFORMATION,
  MEMBER_ID_DOCUMENT,
  MEMBER_DRIVING_LICENCE,
  MEMBER_EMPLOYER_CERTIFICATE
} from '../constants/backend-constants';
import config from '../constants/config-constants';
import { bundleSelector, localeSelector, validCompanyIdSelector } from '../selectors/all-selectors';
import { partialErrorCodes } from '../constants/options-constants';
import _union from 'lodash/union';
import _isEqual from 'lodash/isEqual';
import _includes from 'lodash/includes';
import { cleanMemberDataForApi } from '../utils/dataFormatUtils';
import { enhanceAddMemberhRequestParams } from '../api/data-enhancer';
import { addSuccessMsg } from '../utils/flashMessage/creator';
import { getMsg } from '../utils/IntlGlobalProvider';
import { MEMBER_UPDATE_CHORUS_DATA_SUCCESS } from '../constants/actionTypes-constants';

export function getMembersList(params) {
  return (dispatch, getState) => {
    const state = getState();
    let paramsObj;

    if (params) {
      let pageNumber = params.page ? params.page.number : state.members.paginatedResults.metadata.paginationInfo.currentPage;
      let pageSize = params.page ? params.page.size : state.members.paginatedResults.metadata.paginationInfo.pageSize;

      paramsObj = {
        page: {
          number: pageNumber,
          size: pageSize
        }
      };

      let sortProperty = params.sort ? params.sort.property : state.members.currentSortedIndex;
      let sortIsDescending = params.sort ? params.sort.isDescending : state.members.sortIsDescending;

      let sort = {
        property: sortProperty,
        isDescending: sortIsDescending
      };

      if (sortProperty) {
        paramsObj = Object.assign(paramsObj, { sort });
      }

      if (params.hasOwnProperty('subscriptionOrigins') && typeof params.subscriptionOrigins !== 'object') {
        params.subscriptionOrigins = [params.subscriptionOrigins];
      }

      paramsObj = Object.assign(
        {},
        paramsObj,
        _pick(params, ['memberName', 'email', 'status', 'technician', 'expediteReview', 'subscriptionOrigins'])
      );

      setApiCompanyIds(paramsObj, state);
    } else {
      paramsObj = null;
    }

    dispatch({
      type: types.MEMBERS_GET_LIST_REQUEST
    });

    return dispatch(callApi('members', 'getList', paramsObj, false)).then(function(data) {
      dispatch({
        type: types.MEMBERS_GET_LIST_SUCCESS,
        paginatedObj: data,
        urlParams: paramsObj
      });
      return data;
    });
  };
}

export function getMemberDetail(id) {
  return dispatch => {
    dispatch({ type: types.MEMBERS_GET_DETAIL_MEMBER_REQUEST });

    return dispatch(callApi('members', 'getMemberCompleteDetail', id)).then(
      data => {
        dispatch({ type: types.MEMBERS_GET_DETAIL_MEMBER_SUCCESS, member: data });
        return Promise.resolve(data);
      },
      error => Promise.reject(error)
    );
  };
}

export function getMemberProfileHistory(id) {
  return dispatch => {
    dispatch({ type: types.MEMBERS_GET_PROFILE_HISTORY_MEMBER_REQUEST });

    return dispatch(callApi('members', 'getMemberProfileHistory', id, false)).then(
      data => {
        dispatch({ type: types.MEMBERS_GET_PROFILE_HISTORY_MEMBER_SUCCESS, memberProfileHistory: data });
        return Promise.resolve(data);
      },
      error => {
        dispatch({ type: types.MEMBERS_GET_PROFILE_HISTORY_MEMBER_ERROR });
        dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      }
    );
  };
}

export function clearMemberProfileHistory() {
  return dispatch => {
    dispatch({ type: types.MEMBERS_GET_PROFILE_HISTORY_MEMBER_CLEAR });
  };
}

export function updateBasicMember(id, data) {
  return dispatch => {
    return dispatch(callApi('members', 'updateSimpleMember', { id, data })).then(
      data => Promise.resolve(data),
      error => {
        dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      }
    );
  };
}

export function handleGetFileError(dispatch, file, type) {
  dispatch({
    type: type,
    fileToRemove: file
  });
}

export function getMemberDrivingLicenseImage(files, loader = false) {
  return dispatch => {
    dispatch({ type: types.MEMBERS_DETAILS_DRIVING_LICENSE_REQUEST });

    return Promise.all(
      _forEach(files, file => {
        if (!_get(file, 'fileId')) {
          handleGetFileError(dispatch, file, types.MEMBERS_DETAILS_DRIVING_LICENSE_ERROR);
        } else {
          dispatch(callApi('file', 'getFileUrl', file.fileId, loader))
            .then(data => {
              dispatch({
                type: types.MEMBERS_DETAILS_DRIVING_LICENSE_SUCCESS,
                drivingLicense: data
              });
            })
            .catch(() => {
              handleGetFileError(dispatch, file, types.MEMBERS_DETAILS_DRIVING_LICENSE_ERROR);
            });
        }
      })
    );
  };
}

export function getMemberIdentityDocumentImage(files, loader = false) {
  return dispatch => {
    dispatch({ type: types.MEMBERS_DETAILS_IDENTITY_DOCUMENT_REQUEST });

    return Promise.all(
      _forEach(files, file => {
        if (!_get(file, 'fileId')) {
          handleGetFileError(dispatch, file, types.MEMBERS_DETAILS_IDENTITY_DOCUMENT_ERROR);
        } else {
          dispatch(callApi('file', 'getFileUrl', file.fileId, loader))
            .then(data => {
              dispatch({
                type: types.MEMBERS_DETAILS_IDENTITY_DOCUMENT_SUCCESS,
                identityDocument: data
              });
            })
            .catch(() => {
              handleGetFileError(dispatch, file, types.MEMBERS_DETAILS_IDENTITY_DOCUMENT_ERROR);
            });
        }
      })
    );
  };
}

export function getMemberEmployerCertificateImage(files, loader = false) {
  return dispatch => {
    dispatch({ type: types.MEMBERS_DETAILS_EMPLOYER_CERTIFICATE_REQUEST });

    return Promise.all(
      _forEach(files, file => {
        if (!_get(file, 'fileId')) {
          handleGetFileError(dispatch, file, types.MEMBERS_DETAILS_EMPLOYER_CERTIFICATE_ERROR);
        } else {
          dispatch(callApi('file', 'getFile', file.fileId, loader))
            .then(data => {
              dispatch({
                type: types.MEMBERS_DETAILS_EMPLOYER_CERTIFICATE_SUCCESS,
                employerCertificate: data
              });
            })
            .catch(() => {
              handleGetFileError(dispatch, file, types.MEMBERS_DETAILS_EMPLOYER_CERTIFICATE_ERROR);
            });
        }
      })
    );
  };
}

export function getImpersonateMember(memberId, companyId, openInNewTab) {
  return dispatch => {
    dispatch({
      type: types.MEMBERS_IMPERSONATE_MEMBER_REQUEST
    });

    const handleError = error => {
      dispatch(addErrorMessage({ error }));
    };

    const getWebUrl = (authorizedApplications, appType) => {
      if (_includes(authorizedApplications, 'RRENT_WEB')) appType = 'RRENT_WEB';
      else if (_includes(authorizedApplications, 'RENAULT_WEB')) appType = 'RENAULT_WEB';
      else if (_includes(authorizedApplications, 'SHARE_MOBILITY_WEB')) appType = 'SHARE_MOBILITY_WEB';
      return config.webUrls[appType];
    };

    dispatch(callApi('companies', 'getCompanyDetail', companyId))
      .then(data => {
        const { authorizedApplications } = data || {};

        dispatch(callApi('members', 'getMemberImpersonate', memberId))
          .then(data => {
            dispatch({
              type: types.MEMBERS_IMPERSONATE_MEMBER_SUCCESS
            });

            const webUrl = getWebUrl(authorizedApplications, data.application);
            const finalUrl = urlRemoveDoubleSlash(webUrl + '/#/login?impersonateToken=' + data.token);

            if (openInNewTab) openUrlBackgroundTab(finalUrl);
            else window.open(finalUrl, '_blank');
          })
          .catch(handleError);
      })
      .catch(handleError);
  };
}

export function getImpersonateData(profileId, loader = false) {
  return dispatch => {
    return dispatch(callApi('members', 'getMemberImpersonate', profileId, loader))
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  };
}

export function openResetPasswordMemberModal() {
  return {
    type: types.MEMBERS_OPEN_RESET_PASSWORD_MEMBER_MODAL
  };
}

export function closeResetPasswordMemberModal() {
  return {
    type: types.MEMBERS_CLOSE_RESET_PASSWORD_MEMBER_MODAL
  };
}

export function requestResetPasswordMember(memberId) {
  return (dispatch, getState) => {
    const { resetPasswordDeliveryMode } = trimValues(getState().form.resetPasswordModalForm);

    dispatch({
      type: types.MEMBERS_RESET_PASSWORD_MEMBER_REQUEST
    });

    return dispatch(callApi('members', 'resetPasswordMember', { memberId, resetPasswordDeliveryMode }))
      .then(() => {
        dispatch(closeResetPasswordMemberModal());
        dispatch(
          addFlashMessage({
            contentKey: 'resetPassword_member_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(error => {
        if (!error.type) {
          console.error(error.stack); // eslint-disable-line
        }
        dispatch(
          addFlashMessage({
            contentKey: 'resetPassword_member_error',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

export function toggleStatusMember() {
  return {
    type: types.MEMBERS_STATUS_MEMBER_TOGGLE
  };
}

export function requestChangeSuspensionMember(member, comment = null) {
  return (dispatch, getState) => {
    const state = getState();

    const id = _get(member, 'id', null);
    const isSuspendedMember = _get(state, 'members.detailMember.suspended', false);
    const action = isSuspendedMember ? 'unsuspend' : 'suspend';

    dispatch({ type: types.MEMBERS_CHANGE_STATUS_MEMBER_REQUEST });

    return dispatch(callApi('members', 'changeSuspensionMember', { id, isSuspendedMember, comment }))
      .then(() => {
        dispatch(getMemberDetail(id));
      })
      .then(() => {
        dispatch(
          addFlashMessage({
            contentKey: `member_${action}_success`,
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(error => {
        if (!error.type) {
          // make sure native errors not swallowed
          console.error(error.stack); // eslint-disable-line
        }
        dispatch(
          addFlashMessage({
            contentKey: `members_detail_${action}_failed`,
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

export function openAssignMemberTypeModal() {
  return {
    type: types.MEMBER_ASSIGN_TYPE_MODAL_OPEN
  };
}

export function closeAssignMemberTypeModal() {
  return {
    type: types.MEMBER_ASSIGN_TYPE_MODAL_CLOSE
  };
}

export function openDriverLicenceDetailsModal() {
  return {
    type: types.MEMBER_DRIVING_LICENCE_MODAL_OPEN
  };
}

export function closeDriverLicenceDetailsModal() {
  return {
    type: types.MEMBER_DRIVING_LICENCE_MODAL_CLOSE
  };
}

export function openAnonymizeMemberDialog() {
  return {
    type: types.MEMBER_ANONYMIZE_DIALOG_OPEN
  };
}

export function closeAnonymizeMemberDialog() {
  return {
    type: types.MEMBER_ANONYMIZE_DIALOG_CLOSE
  };
}

export function requestAnonymizeMember(payload) {
  return dispatch => {
    dispatch({
      type: types.MEMBER_ANONYMIZE_REQUEST,
      payload
    });

    return dispatch(callApi('members', 'anonymize', payload, false))
      .then(data => {
        setTimeout(() => {
          dispatch({
            type: types.MEMBER_ANONYMIZE_SUCCESS,
            data
          });
          dispatch(closeAnonymizeMemberDialog());
          dispatch(
            addFlashMessage({
              contentKey: 'member_anonymation_success',
              type: FLASH_MESSAGE_TYPE_SUCCESS
            })
          );
          dispatch(getMemberDetail(payload.memberId));
        }, 500);
      })
      .catch(error => {
        dispatch(closeAnonymizeMemberDialog());
        dispatch(
          addFlashMessage({
            contentKey: error.type,
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

export function requestDeanonymizedMember(memberId) {
  return dispatch => {
    dispatch({
      type: types.MEMBER_DEANONYMIZE_REQUEST,
      memberId
    });

    return dispatch(callApi('members', 'deanonymize', memberId, false))
      .then(data => {
        dispatch({
          type: types.MEMBER_DEANONYMIZE_SUCCESS,
          data
        });
        dispatch(closeAnonymizeMemberDialog());
        dispatch(
          addFlashMessage({
            contentKey: 'member_deanonymation_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(() => {
        dispatch(closeAnonymizeMemberDialog());
        dispatch(
          addFlashMessage({
            contentKey: 'member_deanonymation_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

export function requestMemberTypeChange(params) {
  return (dispatch, getState) => {
    dispatch({
      type: types.MEMBER_CHANGE_MEMBER_TYPE_REQUEST,
      params
    });

    const state = getState();
    const bundle = bundleSelector(state);

    return dispatch(callApi('members', 'changeMemberType', params, false)).then(
      data => {
        dispatch({
          type: types.MEMBER_CHANGE_MEMBER_TYPE_SUCCESS,
          data
        });
        dispatch(closeAssignMemberTypeModal());
        dispatch(
          addFlashMessage({
            contentKey: 'member_type_change_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        return Promise.resolve(data);
      },
      error => {
        const msgParams = { bundle, error, def: 'member_type_change_failed' };
        dispatch(addErrorMessage(msgParams));
        return Promise.reject(error);
      }
    );
  };
}

export function requestMemberDrivingLicenceUpdate(params) {
  return (dispatch, getState) => {
    dispatch({
      type: types.MEMBER_UPDATE_MEMBER_DRIVING_LICENCE_REQUEST
    });

    const state = getState();
    const bundle = bundleSelector(state);
    const memberId = state.members.detailMember.id;

    const { licenceNumber, expirationDate, cityDeliverance, deliveranceDate } = trimValues(state.form.drivingLicenceEditApproveForm);
    let body = {
      licenceNumber,
      deliveranceDate,
      cityDeliverance,
      expirationDate
    };
    return dispatch(callApi('documents', 'approveDocumentWithData', { documentId: params.documentId, input: { ...body } }, true)).then(
      data => {
        dispatch({
          type: types.MEMBER_UPDATE_MEMBER_DRIVING_LICENCE_SUCCESS,
          data
        });
        dispatch(closeDriverLicenceDetailsModal());
        dispatch(getMemberDetail(memberId));

        dispatch(
          addFlashMessage({
            contentKey: 'member_approve_document_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        return Promise.resolve(data);
      },
      error => {
        const msgParams = { bundle, error, def: 'member_approve_document_failed' };
        dispatch(addErrorMessage(msgParams));
        return Promise.reject(error);
      }
    );
  };
}

export function requestChangeTechnicianMember(member) {
  return (dispatch, getState) => {
    const customFields = getState().members.customFields;
    const params = cleanMemberDataForApi(member, customFields);

    params.technician = !params.technician;

    dispatch({
      type: types.MEMBERS_CHANGE_TECHNICIAN_MEMBER_REQUEST,
      params
    });

    return dispatch(callApi('members', 'changeTechnicianMember', params))
      .then(() => {
        dispatch(callChangeTechnicianMemberSuccess(member));
        refreshPage();
      })
      .catch(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'members_detail_change_technician_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

export function callChangeTechnicianMemberSuccess(member) {
  return {
    type: types.MEMBERS_CHANGE_TECHNICIAN_MEMBER_SUCCESS,
    member
  };
}

export function exportMembersListWithSearch(params) {
  return (dispatch, getState) => {
    const state = getState();
    const isExportProcessing = window.localStorage.getItem('idExportMembers');
    const paginated = params.page || {};
    let paramsObj = {};

    if (isExportProcessing) {
      return dispatch(pollingMembersExport(isExportProcessing));
    }
    if (params) {
      if (paginated) {
        const pageNumber = params.page ? params.page.number : state.members.paginatedResults.metadata.paginationInfo.currentPage;
        const pageSize = params.page ? params.page.size : state.members.paginatedResults.metadata.paginationInfo.pageSize;

        paramsObj = {
          page: {
            number: pageNumber,
            size: pageSize
          }
        };
      }
      paramsObj.locale = localeSelector(state);
      paramsObj = Object.assign(
        {},
        paramsObj,
        _pick(params, ['memberName', 'email', 'status', 'technician', 'vip', 'subscriptionOrigins', 'expediteReview', 'professional'])
      );

      const sortProperty = params.sort ? params.sort.property : state.members.currentSortedIndex;
      const sortIsDescending = params.sort ? params.sort.isDescending : state.members.sortIsDescending;

      const sort = {
        property: sortProperty,
        direction: sortIsDescending ? SORT_DESC : SORT_ASC
      };

      if (sortProperty) {
        paramsObj = Object.assign(paramsObj, { sort });
      }

      trySet(paramsObj, 'companyIds', append([selectCompanyId(state)]));
      trySet(paramsObj, 'subCompanyIds', append([selectSubCompanyId(state)]));
    } else {
      paramsObj = null;
    }
    // const body = !_isEmpty (bodyParams) ? JSON.stringify(bodyParams) : '';
    return dispatch(callApi('members', 'exportMembers', paramsObj, false))
      .then(data => {
        window.localStorage.setItem('idExportMembers', data);
        dispatch({
          type: types.MEMBERS_EXPORT_REQUEST
        });
        dispatch(pollingMembersExport(data));

        return data;
      })
      .catch(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'members_download_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
        window.localStorage.removeItem('idExportMembers');
      });
  };
}

export function getCompaniesTechnicianList(id) {
  return dispatch => {
    dispatch({
      type: types.MEMBERS_GET_COMPANIES_USED_LIST_REQUEST
    });

    return dispatch(callApi('members', 'getCompaniesUsedAsTechnicianList', id)).then(function(data) {
      dispatch({
        type: types.MEMBERS_GET_COMPANIES_USED_LIST_SUCCESS,
        companyUsedByMember: data.affected
      });
      return data;
    });
  };
}

export function addCompanyToTechnician(companies) {
  return (dispatch, getState) => {
    let state = getState();

    let membersUsedCompaniesInitial = state.members.companyUsedByMember || [];
    membersUsedCompaniesInitial = membersUsedCompaniesInitial.sort();

    let concatCompanies = membersUsedCompaniesInitial.concat(companies);

    concatCompanies = _union(concatCompanies);
    concatCompanies = concatCompanies.sort();

    if (!_isEqual(membersUsedCompaniesInitial, concatCompanies)) {
      dispatch({
        type: types.MEMBERS_EDIT_COMPANIES_USED_LIST_SUCCESS,
        companyUsedByMember: concatCompanies
      });
    }
  };
}

export function freeCompanyFromMemberUsed(companies) {
  return (dispatch, getState) => {
    let state = getState();

    let membersUsedCompaniesInitial = state.members.companyUsedByMember || [];
    membersUsedCompaniesInitial = membersUsedCompaniesInitial.sort();
    let concatCompanies = _differenceWith(membersUsedCompaniesInitial, companies, _isEqual);

    concatCompanies = concatCompanies.sort();

    return dispatch({
      type: types.MEMBERS_EDIT_COMPANIES_USED_LIST_SUCCESS,
      companyUsedByMember: concatCompanies
    });
  };
}

export function saveCompanyUsedRequest(memberId) {
  return (dispatch, getState) => {
    const state = getState();

    let companyUsedByMember = state.members.companyUsedByMember;

    let params = [];

    companyUsedByMember.forEach(item => {
      params.push(item.id);
    });

    dispatch({
      type: types.MEMBERS_SAVE_COMPANIES_USED_LIST_REQUEST,
      params
    });

    return dispatch(callApi('members', 'saveCompanyUsedAsTechnician', { memberId, params }))
      .then(memberUsedCompanies => {
        dispatch(saveCompanyUsedSuccess(memberUsedCompanies.affected));
        dispatch(
          addFlashMessage({
            contentKey: 'members_companies_used_update_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'members_companies_used_update_error',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

export function saveCompanyUsedSuccess(companyUsedByMember) {
  return {
    type: types.MEMBERS_SAVE_COMPANIES_USED_LIST_SUCCESS,
    companyUsedByMember
  };
}

export function getMemberComments(memberId) {
  return dispatch => {
    dispatch({
      type: types.MEMBERS_DETAILS_COMMENTS_REQUEST
    });

    return dispatch(callApi('members', 'getComments', memberId)).then(data => {
      dispatch({
        type: types.MEMBERS_DETAILS_COMMENTS_SUCCESS,
        comments: data
      });
    });
  };
}

export function openCommentModal(modalType) {
  return {
    type: types.MEMBER_COMMENT_MODAL_OPEN,
    modalType
  };
}

export function closeCommentModal() {
  return {
    type: types.MEMBER_COMMENT_MODAL_CLOSE
  };
}

export function openMemberEmailModal() {
  return {
    type: types.MEMBER_EMAIL_MODAL_OPEN
  };
}

export function closeMemberEmailModal() {
  return {
    type: types.MEMBER_EMAIL_MODAL_CLOSE
  };
}

export function changeMemberEmailRequest(memberId) {
  return (dispatch, getState) => {
    const { login } = trimValues(getState().form.changeEmailForm);

    dispatch({
      type: types.MEMBERS_CHANGE_EMAIL_MEMBER_REQUEST
    });

    return dispatch(callApi('members', 'changeEmailMember', { memberId, login }))
      .then(member => {
        dispatch({
          type: types.MEMBERS_CHANGE_EMAIL_MEMBER_SUCCESS,
          member
        });
        dispatch(closeMemberEmailModal());
        dispatch(
          addFlashMessage({
            contentKey: 'changeEmail_member_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(error => {
        if (!error.type) {
          console.error(error.stack); // eslint-disable-line
        }
        dispatch(
          addFlashMessage({
            contentKey: 'changeEmail_member_error',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

export function confirmCommentMember(detailMember, rejectType, comment) {
  return dispatch => {
    if (rejectType === MEMBER_ADD_COMMENT) {
      return dispatch(confirmAddComment(detailMember.id, comment));
    } else if (rejectType === MEMBER_SUSPEND_MEMBER) {
      return dispatch(requestChangeSuspensionMember(detailMember, comment));
    } else {
      return dispatch(confirmRejectReason(detailMember, rejectType, comment));
    }
  };
}

function confirmAddComment(memberId, comment) {
  return dispatch => {
    return dispatch(callApi('members', 'addComment', { memberId, comment }))
      .then(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'member_add_comment_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        dispatch(getMemberComments(memberId));
      })
      .catch(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'member_add_comment_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

function confirmRejectReason(member, rejectType, comment) {
  return dispatch => {
    return dispatch(
      rejectType === MEMBER_INFORMATION
        ? callApi('members', 'rejectMemberReason', { memberId: member.id, rejectType, comment })
        : callApi('documents', 'rejectDocumentReason', {
            documentId: getDocumentIdByType(member, rejectType),
            comment
          })
    )
      .then(() => {
        dispatch(
          addFlashMessage({
            contentKey: rejectType === MEMBER_INFORMATION ? 'member_reject_personal_information_success' : 'member_reject_document_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        dispatch(getMemberDetail(member.id));
      })
      .catch(() => {
        dispatch(
          addFlashMessage({
            contentKey: rejectType === MEMBER_INFORMATION ? 'member_reject_personal_information_failed' : 'member_reject_document_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

export function confirmApproveMember(member, approveType) {
  return dispatch => {
    return dispatch(
      approveType === MEMBER_INFORMATION
        ? callApi('members', 'approveMember', { memberId: member.id })
        : callApi('documents', 'approveDocument', {
            documentId: getDocumentIdByType(member, approveType)
          })
    )
      .then(() => {
        dispatch(
          addFlashMessage({
            contentKey:
              approveType === MEMBER_INFORMATION ? 'member_approve_personal_information_success' : 'member_approve_document_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        dispatch(getMemberDetail(member.id));
      })
      .catch(() => {
        dispatch(
          addFlashMessage({
            contentKey:
              approveType === MEMBER_INFORMATION ? 'member_approve_personal_information_failed' : 'member_approve_document_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

function getDocumentIdByType(member, type) {
  if (type === MEMBER_ID_DOCUMENT) {
    return member.identityDocument.id;
  } else if (type === MEMBER_DRIVING_LICENCE) {
    return member.drivingLicence.id;
  } else if (type === MEMBER_EMPLOYER_CERTIFICATE) {
    return member.employerCertificate.id;
  } else {
    return null;
  }
}

export function getCustomFieldsMember(memberId) {
  return dispatch => {
    return dispatch(callApi('members', 'getCustomFields', memberId)).then(
      data => {
        dispatch({
          type: types.MEMBERS_CUSTOM_FIELDS_SUCCESS,
          customFields: data
        });
      },
      error => {
        dispatch(addErrorMessage({ error }));
      }
    );
  };
}

export function clearCustomFieldsMember() {
  return {
    type: types.MEMBERS_CUSTOM_FIELDS_CLEAR
  };
}

export function getCustomFieldsFile(fieldId) {
  return dispatch => {
    dispatch({ type: types.MEMBERS_CUSTOM_FIELDS_FILE_REQUEST, fieldId });

    return dispatch(callApi('file', 'getFile', fieldId, false))
      .then(data => {
        dispatch({
          type: types.MEMBERS_CUSTOM_FIELDS_FILE_SUCCESS,
          file: data
        });
      })
      .catch(() => {
        dispatch({
          type: types.MEMBERS_CUSTOM_FIELDS_FILE_ERROR,
          id: fieldId
        });
      });
  };
}

export function uploadMember(file) {
  return (dispatch, getState) => {
    const state = getState();
    const companyId = validCompanyIdSelector(state);
    const bundle = bundleSelector(state);

    if (companyId && file) {
      const action = callApi('members', 'upload', { companyId, file });
      return dispatch(action).then(
        data => {
          dispatch(
            addFlashMessage({
              contentKey: 'upload_members_success',
              type: FLASH_MESSAGE_TYPE_SUCCESS
            })
          );
          if (_get(data, 'status', '') === 202) {
            let data = { recapMail: true, status: 202 };
            dispatch(importedMembersSummary(data));
          } else dispatch(importedMembersSummary(data));
        },
        error => {
          if (_get(error, 'status', '') === 503) {
            dispatch(importedMembersSummary(error));
          } else {
            const partialError = partialErrorCodes.uploadMember.unsupportedFileType;
            const msgParams = { bundle, error, partialErrors: [partialError] };
            dispatch(addErrorMessage(msgParams));
          }
        }
      );
    }
  };
}

export function importedMembersSummary(data) {
  return dispatch => {
    dispatch({
      type: types.MEMBERS_IMPORTED_SUCCESS,
      data
    });
  };
}

export function emptyMembersImportResume() {
  return {
    type: types.MEMBERS_IMPORTED_EMPTY_RESUME
  };
}

export function addMemberRequest(selectedCompanyId, selectedSubCompanyId, skipEmail = false) {
  return (dispatch, getState) => {
    const state = getState();

    const {
      login,
      civility,
      firstName,
      lastName,
      phoneNumber,
      locale,
      birthDate,
      address,
      postalCode,
      city,
      country,
      licenceNumber,
      deliveranceDate,
      cityDeliverance,
      expirationDate,
      hasExpirationDate,
      managerEmail,
      italian,
      fiscalCode,
      ...customFields
    } = trimValues(state.form.addMemberForm);

    const { phonePrefix, phoneSuffix } = phoneNumber || {};

    let params = {
      login,
      firstName,
      lastName,
      locale: locale ? locale : state.i18n.locale,
      phoneNumber: {
        countryCode: phonePrefix,
        nationalNumber: phoneSuffix
      },
      companyId: selectedCompanyId,
      subCompanyId: selectedSubCompanyId,
      birthDate,
      address,
      postalCode,
      city,
      country,
      civility,
      licenceNumber,
      deliveranceDate,
      cityDeliverance,
      expirationDate,
      hasExpirationDate,
      managerEmail,
      italian,
      fiscalCode,
      ...customFields
    };

    let payload = enhanceAddMemberhRequestParams(params);

    dispatch({
      type: types.MEMBER_PRESUBSCRIPTION_REQUEST,
      params: payload
    });

    return dispatch(callApi('members', 'addMember', { params: payload, skipEmail }));
  };
}

export function addMemberSuccess(addedMember) {
  return {
    type: types.MEMBER_PRESUBSCRIPTION_SUCCESS,
    addedMember
  };
}

export function openMigrateMemberModal() {
  return {
    type: types.MEMBER_OPEN_MIGRATE_MODAL
  };
}

export function closeMigrateMemberModal() {
  return {
    type: types.MEMBER_CLOSE_MIGRATE_MODAL
  };
}

export function migrateMember(memberId, newCompanyId) {
  return (dispatch, getState) => {
    const state = getState();
    const bundle = bundleSelector(state);

    return dispatch(callApi('members', 'migrateMember', { profileId: memberId, newCompanyId })).then(
      () => {
        dispatch(
          addFlashMessage({
            contentKey: 'migrate_member_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        dispatch(closeMigrateMemberModal());
      },
      error => {
        const msgParams = { bundle, error };
        dispatch(addErrorMessage(msgParams));
      }
    );
  };
}

export function updateChorusData(enterpriseId) {
  return dispatch => {
    return dispatch(callApi('members', 'updateChorusInfo', enterpriseId)).then(
      data => {
        addSuccessMsg(`${getMsg('common_update')}: ${getMsg('members_detail_enterprise_subtitle')}: ${getMsg('common_success')}`);
        dispatch(chorusDataUpdateSuccess(data));
      },
      error => {
        dispatch(addErrorMessage({ msgPrefix: `${getMsg('common_update')}: ${getMsg('members_detail_enterprise_subtitle')}: `, error }));
      }
    );
  };
}

export function chorusDataUpdateSuccess(data) {
  return { type: MEMBER_UPDATE_CHORUS_DATA_SUCCESS, data };
}
