'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _pure = require('recompose/pure');

var _pure2 = _interopRequireDefault(_pure);

var _SvgIcon = require('../../SvgIcon');

var _SvgIcon2 = _interopRequireDefault(_SvgIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DeviceSignalWifi2Bar = function DeviceSignalWifi2Bar(props) {
  return _react2.default.createElement(
    _SvgIcon2.default,
    props,
    _react2.default.createElement('path', { fillOpacity: '.3', d: 'M12.01 21.49L23.64 7c-.45-.34-4.93-4-11.64-4C5.28 3 .81 6.66.36 7l11.63 14.49.01.01.01-.01z' }),
    _react2.default.createElement('path', { d: 'M4.79 12.52l7.2 8.98H12l.01-.01 7.2-8.98C18.85 12.24 16.1 10 12 10s-6.85 2.24-7.21 2.52z' })
  );
};
DeviceSignalWifi2Bar = (0, _pure2.default)(DeviceSignalWifi2Bar);
DeviceSignalWifi2Bar.displayName = 'DeviceSignalWifi2Bar';
DeviceSignalWifi2Bar.muiName = 'SvgIcon';

exports.default = DeviceSignalWifi2Bar;