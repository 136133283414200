'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _pure = require('recompose/pure');

var _pure2 = _interopRequireDefault(_pure);

var _SvgIcon = require('../../SvgIcon');

var _SvgIcon2 = _interopRequireDefault(_SvgIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var NotificationAirlineSeatLegroomReduced = function NotificationAirlineSeatLegroomReduced(props) {
  return _react2.default.createElement(
    _SvgIcon2.default,
    props,
    _react2.default.createElement('path', { d: 'M19.97 19.2c.18.96-.55 1.8-1.47 1.8H14v-3l1-4H9c-1.65 0-3-1.35-3-3V3h6v6h5c1.1 0 2 .9 2 2l-2 7h1.44c.73 0 1.39.49 1.53 1.2zM5 12V3H3v9c0 2.76 2.24 5 5 5h4v-2H8c-1.66 0-3-1.34-3-3z' })
  );
};
NotificationAirlineSeatLegroomReduced = (0, _pure2.default)(NotificationAirlineSeatLegroomReduced);
NotificationAirlineSeatLegroomReduced.displayName = 'NotificationAirlineSeatLegroomReduced';
NotificationAirlineSeatLegroomReduced.muiName = 'SvgIcon';

exports.default = NotificationAirlineSeatLegroomReduced;