module.exports={
  "cs-i18n.json": "cs-i18n-da078781ec.json",
  "da-i18n.json": "da-i18n-586be25537.json",
  "de-i18n.json": "de-i18n-9be150d0be.json",
  "en-i18n.json": "en-i18n-cff1c74ce1.json",
  "es-i18n.json": "es-i18n-11822aa988.json",
  "fr-i18n.json": "fr-i18n-a62a92c8da.json",
  "it-i18n.json": "it-i18n-335b8a95da.json",
  "nl-i18n.json": "nl-i18n-ad07c6501e.json",
  "pl-i18n.json": "pl-i18n-7009f7ffa2.json",
  "pt-i18n.json": "pt-i18n-1e44e7ce87.json",
  "pt_BR-i18n.json": "pt_BR-i18n-448b41dad1.json",
  "sk-i18n.json": "sk-i18n-2fb711d52b.json",
  "sv-i18n.json": "sv-i18n-981b83a8d3.json"
}