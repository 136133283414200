'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _pure = require('recompose/pure');

var _pure2 = _interopRequireDefault(_pure);

var _SvgIcon = require('../../SvgIcon');

var _SvgIcon2 = _interopRequireDefault(_SvgIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AvPlaylistPlay = function AvPlaylistPlay(props) {
  return _react2.default.createElement(
    _SvgIcon2.default,
    props,
    _react2.default.createElement('path', { d: 'M19 9H2v2h17V9zm0-4H2v2h17V5zM2 15h13v-2H2v2zm15-2v6l5-3-5-3z' })
  );
};
AvPlaylistPlay = (0, _pure2.default)(AvPlaylistPlay);
AvPlaylistPlay.displayName = 'AvPlaylistPlay';
AvPlaylistPlay.muiName = 'SvgIcon';

exports.default = AvPlaylistPlay;