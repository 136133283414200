"use strict";

exports.__esModule = true;
exports.default = void 0;

var _svgInjector = _interopRequireDefault(require("./svg-injector"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _default = _svgInjector.default;
exports.default = _default;